<template>
    <div class="dashboard-content">
        <div class="left-content-section transfer">
            <h1>Individual Transactions</h1>
            <div class="transfer transfer-money-box">
                <router-link :to="{name: 'AddNewRecipients'}" class="link">
                    <div class="box">
                        <span>+</span>
                    </div>
                </router-link>

            </div>

            <RecepientCard/>
        </div>
        <div class="right-content-section transfer">
            <PayContent/>
        </div>
    </div>
</template>
<script>
    import PayContent from '@/components/Dashboard/PayContent.vue'
    import RecepientCard from '@/components/Dashboard/RecepientCard'

    export default {
        name: 'TransferHome',
        components: {PayContent, RecepientCard}
    }
</script>
<style scoped>
    .left-content-section.transfer {
        align-items: center;
        padding: 1.875rem;
    }

    .right-content-section.transfer{
        width: 50%;
    }

    .left-content-section.transfer h1 {
        color: #423E3B;
    }

    .transfer.transfer-money-box {
        margin-top: 1.25rem;
        height: 5.625rem;
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0.1875rem 0.375rem #00000029;
        border: 0.0625rem dashed #707070;
        border-radius: 0.9375rem;
        opacity: 1;
    }

    .transfer.transfer-money-box .link .box {
        margin: 0;
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent linear-gradient(306deg, #383d6f 0%, #535ba7 100%) 0 0 no-repeat;
        border-radius: 0.3125rem;
        box-shadow: 0.625rem 0.5rem 0 rgba(83, 91, 167, 0.7);
        font-weight: 900;
        cursor: pointer;
        transition: all 0.5s linear;
    }

    .transfer.transfer-money-box .link .box span {
        font-family: "Source Sans Pro", sans-serif;
        color: #fff;
        font-size: 1.875rem;
    }

    @media screen and (min-width: 37.5rem) and (max-width: 56.25rem) {
        .right-content-section.transfer{
            width: 100%;
        }
    }

    @media screen and  (max-width: 34.375rem) {
        .left-content-section.transfer {
            padding: 0.625rem;
        }

        .right-content-section.transfer{
            width: 100%;
        }
    }

</style>