<template>
    <div class="recipient-category-section">
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        name: 'CorporateRecipients',

    }
</script>

<style scoped>
    .recipient-category-section{
        display: flex;
        justify-content: center;
    }

</style>