<template>
    <div class="payment-method-card-section" v-if="specificPaymentMethods">
        <div class="payment-methods-section">
            <div v-for="specificPaymentMethod in specificPaymentMethods" :key="specificPaymentMethod.id">
                <div class="payment-method-card" @click="reRoute(specificPaymentMethod.id)">
                    <i class="fa-solid fa-briefcase" v-if="specificPaymentMethod.name.includes('Bank')"></i>
                    <i class="fa-solid fa-mobile-button" v-if="specificPaymentMethod.name.includes('Mobile')"></i>
                    <i class="fa-solid fa-money-bill" v-if="specificPaymentMethod.name.includes('Cash')"></i>
                    <i class="fa-solid fa-money-check" v-if="specificPaymentMethod.name.includes('Card')"></i>
                    <p class="payment-method-name">{{specificPaymentMethod.name}}</p>
                </div>
            </div>
            <div class="no-channels-layout" v-if="specificPaymentMethods.length === 0">
                <div class="no-payment-channels-available">
                    <img src="../../assets/dashboard/void.svg" alt="no-results-found">
                </div>
                <p>No payout methods available.</p>
            </div>
        </div>
    </div>
    <div v-else class="lds-dual-ring"></div>

</template>

<script>


    import {computed, ref} from "vue";
    import axios from "axios";
    import {useStore} from "vuex";
    import {useRoute, useRouter} from "vue-router";
    import Swal from "sweetalert2";

    export default {
        name: "PaymentMethods",

        setup(){
            const store = useStore();
            const route = useRoute();
            const router = useRouter();
            const token = computed( () => {
                return store.getters.getToken;
            })

            const code = route.params.code
            const specificPaymentMethods = ref(null);



            axios.get(`/payment-methods/${code}`, {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            }).then(function (response) {
                specificPaymentMethods.value = response.data.data;
            }).catch(error => {
                if (error.response){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })

            const reRoute = (id) => {
                axios.get(`/payment-method/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('setPaymentMethods', response.data.data)
                }).catch(function (error) {
                    if (error.response){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }

                })
                router.push({name: 'PaymentChannels', params:{id: id}})
            }

            return { router, reRoute,  specificPaymentMethods }

        }
    }
</script>

<style scoped>
    .payment-method-card-section{
        margin-top: 1.875rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .payment-methods-section {

        width: 80%;
    }

    .payment-method-card{
        display: flex;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        align-items: center;
        border-radius: 0.9375rem;
        margin-top: 1.25rem;
        padding: 0.625rem 0 0.625rem 1.25rem;
        width: 100%;
        cursor: pointer;
    }

    .payment-method-name{
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    .payment-method-card p{
        margin-left: 2.5rem;
        font-size: 0.8;
        color: #383d6f;
    }

    .lds-dual-ring:after {
        width: 3.75rem;
        height: 3.75rem;
        margin: 1.25rem;

    }

    @media (max-width: 34.375rem) {
        .payment-method-card{
            display: flex;
            box-shadow: 0 0.125rem 0.375rem #00000029;
            align-items: center;
            border-radius: 0.9375rem;
            margin-top: 1.25rem;
            padding: 0.625rem 0 0.625rem 0.625rem;
            width: 100%;
        }

        .payment-methods-section {
            width: 90%;
        }

        .payment-method-card p{
            font-size: 0.7rem;
        }

    }
</style>