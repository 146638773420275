<template>
<div>
  <Loading v-if="loading"/>
  <div class="form-wrap">
        <form @submit.prevent="register" class="register" autocomplete="off">
            <img src="../assets/landing_page/logo_2.png" height="100" width="100"/>
            <p class="login-register">
                Already have an account?
                <router-link class="router-link" :to="{ name: 'Login' }">Login</router-link>
            </p>
            <h2>Create Your FP Transfer Account</h2>
            <div class="inputs">
                <div class="input" :class="{'input--error': v$.phone.$error}">
                    <select ref="country_code">
                      <option :value="country.code" v-for="country in countries" :key="country.id">
                        {{ country.flag_emoji}} {{ country.dial_code}}
                      </option>
                    </select>
                    
                    <input @blur="v$.phone.$touch()" type="tel" placeholder="Phone Number" class="phone-number"  v-model="state.phone"/>                    
                </div>
                <div v-if="v$.phone.$error">
                <p :class="{'error': v$.phone.required.$invalid}"  v-if="v$.phone.required.$invalid">Please enter your phone number</p>
                </div>
                <div class="input" :class="{'input--error': v$.email.$error}">
                    <input @blur="v$.email.$touch()" type="email" placeholder="Email"  v-model="state.email"/>
                    <img class="icon" src="../assets/Icons/envelope-regular.svg" alt="email-icon">
                </div>
                <div v-if="v$.email.$error">
                  <p :class="{'error': v$.email.email.$invalid}"  v-if="v$.email.email.$invalid">Please enter a valid email address</p>
                  <p :class="{'error': v$.email.required.$invalid}"  v-if="v$.email.required.$invalid">Please enter an email address</p>
                </div>
                <div class="input" :class="{'input--error': v$.password.$error}">
                    <input @blur="v$.password.$touch()" type="password" placeholder="Password" v-model="state.password" />
                    <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                </div>
<!--                <div v-if="v$.password.$error">-->
<!--                <p :class="{'error': v$.password.required.$invalid}"  v-if="v$.password.required.$invalid">Please enter your password</p>-->
<!--                <p :class="{'error': v$.password.minLength.$invalid}"  v-if="v$.password.minLength.$invalid">Password should be at least 8 characters</p>-->
<!--                <p :class="{'error': v$.password.containsLowercase.$invalid || v$.password.containsUppercase.$invalid || v$.password.containsNumber.$invalid || v$.password.containsSpecial.$invalid}"  v-if="v$.password.containsLowercase.$invalid || v$.password.containsUppercase.$invalid || v$.password.containsNumber.$invalid || v$.password.containsSpecial.$invalid">Password must have at least one uppercase letter, lowercase letter, number and special character</p>-->

<!--                </div>-->
                <div class="password-rules">
                    <div class="specific-password-rules" :class="{'active': !v$.password.minLength.$invalid && !v$.password.required.$invalid}">
                        <p ><i class="fas fa-check-circle"></i> 8 characters minimum</p>
                    </div>
                    <div class="specific-password-rules" :class="{ 'active': !v$.password.containsLowercase.$invalid}">
                        <p ><i class="fas fa-check-circle"></i> Contains one lowercase character</p>
                    </div>
                    <div class="specific-password-rules" :class="{ 'active': !v$.password.containsUppercase.$invalid}">
                        <p ><i class="fas fa-check-circle"></i> Contains one uppercase character</p>
                    </div>
                    <div class="specific-password-rules" :class="{ 'active': !v$.password.containsNumber.$invalid && !v$.password.containsSpecial.$invalid}">
                        <p ><i class="fas fa-check-circle"></i> Contains one number and one special character</p>
                    </div>
                </div>
                <div class="input" :class="{'input--error': v$.password_confirmation.$error}">
                    <input @blur="v$.password_confirmation.$touch()" type="password" placeholder="Confirm password" v-model="state.password_confirmation" />
                    <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                </div>
                <div v-if="v$.password_confirmation.$error">
                <p :class="{'error': v$.password_confirmation.required.$invalid}"  v-if="v$.password_confirmation.required.$invalid">Please confirm your password</p>
                <p :class="{'error': v$.password_confirmation.sameAs.$invalid}"  v-if="v$.password_confirmation.sameAs.$invalid">Must match password entered above</p>
                </div>

                <p class="terms"><a href="https://fastpacetransfer.com/terms.html">Terms of Service</a> and <a href="https://fastpacetransfer.com/privacy.html">Privacy Policy</a> apply</p>
            </div>
            <button :disabled="v$.$invalid">Sign Up</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</div>
</template>

<script>
import { useStore } from "vuex"
import useVuelidate from '@vuelidate/core'
import Loading from '../components/common/Loading.vue'
import Swal from "sweetalert2"
import { required, email, sameAs, minLength } from '@vuelidate/validators'
import { ref, reactive, computed } from "vue";
import axios from "axios"
import { useRouter } from 'vue-router'
export default {
    name: "Register",
    components: { Loading },


    setup(){
     
    const router = useRouter(); 
    const store  = useStore();
    store.dispatch('setSendingCountries');

    
    const loading = computed( () => {
      return store.state.loading;
    })

    const countries = computed( () => {
      return store.getters.getSendingCountries
    }) 

    
       // Grabbing inputs
     const country_code = ref(null);  
     const state = reactive({
       phone : "",
       email : "",
       password : "",
       password_confirmation : ""
     });

     // Validation rules
      const rules = computed( () =>({
        phone : { required },
        email: { required, email },
        password: { 
          required, 
          minLength: minLength(8),
          containsUppercase: function(value) {
          return /[A-Z]/.test(value)
          },
          containsLowercase: function(value) {
            return /[a-z]/.test(value)
          },
          containsNumber: function(value) {
            return /\d/.test(value)
          },
          containsSpecial: function(value) {
            return /[#?!@$%^&*-]/.test(value)
          }
        },
        password_confirmation: {
           required, 
           sameAs: sameAs(state.password)
        }

      })
      
      )
      

      const v$ = useVuelidate(rules, state);
      
      
      


      // Actual registration function
     const register =  () => {

        // Run validate function
          v$.$validate;

        // If there are no errors
          if (!v$.$error) {
            store.dispatch('isLoading');
            const data = 
            {
                "country_code": country_code.value.value, 
                "phone": state.phone,
                "email": state.email,
                "password": state.password,
                "password_confirmation": state.password_confirmation
            }

             axios.post('/register', data)
                        .then( response => {
                          const token = response.data.data.token;
                          store.dispatch('setToken', token);
                          store.dispatch('isLoading');
                          router.push({ name: 'Verification'})
                        })
                        .catch(error => {
                          if (error.response) {  
      
                          //  (error.response.data.errors[0]);
                            Swal.fire({ 
                              icon: 'error',
                              title: 'Error',
                              text: error.response.data.errors.join(" "),
                            }
                            )

                            state.email = "";
                            state.phone = "";
                            state.password = "";
                            state.password_confirmation = "";

                            store.dispatch('isLoading');
                          }
                     });
         
        

        //  this.$router.push({name: 'Login'});
                
        }
     }
      
      



      return {v$, loading, country_code, countries, register, state};
    }
}
</script>

<style scoped>

    .register h2 {
        max-width: 21.875rem;
    }

    .form-wrap form .inputs .password-rules{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .form-wrap form .inputs .password-rules .specific-password-rules{
        font-size: 13px;
        width: 50%;
        margin-bottom: 10px;
        color: #707070;
    }

    .form-wrap form .inputs .password-rules .specific-password-rules.active p{
        color: #2d2929;
        font-weight: bold;
    }

    .form-wrap form .inputs .password-rules .specific-password-rules.active p i{
        color: #2bcf2b;
    }

    .form-wrap form .inputs .terms{
        text-align: center;
        font-size: 0.75rem;
    }

    .form-wrap form .inputs .input select {
        height: 3.125rem;
        background-color: #f2f7f6;
        border: none;
        padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    }

    .form-wrap form .inputs .input select:focus{
        outline: none;
    }
</style>