<template>
  <div class="sidebar" :class="{'active' : active}">
        <div class="logo-details">
          <span><img src="../../assets/dashboard/logo_2.webp" height="60" width="60" alt="company-logo"/></span>
          <span class="logo_name">
              <img src="../../assets/dashboard/Fastpace_white.png" height="43"
                                                          width="144" alt="company-logo"/>
          </span>
        </div>
          <ul class="nav-links">
            <li>
                <router-link :to="{ name: 'Dashboard'}">
                    <i class="fas fa-home"></i>
                    <span class="links_name">Home</span>
                </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Recepients'}">
                  <i class="fas fa-user"></i>
                  <span class="links_name">Recipients</span>
              </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Transfer'}">
                    <i class="fas fa-paper-plane"></i>
                    <span class="links_name">Send Money</span>
                </router-link>
            </li>

            <li>
                <router-link :to="{ name: 'History'}">
                    <i class="fas fa-history"></i>
                    <span class="links_name">History</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Settings'}">
                    <i class="fas fa-cog"></i>
                    <span class="links_name">Settings</span>
                </router-link>
            </li>
          </ul>
      </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
    name: 'Sidebar',

    setup(){

        const store = useStore();

        const active = computed( () => {
            return store.state.collapse
        })

        return { active }
    }


}
</script>

<style>

    .sidebar{
        position: fixed;
        height: 100%;
        width: 15rem;
        background: #383D6F;
        transition: all 0.5s ease;
    }
    .sidebar.active{
        width: 3.75rem;
    }
    .sidebar .logo-details{
        height: 5rem;
        display: flex;
        align-items: center;
    }
    .sidebar .logo-details i{
        font-size: 1.75rem;
        font-weight: 500;
        color: #fff;
        min-width: 3.75rem;
        text-align: center
    }
    .sidebar .logo-details .logo_name{
        color: #fff;
        font-size: 1.5rem;
        font-weight: 500;
    }
    .sidebar .nav-links{
        margin-top: 2.5rem;
    }
    .sidebar .nav-links li{
        position: relative;
        list-style: none;
        height: 3.125rem;
    }
    .sidebar .nav-links li a{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: all 0.4s ease;
    }
    /* .sidebar .nav-links li a.active{
      background: #081D45;
    } */


    .sidebar .nav-links li a:hover{
        background: #2b2e50;
    }
    .sidebar .nav-links li i{
        min-width: 3.75rem;
        text-align: center;
        font-size: 1.125rem;
        color: #fff;
    }
    .sidebar .nav-links li a .links_name{
        color: #fff;
        font-size: 0.9375rem;
        font-weight: 400;
        white-space: nowrap;
    }
</style>

<style scoped>
.router-link-active{
    background: #2b2e50;
}
</style>