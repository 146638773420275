<template>
    <p>TransactionLimit</p>
</template>

<script>
    export default {
        name: "TransactionLimit"
    }
</script>

<style scoped>

</style>