<template>
    <div class="card-payment-layout">
        <div class="card-payment-content">
            <div class="card-payment-title">
                <h2>Saved Cards</h2>
            </div>
            <div class="all-cards-layout">
                <div class="card-details-layout">
                    <div class="card-details-content">
                        <div class="card-type-image">
                            <img src="../../../../assets/dashboard/VISA-logo.png" alt="visa-logo"/>
                        </div>
                        <div class="card-type-details">
                            <div class="card-number-plus-type">
                                <p>Visa****1757</p>
                            </div>
                            <div class="card-expiry-date">
                                <p>Expires 12/22</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-details-actions">
                        <span>Remove</span>
                    </div>
                </div>
                <div class="card-details-layout">
                    <div class="card-details-content">
                        <div class="card-type-image">
                            <img src="../../../../assets/dashboard/VISA-logo.png" alt="visa-logo"/>
                        </div>
                        <div class="card-type-details">
                            <div class="card-number-plus-type">
                                <p>Visa****1757</p>
                            </div>
                            <div class="card-expiry-date">
                                <p>Expires 12/22</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-details-actions">
                        <span>Remove</span>
                    </div>
                </div>
                <div class="card-details-layout">
                    <div class="card-details-content">
                        <div class="card-type-image">
                            <img src="../../../../assets/dashboard/VISA-logo.png" alt="visa-logo"/>
                        </div>
                        <div class="card-type-details">
                            <div class="card-number-plus-type">
                                <p>Visa****1757</p>
                            </div>
                            <div class="card-expiry-date">
                                <p>Expires 12/22</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-details-actions">
                        <span>Remove</span>
                    </div>
                </div>
                <div class="card-details-layout">
                    <div class="card-details-content">
                        <div class="card-type-image">
                            <img src="../../../../assets/dashboard/mastercard.png" alt="visa-logo"/>
                        </div>
                        <div class="card-type-details">
                            <div class="card-number-plus-type">
                                <p>MasterCard****1757</p>
                            </div>
                            <div class="card-expiry-date">
                                <p>Expires 10/2</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-details-actions">
                        <span>Remove</span>
                    </div>
                </div>
                <div class="card-details-layout">
                    <div class="card-details-content">
                        <div class="card-type-image">
                            <img src="../../../../assets/dashboard/mastercard.png" alt="mastercard-logo"/>
                        </div>
                        <div class="card-type-details">
                            <div class="card-number-plus-type">
                                <p>MasterCard****1757</p>
                            </div>
                            <div class="card-expiry-date">
                                <p>Expires 10/2</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-details-actions">
                        <span>Remove</span>
                    </div>
                </div>
                <div class="card-details-layout">
                    <div class="card-details-content">
                        <div class="card-type-image">
                            <img src="../../../../assets/dashboard/mastercard.png" alt="mastercard-logo"/>
                        </div>
                        <div class="card-type-details">
                            <div class="card-number-plus-type">
                                <p>MasterCard****1757</p>
                            </div>
                            <div class="card-expiry-date">
                                <p>Expires 10/2</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-details-actions">
                        <span>Remove</span>
                    </div>
                </div>
                <div class="add-new-card-layout" @click="reRoute()">
                    <span>+ Add new card</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import { useRouter } from "vue-router"
    import {computed} from "vue";

    export default {
        name: "CardPayment",
        setup(){
            const store = useStore();
            const router = useRouter();

            const loading = computed( () => {
                return store.state.loading
            })

            const reRoute = () => {
                router.push({name: 'AddNewCard'})
            }

            return{ loading, reRoute }
        }
    }
</script>

<style scoped>
    .card-payment-layout{
        display: flex;
        justify-content: center;
    }
    .card-payment-layout .card-payment-content{
        width: 80%;
    }

    .card-payment-layout .card-payment-content .all-cards-layout{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
    }

    .card-payment-layout .card-payment-content .card-payment-title{
        padding: 20px;
        color: #555555;
    }

    .card-payment-layout .card-payment-content .card-details-layout{
        margin-top: 20px;
        width: 30%;
        border: 1px solid #e3e2e2;
        border-radius: 5px;
        background: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    }

    .card-payment-layout .card-payment-content .card-details-layout .card-details-content{
        padding: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e3e2e2;
    }

    .card-payment-layout .card-payment-content .card-details-layout .card-details-content .card-type-details .card-number-plus-type{
        font-weight: bold;
        font-size: 18px;
    }

    .card-payment-layout .card-payment-content .card-details-layout .card-details-content .card-type-details .card-expiry-date{
        font-size: 12px;
        margin-top: 5px;
        color: #707070;
    }


    .card-payment-layout .card-payment-content .card-details-layout .card-details-content .card-type-image{
        height: 60px;
        width: 100px;
        border: 1px solid #e3e2e2;
        border-radius: 5px;
        padding: 7px;
        margin-right: 30px;
    }

    .card-payment-layout .card-payment-content .card-details-layout .card-details-content .card-type-image img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .card-payment-layout .card-payment-content .card-details-layout  .card-details-actions{
        padding: 15px 20px;
        color: #1973E8;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: flex-end;
    }

    .card-payment-layout .card-payment-content .card-details-layout  .card-details-actions span{
        cursor: pointer;
    }


    .card-payment-layout .card-payment-content .all-cards-layout .add-new-card-layout{
        border: 2px dashed #e3e2e2;
        margin-top: 20px;
        height: 150px;
        width: 30%;
        color: #1973E8;
        font-weight: bold;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
    }

    @media screen and  (max-width: 34.375rem) {
        .card-payment-layout .card-payment-content .card-details-layout, .card-payment-layout .card-payment-content .all-cards-layout .add-new-card-layout{
            width: 100%;
        }

        .card-payment-layout .card-payment-content {
            width: 90%;
        }
    }

    @media screen and (min-width: 37.5rem) and (max-width: 70rem) {
        .card-payment-layout .card-payment-content .card-details-layout, .card-payment-layout .card-payment-content .all-cards-layout .add-new-card-layout{
            width: 49%;
        }

        .card-payment-layout .card-payment-content {
            width: 90%;
        }
    }


</style>
