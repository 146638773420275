<template>
  <div class="home">
    <Navigation/>
    <LandingPageContent/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "../components/LandingPage/Navigation.vue";
import Footer from "../components/LandingPage/Footer.vue";
import LandingPageContent from "../components/LandingPage/LandingPageContent.vue"

export default {
  name: 'Home',
  components: {
    Navigation, Footer, LandingPageContent
  }
}
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}





</style>