<template>
    <p class="welcome-message">Recipients</p>
    <div class="recipients-section">
        <div class="recipients-table-section">
            <table >
                <thead>
                <tr>
                    <th>FIRST NAME</th>
                    <th>LAST NAME</th>
                    <th> PHONE NUMBER</th>
                    <th>COUNTRY</th>
                    <th>RELATIONSHIP</th>
                    <th>ACTION</th>
                </tr>
                </thead>
                <tbody v-if="recipients.length">
                <tr  v-for="recipient in recipients" :key="recipient.id">
                    <td>{{recipient.firstname}}</td>
                    <td>{{recipient.lastname}}</td>
                    <td>{{recipient.phone_number}}</td>
                    <td><p>{{recipient.recipient_country.name}}</p></td>
                    <td>{{recipient.relationship.charAt(0).toUpperCase() + recipient.relationship.slice(1)}}</td>
                    <td class="actions" @click="reRoute(recipient.id)"><i class="far fa-eye"></i>View</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr >
                    <td class="no-data" colspan="6">no recipients added. <router-link :to="{name: 'Transfer'}">Start now</router-link></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import { useRouter } from 'vue-router'
    import {computed, ref} from "vue";
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "Home",

        setup(){
            const store = useStore();
            const router = useRouter()
            const token = computed( () => {
                return store.getters.getToken
            })

            const userData = computed( () => {
                return store.getters.getUserData
            })

            const reRoute = (id) => {
                router.push({name: 'RecipientDetails', params: { recipient_id : id}})
            }

            const recipients = ref([]);

            const getRecipients = async() => {
                await store.dispatch('isLoading')
                await axios.get(`/recipients/${userData.value.customer ? userData.value.customer.id : userData.value.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then( function (response) {
                    store.dispatch('isLoading')
                    recipients.value = response.data.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
                })
            }

            getRecipients();

            return { reRoute, recipients}
        }
    }
</script>

<style>

    .welcome-message{
        color: #423e3b;
    }
    .recipients-section{
        margin-top: 1.875rem;
        display:flex;
        width: 100%;
    }

    .recipients-section .recipients-table-section{
        overflow-x: auto;
        width: 100%;
        margin: 0 1.25rem;
        transition: all 1s ease;
    }


    table {
        border-collapse: collapse;
        box-shadow: 0 0.3125rem 0.625rem #e1e5ee;
        background-color: white;
        text-align: left;
        overflow: hidden;
        width: 100%;
    }
    table thead {
        box-shadow: 0 0.3125rem 0.625rem #e1e5ee;
    }
    table th {
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.7rem;
        font-weight: 900;
        color: #2b2e50;
    }
    table td {
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.8rem;
        font-weight: 900;
    }
    table td.no-data{
        text-align: center;
    }
    table a {
        text-decoration: none;
        color: #2962ff;
    }
    table .actions{
        margin: 0.3125rem;
        cursor: pointer;
    }


    table tbody tr:nth-child(even) {
        background-color: #f4f6fb;
    }

    @media screen and (max-width: 31.25rem) {


        .recipients-section .recipients-table-section{
            width: 100%;
        }
    }

</style>