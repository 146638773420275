<template>
    <div class="card-details" v-if="transactions">
        <div class="status-page-section" >
            <div class="amount-card" :class="amountCardStyleObject">
                <div class="amount">
                    <span>Amount Sent</span>
                    <span>{{transactions.sending_currency}} {{transactions.sending_amount}}</span>
                </div>
                <div class="amount-arrow">
                    <i class="fas fa-long-arrow-alt-right"></i>
                </div>
                <div class="amount">
                    <span>Amount Received</span>
                    <span>{{transactions.receiving_currency}} {{transactions.receiving_amount}}</span>
                </div>
            </div>
            <div class="status-page-details">
                <div class="recipient-details-section" v-if="transactions.individual_recipient">
                    <span>Recipient</span>
                    <span>{{transactions.individual_recipient.firstname}} {{transactions.individual_recipient.lastname}}</span>
                </div>
                <div class="recipient-details-section" v-if="transactions.business_recipient">
                    <span>Recipient</span>
                    <span>{{transactions.business_recipient.name}}</span>
                </div>
                <div class="mini-transaction-details">
                    <div class="page-section">
                        <div class="transaction-icon">
                            <i class="fas fa-exchange-alt"></i>
                        </div>
                        <div class="status-page" v-if="transactions.individual_recipient">
                            <span>Transaction Method</span>
                            <span>{{transactions.payment_method.name}}</span>
                        </div>
                        <div class="status-page" v-if="transactions.business_recipient">
                            <span>Transaction Method</span>
                            <span>Bank</span>
                        </div>
                    </div>
                    <div class="page-section">
                        <div class="transaction-icon">
                            <i class="fas fa-barcode"></i>
                        </div>
                        <div class="status-page">
                            <span>Transaction ID</span>
                            <span>{{transactions.transaction_code}}</span>
                        </div>
                    </div>
                    <div class="page-section">
                        <div class="transaction-icon">
                            <i class="fas fa-sync-alt"></i>
                        </div>
                        <div class="page-status">
                            <span>Transaction Status</span>
                            <span :class="statusStyleObject">{{transactions.status.charAt(0).toUpperCase() + transactions.status.slice(1)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="transaction-action">

                <button v-if="transactions.business_recipient" @click="reRoute(transactions.business_recipient.id)">Send Again</button>
                <button v-else @click="reRoute()">Send Again</button>
                <router-link class="link" :to="{name:'Dashboard'}">Done</router-link>
            </div>
        </div>
    </div>
</template>

<script>

    import { useRoute, useRouter } from "vue-router";
    import axios from 'axios'
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import Swal from "sweetalert2";

    export default {
        name: "CardDetails",
        setup(){
            const store = useStore();
            const router = useRouter();
            const route = useRoute();

            const token = computed( () => {
                return store.getters.getToken
            })

            const id = route.params.id

            const transactions = ref(null);

            const getTransaction = async () => {
                await store.dispatch('isLoading')
                await axios.get(`/transaction/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    transactions.value = response.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
                })
            }

            getTransaction();

            const amountCardStyleObject = computed( () => {
                return {
                    'success': transactions.value.status === 'processed',
                    'failed': transactions.value.status === 'failed',
                    'canceled': transactions.value.status === 'canceled',
                    'processing': transactions.value.status === 'processing',
                }
            })

            const statusStyleObject = computed( () => {
                return {
                    'success': transactions.value.status === 'processed',
                    'failed': transactions.value.status === 'failed',
                    'canceled': transactions.value.status === 'canceled',
                    'processing': transactions.value.status === 'processing',
                }
            })

            const reRoute = async (id) => {
                if (id){
                    await store.dispatch('isLoading')
                    await axios.get(`/corporate-recipient/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token.value}`
                        },
                    }).then(function (response) {
                        store.dispatch('setCorporateRecipient', response.data.data)
                        store.dispatch('isLoading')
                        router.push({name: 'CorporateRecipient'})
                    }).catch(function (error) {
                        if (error.response){
                            store.dispatch('isLoading')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.errors.join(" "),
                            })
                        }
                    })
                }else{
                    await router.push({name: 'Transfer',})
                }
            }

            return { transactions, amountCardStyleObject, statusStyleObject, reRoute}

        }
    }
</script>

<style scoped>
    .card-details{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-details .status-page-section{
        width: 40%;
        background: #ffffff;
        border-radius: 0.5625rem;
        padding: 0.625rem 2.5rem;
        box-shadow: -15px -15px 15px rgba(255,255,255,0.5),
        15px 15px 15px rgba(0, 0, 0, 0.05);
    }

    .card-details .status-page-section .amount-card{
        display: flex;
        position: relative;
        width: 100%;
        justify-content: space-between;
        padding: 2.5rem;
    }

    .card-details .status-page-section .amount-card.success{
        box-shadow: 0 0.125rem 0.625rem #00B38BCC;
    }

    .card-details .status-page-section .amount-card.failed, .card-details .status-page-section .amount-card.canceled{
        box-shadow: 0 0.125rem 0.625rem #F21D2F;
    }

    .card-details .status-page-section .amount-card.processing{
        box-shadow: 0 0.125rem 0.625rem #FFBF00;
    }

    .card-details .status-page-section .amount-card .amount{
        display: flex;
        flex-direction: column;
    }

    .card-details .status-page-section .amount-card .amount span{
        color: #423E3B;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .amount-card .amount span:nth-of-type(1){
        padding-bottom: 1.25rem;
        font-size: 0.625rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .amount-card .amount span:nth-of-type(2){
        font-size: 1.5625rem;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .amount-card .amount-arrow{
        display: flex;
        align-items: center;
        font-size: 2.5rem;
        color: #383d6f;
    }

    .card-details .status-page-section .status-page-details{
        margin-top: 1.25rem;
    }

    .card-details .status-page-section .status-page-details .recipient-details-section{
        padding: 0.625rem 2.5rem;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0.1875rem 0.1875rem #00000029;
    }

    .card-details .status-page-section .status-page-details .recipient-details-section span:nth-of-type(1){
        font-size: 0.6875rem;
        padding-bottom: 0.625rem;
        color: #423e3b;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .status-page-details .recipient-details-section span:nth-of-type(2){
        font-size: 0.9375rem;
        font-weight: bold;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details{
        margin-top: 0.625rem;
        box-shadow: 0 0.1875rem 0.1875rem #00000029;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section{
        padding: 0.625rem 1.25rem;
        display: flex;
        align-items: center;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .transaction-icon{
        padding-right: 3.125rem;
        font-size: 1.5625rem;
        color: #383d6f;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .status-page{
        display: flex;
        flex-direction: column;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .status-page span:nth-of-type(1){
        font-size: 0.6875rem;
        padding-bottom: 0.625rem;
        color: #423e3b;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .status-page span:nth-of-type(2){
        font-size: 0.9375rem;
        font-weight: bold;
        color: #423E3B;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status{
        display: flex;
        flex-direction: column;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status span:nth-of-type(1){
        font-size: 0.6875rem;
        padding-bottom: 0.625rem;
        color: #423e3b;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status span:nth-of-type(2){
        font-size: 0.9375rem;
        font-weight: bold;
        letter-spacing: 0.047rem;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status .success{
        color: #00B38B;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status .failed, .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status .canceled{
        color: #F21D2F;
    }

    .card-details .status-page-section .status-page-details .mini-transaction-details .page-section .page-status .processing{
        color: #FFBF00;
    }

    .card-details .status-page-section .transaction-action{
        margin-top: 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .link{
        text-decoration: none;
        color: #383d6f;
        letter-spacing: 0.047rem;
        font-weight: bold;
    }


    .card-details .status-page-section .transaction-action button{
        background: #383d6f;
        padding: 0.9375rem;
        border-radius: 0.3125rem;
        width: 90%;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0.047rem;
        margin-bottom: 0.625rem;
        border-style: none;
        color: white;
        cursor: pointer;
    }

    @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
        .card-details .status-page-section{
            width: 70%;
        }
    }


    @media (max-width: 34.375rem) {

        .card-details .status-page-section{
            width: 100%;
            padding: 0.625rem 0.9375rem;
        }

        .card-details .status-page-section .amount-card .amount span:nth-of-type(2){
            font-size: 1.0625rem;
        }

        .card-details .status-page-section .amount-card .amount-arrow{
            font-size: 1.25rem;
        }

        .card-details .status-page-section .amount-card .amount span:nth-of-type(1){
            padding-bottom: 0.625rem;
        }

        .card-details .status-page-section .amount-card{
            padding: 2.5rem 1.875rem;
        }
    }

</style>