<template>
    <div class="payment-channel-card-section" v-if="specificPaymentChannels">
        <div class="payment-channel-section">
            <div v-for="specificPaymentChannel in specificPaymentChannels" :key="specificPaymentChannel.id">
                <div class="payment-channel-card" @click="reRoute(specificPaymentChannel.id, specificPaymentChannel.code, specificPaymentChannel.name, specificPaymentChannel.logo)">
                    <div class="payment-channel-logo">
                        <img v-if="specificPaymentChannel.logo" :src="specificPaymentChannel.logo" alt="mobile wallet logo">
                        <i class="fa-solid fa-briefcase" v-if="specificPaymentChannel.name.toLowerCase().includes('bank') && !specificPaymentChannel.logo"></i>
                        <i class="fa-solid fa-mobile-button" v-if="specificPaymentChannel.name.toLowerCase().includes('mobile') && !specificPaymentChannel.logo"></i>
                        <i class="fa-solid fa-money-bill" v-if="specificPaymentChannel.name.toLowerCase().includes('cash') && !specificPaymentChannel.logo"></i>
                        <i class="fa-solid fa-money-check" v-if="specificPaymentChannel.name.toLowerCase().includes('card') && !specificPaymentChannel.logo"></i>
                    </div>
                    <p class="payment-channel-name">{{specificPaymentChannel.name}}</p>
                </div>
            </div>
          <div class="no-channels-layout" v-if="specificPaymentChannels.length === 0">
            <div class="no-payment-channels-available">
              <img src="../../assets/dashboard/void.svg" alt="no-results-found">
            </div>
            <p>No payout channels available.</p>
          </div>
        </div>
    </div>
    <div v-else class="lds-dual-ring"></div>

</template>

<script>


    import {computed, ref} from "vue";
    import axios from "axios";
    import Swal from 'sweetalert2'
    import {useStore} from "vuex";
    import {useRoute, useRouter} from "vue-router";

    export default {
        name: "Payment Channels",

        setup(){
            const store = useStore();
            const route = useRoute();
            const router = useRouter();
            const token = computed( () => {
                return store.getters.getToken;
            })

            const id = route.params.id
            const specificPaymentChannels = ref(null);



            axios.get(`/payment-channels/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            }).then(function (response) {
                specificPaymentChannels.value = response.data.data;
            }).catch(error => {
                if (error.response){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })

            const reRoute = (id, code, name, logo) => {

                const data = {
                    "code": code,
                    "name": name,
                    "logo": logo
                }

                store.dispatch('setPaymentChannels', data)
                router.push({name: 'AddRecipientDetails', params:{id: id}})
            }

            return { router, reRoute,  specificPaymentChannels }

        }
    }
</script>

<style scoped>
    .payment-channel-card-section{
        margin-top: 1.875rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .payment-channel-section {

        width: 80%;
    }

    .payment-channel-card{
        display: flex;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        align-items: center;
        border-radius: 0.9375rem;
        margin-top: 1.25rem;
        padding: 0.625rem 0 0.625rem 1.25rem;
        width: 100%;
        cursor: pointer;
    }

    .payment-channel-card .payment-channel-logo img{
        max-height: 100%;
        max-width: 100%;
    }

    .payment-channel-card .payment-channel-logo {
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    

    .payment-channel-name {
        margin-left: 2.5rem;
        font-size: 0.8rem;
        color: #383d6f;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    .lds-dual-ring:after {
        width: 3.75rem;
        height: 3.75rem;
        margin: 1.25rem;

    }

    @media (max-width: 34.375rem) {
        .payment-channel-card{
            display: flex;
            box-shadow: 0 0.125rem 0.375rem #00000029;
            align-items: center;
            border-radius: 0.9375rem;
            margin-top: 1.25rem;
            padding: 0.625rem 0 0.625rem 0.625rem;
            width: 100%;
        }

        .payment-channel-section {
            width: 90%;
        }

        .payment-channel-name{
            font-size: 0.7rem;
        }

    }
</style>