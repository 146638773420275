<template>
  <section class="container">
      <div class="text">
        <div class="heading-container">
          <div class="heading">
            <h1>
              <span class="diff-color">Fast, Secure</span> and
              <span class="diff-color">Reliable</span> way to send money.
            </h1>
          </div>
          <div class="rocket-icon">
            <div>
              <img
                src="../../assets/landing_page/Mask_Group_5.png"
                alt="rocket-icon"
                class="blast-off"
              />
            </div>
            <div class="shield">
              <img
                src="../../assets/landing_page/Mask_Group_7.png"
                alt="rocket-icon"
              />
            </div>
          </div>
        </div>

        <p class="subtitle">Send money instantly via Fast Pace Transfer.</p>
        <router-link :to="{ name: 'Register'}">
        <button class="call-to-action">Create a free account</button>
        </router-link>

        <div class="payment-methods">
          <p class="pay-by">Pay by</p>
          <img
            src="../../assets/landing_page/payment.png"
            alt="payment-methods"
          />
        </div>
      </div>
      <div class="box">
        <div class="inner-box">
          <div class="ellipse">
            <div>
              <img
                src="../../assets/landing_page/arrows.svg"
                alt="arrow-icon"
              />
            </div>
          </div>

          <div class="input-design">
            <div class="first-row">
              <p>Currency</p>
            </div>
            <div class="second-row">
              <div class="left-input">
                <span>Send</span>
                <span class="diff-size">0</span>
              </div>

              <div class="right-input">
                <div class="flag">
                  <img
                    src="../../assets/landing_page/Mask_Group_4.png"
                    alt=""
                  />
                </div>
                <span class="diff-size-2">GBP</span>

                <div class="arrow-down">
                  <img
                    src="../../assets/landing_page/Mask_Group_2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="input-design second">
            <div class="first-row">
              <p>Currency</p>
            </div>
            <div class="second-row">
              <div class="left-input">
                <span>Receive</span>
                <span class="diff-size">0</span>
              </div>

              <div class="right-input">
                <div class="flag">
                  <img
                    src="../../assets/landing_page/ghana-162302.png"
                    alt=""
                  />
                </div>
                <span class="diff-size-2">GHS</span>

                <div class="arrow-down">
                  <img
                    src="../../assets/landing_page/Mask_Group_2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
            <router-link class="link" :to="{ name: 'Register'}">
              <button class="btn">
                Get Started
              </button>
            </router-link> 
        </div>
      </div>
    </section>

</template>

<script>
export default {
    name: "LandingPageContent"
}
</script>

<style scoped>

  .container {
    display: flex;
  }

  .text {
    width: 50%;
  }

  .heading-container {
    display: flex;
  }

  .diff-color {
    color: #535ba7;
  }

  .heading {
    margin-left: 40px;
    text-align: left;
    font-size: 35px;
    font-weight: 900;
    letter-spacing: 2px;
  }

  .rocket-icon {
    margin-right: 5px;
    padding-top: 40px;
    position: relative;
  }

  .rocket-icon .blast-off {
    position: absolute;
    top: 32px;
    left: 85px;
  }

  .rocket-icon .shield img {
    height: 170px;
  }

  .subtitle {
    margin-top: 30px;
    margin-left: 40px;
    text-align: left;
    font-size: 25px;
    letter-spacing: 1px;
  }

  .payment-methods {
    margin-top: 45px;
    display: flex;
    margin-left: 15px;
    align-items: center;
  }

  .payment-methods .pay-by {
    font-size: 22px;
    letter-spacing: 1px;
  }


  .box {
    width: 50%;
    position: relative;
    background: #383d6f;

  }

  .inner-box {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 600px;
    height: 470px;
    background: transparent
    linear-gradient(129deg, #ffffff 0%, #ffffff66 0%, #ffffff0d 100%) 0% 0%
    no-repeat;
    border: 1px solid #ffffff99;
    border-radius: 25px;
    opacity: 1;
    margin: 40px 70px 0 60px;

  }

  .inner-box .ellipse {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: transparent linear-gradient(127deg, #535ba7 0%, #383d6f 100%) 0%
    0% no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    left: 300px;
  }

  .inner-box .input-design {
    display: flex;
    flex-direction: column;
    padding: 85px 60px 20px;
    color: #fff;
  }

  .inner-box .input-design.second {
    padding-top: 15px;
  }

  .inner-box .input-design .first-row {
    display: flex;
    justify-content: flex-end;
    margin-right: 70px;
  }

  .inner-box .input-design .second-row {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  .inner-box .input-design .second-row .diff-size {
    font-size: 1.4375rem;
    /*position: relative;*/
    /*width: 7ch;*/
    /*animation: type 1s steps(20), blink 0.5s step-end infinite;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
    /*border-right: 3px solid;*/
  }

  /*.inner-box .input-design .second-row .differ-size {*/
  /*  font-size: 1.4375rem;*/
  /*  position: relative;*/
  /*  width: 6ch;*/
  /*  animation: type 1s steps(20), blink 0.5s step-end infinite;*/
  /*  white-space: nowrap;*/
  /*  overflow: hidden;*/
  /*  border-right: 3px solid;*/
  /*}*/

  /*@keyframes type {*/
  /*  0% {*/
  /*    width: 0;*/
  /*  }*/
  /*}*/

  /*@keyframes blink {*/
  /*  50% {*/
  /*    border-color: transparent;*/
  /*  }*/
  /*}*/


  .inner-box .input-design .second-row .diff-size-2 {
    font-size: 20px;
  }

  .inner-box .input-design .second-row .left-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .inner-box .input-design .second-row .right-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 29%;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .link{

    align-self: center;
    width: 60%;
    margin-top: 50px;



  }
  .link .btn{
    color: #fff;
    background: #00b38b;
    width: 100%;
    border-style: none;
    border-radius: 10px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
  }



  @media screen and (max-width: 280px) {
    .rocket-icon .blast-off {
      width: 1.5625em;
      height: 1.9375em;
      top: 0em;
      left: 3.3125em;
    }

    .rocket-icon .shield img {
      width: 5.6875em;
      height: 6.0625em;
    }

    .subtitle {
      margin-top: 1.25em;
      margin-left: 0.9375em;
      font-size: 1em;
      letter-spacing: 0.0313em;
      line-height: 1.5;
    }

    .pay-by,
    .payment-methods {
      display: none;
    }

    .call-to-action {
      margin-left: 1em;
      margin-top: 1.25em;
      margin-bottom: 1.25em;
      padding: 0.625em;
      border-style: none;
      font-size: 0.775rem;
      color: white;
      border-radius: 0.25em;
      background: #f21d2f;
      font-family: "Lato", sans-serif;
      cursor: pointer;
    }
    .box {
      width: 100%;
      background: #383d6f;
    }

    .inner-box {
      width: 82%;
      margin: 3.75em 1.875em;
      height: 16.0625em;
    }

    .inner-box .ellipse {
      width: 3.75em;
      height: 3.75em;
      top: 1.875em;
      left: 40%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.5625em;
    }

    .inner-box .input-design {
      padding: 3.75em 0.625em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.1875em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 0.3125em 0.625em;
      width: 57%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: 0.625em;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: 0.8125em;
    }

    .inner-box .input-design .second-row .right-input {
      padding: 0.3125em 0.625em;
      width: 40%;
      display: flex;
      align-items: center;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.625em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: 0.75em;
    }

    .link .btn {
      display: none;
    }

  }

  @media screen and (min-width: 281px) and (max-width: 320px) {
    .container {
      flex-direction: column;
      padding-top: 1.5rem;
    }

    .text {
      width: 100%;
      padding-left: 0.3125em;
      padding-right: 0.3125em;
    }

    .heading-container > .rocket-icon {
      align-self: center;
      padding: 0;
      margin-right: 0.375em;
    }

    .heading {
      line-height: 1.7;
      margin-left: 0.9375em;
      font-size: 0.9375em;
      letter-spacing: 0.0938em;
    }

    .rocket-icon .blast-off {
      width: 1.5625em;
      height: 1.9375em;
      top: 0em;
      left: 3.3125em;
    }

    .rocket-icon .shield img {
      width: 5.6875em;
      height: 6.0625em;
    }

    .subtitle {
      margin-top: 1.25em;
      margin-left: 0.9375em;
      font-size: 1em;
      letter-spacing: 0.0313em;
      line-height: 1.5;
    }

    .pay-by,
    .payment-methods {
      display: none;
    }

    .call-to-action {
      margin-left: 1em;
      margin-top: 1.25em;
      margin-bottom: 1.25em;
      padding: 0.625em;
      border-style: none;
      color: white;
      border-radius: 0.25em;
      background: #f21d2f;
      font-family: "Lato", sans-serif;
      cursor: pointer;
    }
    .box {
      width: 100%;
      background: #383d6f;
    }

    .inner-box {
      width: 82%;
      margin: 3.75em 1.875em;
      height: 16.0625em;
    }

    .inner-box .ellipse {
      width: 3.75em;
      height: 3.75em;
      top: 1.875em;
      left: 40%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.5625em;
    }

    .inner-box .input-design {
      padding: 3.75em 0.9375em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.1875em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 0.3125em 0.625em;
      width: 58%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: 0.875em;
    }

    .inner-box .input-design .second-row .right-input {
      padding: 0.3125em 0.625em;
      width: 36%;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: 0.8125em;
    }

    .link .btn {
      display: none;
    }

  }

  @media screen and (min-width: 321px) and (max-width: 374px) {
    .container {
      flex-direction: column;
      padding-top: 1.5rem;
    }

    .text {
      width: 100%;
      padding-left: 0.3125em;
      padding-right: 0.3125em;
    }

    .heading-container > .rocket-icon {
      align-self: center;
      padding: 0;
      margin-right: 0.375em;
    }

    .heading {
      line-height: 1.7;
      margin-left: 0.9375em;
      font-size: 0.9375em;
      letter-spacing: 0.0938em;
    }

    .rocket-icon .blast-off {
      width: 1.5625em;
      height: 1.9375em;
      top: 0em;
      left: 3.3125em;
    }

    .rocket-icon .shield img {
      width: 5.6875em;
      height: 6.0625em;
    }

    .subtitle {
      margin-top: 1.25em;
      margin-left: 0.9375em;
      font-size: 1em;
      letter-spacing: 0.0313em;
      line-height: 1.5;
    }

    .pay-by,
    .payment-methods {
      display: none;
    }

    .call-to-action {
      margin-left: 1em;
      margin-top: 1.25em;
      margin-bottom: 1.25em;
      padding: 0.625em;
      border-style: none;
      color: white;
      border-radius: 0.25em;
      background: #f21d2f;
      font-family: "Lato", sans-serif;
      cursor: pointer;
    }
    .box {
      width: 100%;
      background: #383d6f;
    }

    .inner-box {
      width: 80%;
      margin: 3.75em 2.5em;
      height: 16.0625em;
    }

    .inner-box .ellipse {
      width: 3.75em;
      height: 3.75em;
      top: 1.875em;
      left: 40%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.5625em;
    }

    .inner-box .input-design {
      padding: 3.75em 1.25em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.25em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 0.3125em 0.625em;
      width: 58%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: 0.9375em;
    }

    .inner-box .input-design .second-row .right-input {
      padding: 0.3125em 0.625em;
      width: 36%;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: 0.875em;
    }

    .link .btn {
      display: none;
    }

  }

  @media screen and (min-width: 375px) and (max-width: 767px) {
    .container {
      flex-direction: column;
      padding-top: 1.5rem;
    }

    .text {
      width: 100%;
      padding-left: 0.3125em;
      padding-right: 0.3125em;
    }

    .heading-container > .rocket-icon {
      align-self: center;
      padding: 0;
      margin-right: 1.25em;
    }

    .heading {
      line-height: 1.7;
      margin-left: 0.9375em;
      font-size: 1.125em;
      letter-spacing: 0.0938em;
    }

    .rocket-icon .blast-off {
      width: 1.5625em;
      height: 1.9375em;
      top: 0em;
      left: 3.3125em;
    }

    .rocket-icon .shield img {
      width: 5.6875em;
      height: 6.0625em;
    }

    .subtitle {
      margin-top: 1.875em;
      margin-left: 0.9375em;
      font-size: 1em;
      letter-spacing: 0.0313em;
    }

    .pay-by {
      display: none;
    }

    .payment-methods {
      display: none;
    }

    .box {
      width: 100%;
      background: #383d6f;
    }

    .inner-box {
      width: 81%;
      margin: 5em 2.5em;
      max-height: 18.75em;
    }

    .inner-box .ellipse {
      width: 5em;
      height: 5em;
      top: 2.4375em;
      left: 40%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.875em;
    }

    .inner-box .input-design {
      padding: 3.75em 1.25em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.625em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 0.625em 0.625em;
      width: 58%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: smaller;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: large;
    }

    .inner-box .input-design .second-row .right-input {
      display: flex;
      padding: 0.3125em 0.625em;
      width: 36%;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: medium;
    }

    .link .btn {
      display: none;
    }

    .call-to-action {
      margin-left: 0.875em;
      margin-top: 1.6875em;
      border-radius: 0.3125em;
      margin-bottom: 1.25em;
      border-style: none;
      padding: 0.625em;
      color: #fff;
      background: #f21d2f;
      font-family: "Lato", sans-serif;
      font-size: 0.875em;
    }


  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
      flex-direction: column;
      padding-top: 1.5rem;
    }

    .text {
      width: 100%;
      padding: 0 1.875em;
    }

    .heading-container > .rocket-icon {
      align-self: center;
      padding: 0;
      margin-right: 1.875em;
    }

    .heading {
      line-height: 1.7;
      margin-left: 0.9375em;
      font-size: 2em;
      letter-spacing: 0.0938em;
    }

    .rocket-icon .blast-off {
      top: -0.375em;
      left: 5em;
    }

    .subtitle {
      margin-top: 1.875em;
      margin-left: 0.9375em;
      font-size: 1.375em;
      letter-spacing: 0.0313em;
    }

    .pay-by {
      display: none;
    }

    .payment-methods {
      display: none;
    }

    .call-to-action {
      color: #fff;
      background: #f21d2f;
      font-size: 1.25em;
      margin-top: 2.5em;
      margin-left: 0.9375em;
      margin-bottom: 2.5em;
      padding: 1.0625em;
      width: 50%;
      border-style: none;
      border-radius: 0.4375em;
      cursor: pointer;
      font-family: "Lato", sans-serif;
    }

    .box {
      width: 100%;
      background: #383d6f;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .inner-box {
      width: 54%;
      margin: 3.75em 2.5em;
      max-height: 22.75em;
    }

    .inner-box .ellipse {
      width: 5em;
      height: 5em;
      top: 1.25em;
      left: 45%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.875em;
    }

    .inner-box .input-design {
      padding: 3.75em 1.25em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.625em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 1em 0.625em;
      width: 62%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: 1em;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: 1.375em;
    }

    .inner-box .input-design .second-row .right-input {
      display: flex;
      align-items: center;
      padding: 0.3125em 0.625em;
      width: 27%;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: 1.1875em;
    }

    .link .btn {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1278px) {
    .call-to-action {
      display: none;
    }

    .container{
      height: 85vh;
    }


    .text {
      width: 50%;
      padding: 3.125em 1.875em;
    }

    .heading-container > .rocket-icon {
      align-self: center;
      padding: 0;
      margin-right: 0em;
    }

    .heading {
      line-height: 1.7;
      margin-left: 0.9375em;
      font-size: 1.5em;
      letter-spacing: 0.0938em;
    }

    .rocket-icon .blast-off {
      top: -0.375em;
      left: 5em;
    }

    .subtitle {
      margin-top: 1.875em;
      margin-left: 0.9375em;
      font-size: 1.375em;
      letter-spacing: 0.0313em;
    }

    .box {
      width: 50%;
      background: #383d6f;
      display: flex;
      justify-content: center;
    }

    .inner-box {
      position: relative;
      width: 80%;
      margin: 3.75em 2.5em;
      max-height: 22.75em;
    }

    .inner-box .ellipse {
      width: 5em;
      height: 5em;
      top: -12%;
      left: 41%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.875em;
    }

    .inner-box .input-design {
      padding: 3.75em 1.25em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.625em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 1em 0.625em;
      width: 62%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: 1em;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: 1.375em;
    }

    .inner-box .input-design .second-row .right-input {
      display: flex;
      align-items: center;
      padding: 0.3125em 0.625em;
      width: 27%;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: 1.1875em;
    }

    .link .btn {
      display: none;
    }
  }

  @media screen and (min-width: 1279px) and (max-width: 1439px) {
    .call-to-action {
      display: none;
    }

    .container{
      height: 85vh;
    }

    .text {
      width: 50%;
      padding: 5.5rem 1.875em;
    }

    .heading-container > .rocket-icon {
      align-self: center;
      padding: 0;
      margin-right: 0em;
    }

    .heading {
      line-height: 1.2;
      margin-left: 0.9375em;
      font-size: 1.8125em;
      letter-spacing: 0.0938em;
    }

    .rocket-icon .blast-off {
      top: -0.375em;
      left: 5em;
    }

    .subtitle {
      margin-top: 1.875em;
      margin-left: 0.9375em;
      font-size: 1.375em;
      letter-spacing: 0.0313em;
    }

    .box {
      width: 50%;
      background: #383d6f;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .inner-box {
      width: 60%;
      position: relative;
      margin: 3.75em 2.5em;
      max-height: 23.125em;
    }

    .inner-box .ellipse {
      width: 5em;
      height: 5em;
      top: -11%;
      left: 41%;
    }

    .ellipse div img {
      width: 1.875em;
      height: 1.875em;
    }

    .inner-box .input-design {
      padding: 3.75em 1.25em 0.625em;
    }

    .inner-box .input-design .first-row {
      margin-right: 2.625em;
    }

    .inner-box .input-design .first-row p {
      font-size: 0.6875em;
    }

    .inner-box .input-design .second-row .left-input {
      padding: 1em 0.625em;
      width: 62%;
    }

    .inner-box .input-design .second-row .left-input > span:nth-child(1) {
      font-size: 1em;
    }

    .inner-box .input-design .second-row .diff-size {
      font-size: 1.375em;
    }

    .inner-box .input-design .second-row .right-input {
      display: flex;
      align-items: center;
      padding: 0.3125em 0.625em;
      width: 27%;
    }

    .inner-box .input-design .second-row .right-input .flag img {
      width: 1.25em;
      height: 1.25em;
    }

    .inner-box .input-design .second-row .right-input .arrow-down img {
      width: 0.75em;
    }

    .inner-box .input-design .second-row .diff-size-2 {
      font-size: 1.1875em;
    }

    .link .btn {
      display: none;
    }



  }

  @media screen and (min-width: 1440px) {
    .call-to-action {
      display: none;
    }

    .text, .box{
      padding-top: 4rem;
    }

    .inner-box .ellipse {
      top: 2.5rem;
    }

    .container{
      height: 85vh;
    }

    .payment-methods {
      margin-left: 2.5rem;
    }

    .subtitle {
      margin-top: 2.5rem;
    }

    .rocket-icon {
      margin-right: 1.875rem;
    }

    .link .btn {
      display: none;
    }
  }


</style>