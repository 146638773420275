<template>
<div class="dashboard">
  <Loading v-if="loading"/>
  <Sidebar/>

  <section class="content-section">
    <Navbar/>
    <div class="content">
      <p class="welcome-message">Settings</p>
      <div class="settings-section">
        <nav>
          <ul class="settings-links">
            <li>
              <router-link class="link" :to="{name: 'Profile'}">Profile</router-link>
            </li>
            <li>
              <router-link class="link" :to="{name: 'Documents'}">Documents</router-link>
            </li>
<!--            <li>-->
<!--              <router-link class="link" :to="{name: 'TransactionLimits'}">Transaction Limit</router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link class="link" :to="{name: 'PasswordChange'}">Security</router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link class="link" :to="{name: 'Support'}">Support</router-link>-->
<!--            </li>-->
          </ul>

        </nav>
          <router-view>
          </router-view>
      </div>

    </div>
  </section>
</div>
</template>

<script>
import Sidebar from '@/components/Dashboard/Sidebar.vue'
import Navbar from '@/components/Dashboard/Navbar.vue'
import Loading from "../../../components/common/Loading";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
    name: 'Settings',
    components: { Sidebar, Navbar, Loading },

  setup(){
      const store = useStore();
      const loading = computed( () => {
        return store.state.loading;
      })

      return { loading }
  }




    
}
</script>

<style scoped>

  .settings-section {
    margin-top: 1.25rem;
    width: 100%;
  }

  .settings-section .settings-links{
    display: flex;
    padding: 1.25rem;
    border-top: 0.0625rem solid #a1a1a1;
    border-bottom: 0.0625rem solid #a1a1a1;
    background: white;
  }

  .link{
    text-decoration: none;
    color: #707070;
    padding-bottom: 0.625rem;
  }

  .link:hover{
    color: #383d6f;
  }

  .settings-section .settings-links li{
    list-style: none;
    margin-left: 1.25rem;
    font-size: 0.9375rem;
  }

  .router-link-active{
    color: #383d6f;
    border-bottom: 0.125rem solid #383d6f;
  }


  .settings-section .settings-links li:nth-of-type(1){
    margin-left: 0;
  }

  @media screen and (max-width: 34.375rem){
    .settings-section .settings-links{
      flex-wrap: wrap;
    }

    .settings-section .settings-links li{
      margin-bottom: 1.25rem;
    }

    .link{
      padding-bottom: 0.3125rem;
    }
  }
</style>