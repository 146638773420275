<template>
    <div class="documents-section">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "Documents",


    }
</script>

<style scoped>
    .documents-section{
        display: flex;
        width: 100%;
    }






</style>