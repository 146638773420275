<template>
<div class="dashboard">
  <Loading v-if="loading"/>
  <Sidebar/>

  <section class="content-section">
    <Navbar/>
    <div class="content">
      <p v-if="userData.customer" class="welcome-message">Welcome Back, {{userData.customer.firstname}}</p>
      <p v-else class="welcome-message">Welcome Back {{userData.firstname}}</p>
      <div class="dashboard-content">
        <div class="left-content-section">
          <div class="transfer-money-box">
            <router-link :to="{ name: 'Transfer'}" class="link">
              <div class="box">
                <span>+</span>
              </div>
            </router-link>
            <p>Send money</p>
          </div>
         <PayContent/>
        </div>
        <div class="right-content-section">
          <div class="ads">
            <div class="ads-card">
              <div class="ads-card-image">
                <img src="../../assets/dashboard/Ad1.webp" height="120" width="854" alt="ads"/>
              </div>
              <div class="ads-card-text">
                <p>
                  Choose the fastest way to send money to your loved ones from UK
                </p>
              </div>
            </div>

            <div class="ads-card">
              <div class="ads-card-image">
                <img src="../../assets/dashboard/Ad2.webp" height="120" width="854" alt="ads"/>
              </div>
              <div class="ads-card-text">
                <p>Safe and Easy payments now with FastPace Transfer </p>
              </div>
            </div>
          </div>

          <QuickTransaction/>

          <div class="your-activities-section">
            <div class="activity-tab-titles">
              <h2>Your Recent Activities</h2>
            </div>

            <TransactionActivities/>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
  import Sidebar from '@/components/Dashboard/Sidebar.vue'
  import Navbar from '@/components/Dashboard/Navbar.vue'
  import PayContent from '@/components/Dashboard/PayContent.vue'
  import TransactionActivities from "../../components/Dashboard/TransactionActivities";
  import QuickTransaction from "../../components/Dashboard/QuickTransaction";
  import {computed } from 'vue'
  import { useStore } from "vuex"
  import Loading from "../../components/common/Loading";

  export default {
    name: 'Dashboard_Home',
    components: {Loading, QuickTransaction, TransactionActivities, Sidebar, Navbar, PayContent },

    setup(){

      const store = useStore();


      const loading = computed( () => {
        return store.getters.getLoading
      })



      const userData = computed( () => {
        return store.getters.getUserData
      })






      return {  userData, loading}
    }

    
}
</script>

<style>
  .dashboard{
    height: 100%;
  }
  .content-section{
    position: relative;
    background: #f5f5f5;
    min-height: 100vh;
    width: calc(100% - 15rem);
    left: 15rem;
    transition: all 0.5s ease;
  }
  .sidebar.active ~ .content-section{
    width: calc(100% - 3.75rem);
    left: 3.75rem;
  }

  .sidebar.active ~ .content-section .nav{
    left: 3.75rem;
    width: calc(100% - 3.75rem);
  }

  .content{
    position: relative;
    padding-top: 6.5rem;
    height: 100%;
  }

  .welcome-message{
    font-size: 2rem;
    padding-left: 1.25rem;
    font-weight: 600;
  }

  .dashboard-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.875rem;
    padding: 0 1.25rem;
  }

  .dashboard-content .left-content-section{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-content .right-content-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
  }

  .transfer-money-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 60%;
    box-shadow: 0 0.1875rem 0.375rem #00000029;
    border: 0.0625rem dashed #707070;
    border-radius: 0.9375rem;
    opacity: 1;
    height: 12.5rem;
  }

  .transfer-money-box .link{
    text-decoration: none;
  }

  .transfer-money-box .link .box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.125rem;
    width: 3.125rem;
    background: transparent linear-gradient(306deg, #383d6f 0%, #535ba7 100%) 0
    0 no-repeat;
    border-radius: 0.3125rem;
    box-shadow: 0.625rem 0.5rem 0 rgba(83, 91, 167, 0.7);
    font-weight: 900;
    margin-bottom: 2.1875rem;
    cursor: pointer;
    transition: all 0.5s linear;
  }

  .transfer-money-box .link .box span {
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
    font-size: 1.875rem;
  }

  .ads {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }

  .ads .ads-card {
    cursor: pointer;
    width: 11.25rem;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 0.1875rem 0.375rem #00000029;
    border-radius: 0.9375rem;
  }

  .ads .ads-card .ads-card-image img {
    width: 11.25rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ads .ads-card .ads-card-text {
    font-size: 0.75rem;
    padding: 0.5125rem;
    line-height: 1.5;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .recent-recipients .recipient .name-circle p {
    font-size: 1.25rem;
    color: white;
  }


  .your-activities-section {
    margin-top: 2.5rem;
    width: 80%;
  }

  .activity-tab-titles {
    display: flex;
  }



  @media (max-width: 77.5rem) {
    .sidebar{
      width: 3.75rem;
    }
    .sidebar.active{
      width: 13.75rem;
    }
    .content-section{
      width: calc(100% - 3.75rem);
      left: 3.75rem;
    }
    .sidebar.active ~ .content-section{
      /* width: calc(100% - 220px); */
      overflow: hidden;
      left: 13.75rem;
    }
    .content-section .nav{
      width: calc(100% - 60px);
      left: 3.75rem;
    }
    .sidebar.active ~ .content-section .nav{
      width: calc(100% - 220px);
      left: 13.75rem;
    }
  }


  @media (max-width: 43.75rem) {
    .nav .profile-details .admin_name,
    .nav .profile-details i{
      display: none;
    }
    .content-section .nav .profile-details{
      height: 3.125rem;
      min-width: 2.5rem;
    }

  }
  @media (max-width: 34.375rem) {
    .welcome-message{
      padding-left: 0.3125rem;
      font-size: 1.125rem;
    }

    .dashboard-content{
      gap: 1.25rem;
      margin-top: 1.875rem;
      padding: 0 0.625rem;
    }

    .transfer-money-box{
      width: 50%;
      height: 6.875rem;
    }

    .transfer-money-box .link .box {
      height: 1.875rem;
      width: 1.875rem;
      box-shadow: 0.3125rem 0.5rem 0 rgba(83, 91, 167, 0.7);
      margin-bottom: 1.0625rem;
    }

    .transfer-money-box .link .box span{
      font-size: 1.5625rem;
    }

    .transfer-money-box p{
      font-size: 0.875rem;
    }

    .ads{
      display: none;
      width: 100%;
      flex-wrap: wrap;
      gap: 1.25rem;
    }

    .ads .ads-card{
      width: 100%;
    }

    .ads .ads-card .ads-card-image img{
      width: 100%;
    }



    .your-activities-section{
      width: 100%;
      font-size: 0.75rem;
    }

    .duration-tab-title{
      margin-left: 1.25rem;
    }

    .dashboard-content .left-content-section{
      width: 100%;
    }
    .dashboard-content .right-content-section{
      width: 100%;
    }
    .sidebar.active ~ .content-section .nav .profile-details{
      display: none;
    }
  }

  @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
    .dashboard-content{
      flex-direction: column;
    }

    .dashboard-content .left-content-section, .dashboard-content .right-content-section {
      width: 100%;
    }

    .ads{
      display: none;
    }

    .your-activities-section{
      width: 100%;
    }
  }



</style>

