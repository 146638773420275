import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from "axios"
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
export default createStore({
  state: {
    recipient_country: {},
    sendingCountries: {},
    receivingCountries: {},
    countries: {},
    recipient: {},
    corporateRecipient: {},
    loading: false,
    transaction_type: null,
    transaction_details: {},
    accounts_details: [],
    payment_methods: {},
    payment_channels: {},
    product_name: null,
    product_id: null,
    user: {},
    checkRecipient: null,
    token: null,
    collapse: false
    
  },
  plugins: [createPersistedState({
    paths: ['token','checkRecipient', 'user', 'product_id', 'product_name', 'payment_channels', 'payment_methods', 'accounts_details', 'transaction_details', 'transaction_type', 'corporateRecipient', 'recipient', 'countries', 'sendingCountries', 'receivingCountries', 'recipient_country'],
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    },
  })],
  mutations: {
    setCountries(state, payload){  
      state.countries = payload;
    },
    setSendingCountries(state, payload){  
      state.sendingCountries = payload;
    },
    setReceivingCountries(state, payload){  
      state.receivingCountries = payload;
    },
    setDocumentTypes(state, payload){  
      state.documentTypes = payload;
    },
    setCorporateRecipient(state, payload){
      state.corporateRecipient = payload;
    },
    setPaymentMethods(state, payload){
      state.payment_methods = payload;
    },
    setPaymentChannels(state, payload){
      state.payment_channels = payload;
    },
    setUserData(state, payload){ 
      state.user = payload;
    },
    setRecipientData(state, payload){
      state.recipient = payload
    },

    setRecipientCountry(state,payload){
      state.recipient_country = payload
    },

    //Accounts Details
    setAccountsDetails(state, payload){
      state.accounts_details.push(payload)
    },

    removeAccountDetails(state, payload){
      const index = state.accounts_details.indexOf(payload)
      if (index > -1) {
        state.accounts_details.splice(index, 1);
      }
    },




    setProductName(state, payload){
      state.product_name = payload
    },

    setProductID(state, payload){
      state.product_id = payload
    },

    setTransactionType(state, payload){
      state.transaction_type = payload
    },

    setToken(state, payload){ 
      state.token = payload;
    },

    setCheckRecipient(state, payload){
      state.checkRecipient = payload
    },

    setTransactionDetails(state, payload){
      state.transaction_details = payload
    },


    toggleSidebar(state){ 
      state.collapse = !state.collapse;
    },
    
    isLoading(state){
      state.loading = !state.loading;
    },


    
  },
  actions: {
    async setSendingCountries(state){
      state.commit('isLoading');  
      const data = await axios.get('/countries/sending'); 
      const result = data.data.data ;                         
      state.commit("setSendingCountries", result);
      state.commit('isLoading');
    },

    async setReceivingCountries(state){
      state.commit('isLoading');  
      const data = await axios.get('/countries/receiving'); 
      const result = data.data.data ;                         
      state.commit("setReceivingCountries", result);
      state.commit('isLoading');
    },

    async setCountries(state){
      state.commit('isLoading');  
      const data = await axios.get('/countries?all=yes'); 
      const result = data.data.data ;                         
      state.commit("setCountries", result);
      state.commit('isLoading');
    },


    isLoading(state){
      state.commit('isLoading');
    },

    setUserData(state, payload){
      state.commit('setUserData', payload);
    },
    setToken(state, payload){
      state.commit('setToken', payload);
    },



    setPaymentMethods(state, payload){
      state.commit('setPaymentMethods', payload)
    },

    setPaymentChannels(state, payload){
      state.commit('setPaymentChannels', payload)
    },

    setRecipientData(state, payload){
      state.commit('setRecipientData', payload)
    },

    setRecipientCountry(state,payload){
      state.commit('setRecipientCountry', payload)
    },

    setTransactionType(state, payload){
      state.commit('setTransactionType', payload)
    },

    setAccountsDetails(state, payload){
      state.commit('setAccountsDetails', payload)
    },

    removeAccountDetails(state, payload){
      state.commit('removeAccountDetails', payload)
    },


    setCheckRecipient(state, payload){
      state.commit('setCheckRecipient', payload)
    },

    setProductName(state, payload){
      state.commit('setProductName', payload)
    },

    setProductID(state, payload){
      state.commit('setProductID', payload)
    },

    setCorporateRecipient(state, payload){
      state.commit('setCorporateRecipient', payload);
    },

    setTransactionDetails(state, payload){
      state.commit('setTransactionDetails', payload)
    },


  },
  modules: {
  },
  getters: {

    getToken: state => state.token,
    getCheckRecipient: state => state.checkRecipient,
    getLoading: state => state.loading,
    getTransactionType: state => state.transaction_type,
    getSendingCountries: state => state.sendingCountries,
    getProductName: state => state.product_name,
    getProductID: state => state.product_id,
    getCountries: state => state.countries,
    getPaymentMethods: state => state.payment_methods,
    getPaymentChannels: state => state.payment_channels,
    getReceivingCountries: state => state.receivingCountries,
    getRecipientCountry: state => state.recipient_country,
    getCorporateRecipient: state => state.corporateRecipient,
    getRecipient: state => state.recipient,
    getUserData: state => state.user,
    getAccountsDetails: state => state.accounts_details,
  }
})
