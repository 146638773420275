<template>
    <div class="quick-transaction-section">
        <h2>Quick Transaction</h2>
        <div class="recent-recipients" v-if="recipients">
            <div class="recent-recipient" v-if="recipients.length">
                <div class="recipient" v-for="recipient in recipients" :key="recipient.id">
                    <div class="name-circle" @click="checkLimit(recipient.id)">
                        <p>{{recipient.firstname.charAt(0).toUpperCase()}} {{recipient.lastname.charAt(0).toUpperCase()}}</p>
                    </div>
                    <p class="recipient-name">{{recipient.firstname}} {{recipient.lastname}}</p>
                </div>
            </div>
            <div class="no-recipient" v-else>
                <p>No recipients added.</p>
            </div>

        </div>
        <div v-else class="lds-dual-ring"></div>
    </div>


</template>
<script>
    import axios from 'axios'
    import { ref, computed } from 'vue'
    import { useStore } from  'vuex'
    import Swal from "sweetalert2";
    import {useRouter} from "vue-router";
    export default {
        name: 'QuickTransaction',

        setup(){

            const store = useStore();
            const router = useRouter();
            const recipients = ref(null);

            const token = computed( () => {
                return store.getters.getToken;
            })

            const userData = computed( () => {
                return store.getters.getUserData
            })




            axios.get(`/recipients/${userData.value.customer ? userData.value.customer.id : userData.value.id}`, {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                },
                params: {
                    limit: 4
                }
            }).then(function (response) {
                recipients.value = response.data.data.data;
            }).catch(error => {
                if (error.response){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })

            const checkLimit = async (id) => {
                await store.dispatch('isLoading')
                await axios.get(`/recipient/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    store.dispatch('setRecipientData', response.data.data)
                    store.dispatch('setTransactionType', 'individual')
                    store.dispatch('setCheckRecipient', false );
                    router.push({name: 'NewTransaction'})
                }).catch(function (error) {
                    if (error.response){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })
            }

            return { checkLimit, recipients }
        }
    }
</script>
<style scoped>

    .transfer-money-box .link .box span {
        font-family: "Source Sans Pro", sans-serif;
        color: #fff;
        font-size: 1.875rem;
    }

    .ads .ads-card .ads-card-image img {
        width: 11.25rem;
    }

    .quick-transaction-section {
        margin-top: 2.5rem;
        width: 80%;
    }

    .recent-recipients  {
        margin-top: 1.5625rem;
    }

    .recent-recipient{
        display: flex;
        flex-wrap: wrap;
    }

    .recent-recipients .recent-recipient .recipient{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
    }


    .recent-recipients .recent-recipient .recipient .name-circle {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 50%;
        background: transparent linear-gradient(129deg, #a9a9e4 0%, #1c1f38 100%) 0 0 no-repeat padding-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .recent-recipients .recent-recipient .recipient .name-circle p {
        font-size: 1.25rem;
        color: white;
    }

    .recent-recipients .recent-recipient .recipient .recipient-name {
        margin-top: 0.5625rem;
        color: #423e3b;
        cursor: pointer;
    }



    .duration-tab-title h3:nth-child(1) {
        color: #383d6f;
    }

    .duration-tab-title h3:nth-child(2) {
        margin-left: 1.25rem;
        color: #383d6f4d;
    }

    .lds-dual-ring{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .lds-dual-ring:after {

        width: 4rem;
        height: 4rem;
        margin: 0.9375rem;

    }

    .no-recipient{
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 1.25rem;
        font-weight: 900;
        color: #383d6f;
    }



    @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
        .quick-transaction-section{
            width: 100%;
        }
    }

    @media (max-width: 34.375rem) {

        .transfer-money-box .box span {
            font-size: 1.5625rem;
        }

        .transfer-money-box p {
            font-size: 0.875rem;
        }

        .pay-card p {
            font-size: 1.0625rem;
        }

        .ads .ads-card .ads-card-image img {
            width: 100%;
        }

        .quick-transaction-section {
            width: 100%;
        }

        .recent-recipients {
            margin-top: 1.25rem;
            padding-left: 0;
        }

        .recent-recipients .recent-recipient .recipient{
            width: 100%;
        }



    }


</style>