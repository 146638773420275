<template>
  <router-view/>
</template>


<script>
  import logrocket from 'logrocket'
  // import IdleTimer from "../src/composables/IdleTimer";
  // import {useRouter} from "vue-router";
  // import Swal from "sweetalert2";
  import {useStore } from 'vuex'
  import { computed } from 'vue'
  // import { onMounted, computed, ref } from 'vue'
  export default {
    name: 'App',
    setup(){

      logrocket.init('j1sywq/fp-web-prod');


      // const router = useRouter();
      const store = useStore();
      //
      const token = computed( () => {
        return store.getters.getToken
      })

      if (token.value){
        const userData = computed( () => {
          return store.getters.getUserData
        })

        if (userData.value){
          logrocket.identify(`${userData.value.customer ? userData.value.customer.id : userData.value.id}`, {
            name: `${userData.value.customer ? (userData.value.customer.firstname + ' ' + userData.value.customer.lastname) : (userData.value.firstname + ' ' + userData.value.lastname)}`,
            email: `${userData.value.customer ?  (userData.value.email) : (userData.value.user.email)}`,
          })
        }



      }
      //
      // const timer = ref(null)
      //
      // onMounted( () => {
      //   console.log('Mounted')
      //   if (token.value){
      //     timer.value = new IdleTimer({
      //       timeout: 10, //expire after 10 seconds
      //       onTimeout: () => {
      //         localStorage.removeItem('vuex');
      //         router.push({name: 'Login'})
      //       },
      //       onExpired: () => {
      //         //do something if expired on load
      //         localStorage.removeItem('vuex');
      //         router.push({name: 'Login'})
      //         Swal.fire({
      //           icon: 'error',
      //           title: 'Error',
      //           text: 'Your session has expired',
      //         })
      //       }
      //     });
      //
      //   }
      // })



  }


}
</script>

<style>
.error {
  font-size: 12px;
  color: #F21D2F;
  margin-bottom: 8px;
}

@keyframes shakeError {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0.375rem);
    }
    30% {
        transform: translateX(-0.375rem);
    }
    45% {
        transform: translateX(0.375rem);
    }
    60% {
        transform: translateX(-0.375rem);
    }
    75% {
        transform: translateX(0.375rem);
    }
    90% {
        transform: translateX(-0.375rem);
    }
    100% {
        transform: translateX(0);
    }
}

.input--error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.form-wrap form .inputs .input.input--error input{
  border: 1px solid #F21D2F !important;
}

.form-wrap form .inputs .input.input--success input{
  border: 1px solid green !important;
}
</style>
