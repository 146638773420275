<template>
  <div class="pay-card-section">
<!--        <div class="pay-card">-->
<!--        <img src="../../assets/dashboard/ghana.png" alt="" />-->
<!--        <p>Ghana</p>-->
<!--        </div>-->
<!--        <div class="pay-card">-->
<!--        <img src="../../assets/dashboard/zambia.png" alt="" />-->
<!--        <p>Zambia</p>-->
<!--        </div>-->
<!--        <div class="pay-card">-->
<!--        <img src="../../assets/dashboard/gambia.png" alt="" />-->
<!--        <p>Gambia</p>-->
<!--        </div>-->
<!--        <div class="pay-card">-->
<!--        <img src="../../assets/dashboard/nigeria.png" alt="" />-->
<!--        <p>Nigeria</p>-->
<!--        </div>-->
        <div class="coming-soon">
              <p>Coming Soon!</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
      .pay-card-section{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
      }
      .pay-card {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.9375rem;
            margin-bottom: 0.9375rem;
            background: #ffffff;
            padding: 1.875rem;
            box-shadow: 0 0.125rem 0.375rem #00000029;
            border-radius: 0.5rem;
            cursor: pointer;
      }

      .pay-card img {
            width: 30%;
            margin-bottom: 1.25rem;
      }

      .pay-card p {
            font-size: 1.25rem;
            color: #383d6f;
      }

      .coming-soon{
          font-size: 20px;
          letter-spacing: 2px;
          margin-top: 20px;
          color: #000000;
          width: 80%;
          display: flex;
          justify-content: flex-end;
          font-weight: bold;
      }

      @media (max-width: 34.375rem) {
            .pay-card{
                  width: 80%;
                  padding: 0.5rem;
            }

            .pay-card p {
                  font-size: 1.0625rem;
                  color: #383d6f;
            }

            .pay-card img{
                  width: auto;
                  margin-bottom: 0.3125rem;
            }

          .coming-soon{
              width: 90%;
              font-size: 15px;
          }
      }
</style>