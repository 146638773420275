<template>
  <div class="recipient-content-section" v-if="recipients">
        <div class="recipient-card" v-for="recipient in recipients" :key="recipient.id" @click="checkLimit(recipient.id)">
            <div class="recipient" >
                <div class="name-circle">
                  <p>{{recipient.firstname.charAt(0).toUpperCase()}} {{recipient.lastname.charAt(0).toUpperCase()}}</p>
                </div>
                <p class="recipient-name">{{recipient.firstname}} {{recipient.lastname}}</p>
              </div>
        </div>
  </div>
    <div v-else class="lds-dual-ring"></div>
</template>

<script>
    import axios from 'axios'
    import { ref, computed } from 'vue'
    import { useStore } from  'vuex'
    import Swal from "sweetalert2";
    import {useRouter} from "vue-router";
    export default {
        name: 'RecepientCard',

        setup(){

            const store = useStore();
            const router = useRouter();
            const recipients = ref(null);

            const token = computed( () => {
                return store.getters.getToken;
            })

            axios.get('/recipients', {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            }).then(function (response) {
                recipients.value = response.data.data.data;
            }).catch(error => {
                if (error.response){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })

            const checkLimit = async (id) => {
                await store.dispatch('isLoading')
                await axios.get(`/recipient/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    store.dispatch('setRecipientData', response.data.data)
                    store.dispatch('setTransactionType', 'individual')
                    store.dispatch('setCheckRecipient', false );
                    router.push({name: 'NewTransaction'})
                }).catch(function (error) {
                    if (error.response){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })
            }

            return { recipients, checkLimit }
        }
    }
</script>

<style scoped>
    .recipient-card {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0.9375rem;
        background: #ffffff;
        padding: 0.625rem 1.5625rem;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        border-radius: 0.5rem;
        cursor: pointer;
    }


    .recipient-content-section {
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

    }

    .recipient{
        display: flex;
        align-items: center;
        margin-right: 1.25rem;
    }

    .recipient .name-circle {
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 50%;
        background: transparent linear-gradient(129deg, #a9a9e4 0%, #1c1f38 100%) 0
        0 no-repeat padding-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: white;
    }

    .recipient .recipient-name{
        font-size: 1.25rem;
        margin-left: 1.875rem;
    }

    .lds-dual-ring:after {

        width: 4rem;
        height: 4rem;
        margin: 0.9375rem;

    }

    @media screen and (max-width: 31.25rem){
        .recipient{
            margin-right: 0;
        }

        .recipient-card{
            padding: 0.625rem;
        }


    }
</style>