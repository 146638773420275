<template>
      <p class="welcome-message">Transaction History</p>
      <div class="transaction-history-section">
        <div class="transaction-history">
        <table >
          <thead>
          <tr>
            <th>REFERENCE CODE</th>
            <th>TYPE</th>
            <th>CHANNEL</th>
            <th>RECIPIENT</th>
            <th>SENDING</th>
            <th>CHARGE</th>
            <th> RECEIVING</th>
            <th>DATE</th>
            <th>STATUS</th>
            <th>ACTION</th>
          </tr>
          </thead>
          <tbody v-if="transactions.length">
            <tr v-for="(transaction) in transactions" :key="transaction.id">
              <td>{{transaction.transaction_code}}</td>
              <td v-if="transaction.type === 'business'"><span class="cor-type">Corporate</span></td>
              <td v-if="transaction.type === 'individual'"><span class="ind-type">{{transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}}</span></td>
              <td v-if="transaction.individual_recipient">{{transaction.payment_channel.name}}</td>
              <td v-else>Bank</td>
              <td v-if="transaction.individual_recipient">{{transaction.individual_recipient.firstname}} {{transaction.individual_recipient.lastname}}</td>
              <td v-else>{{transaction.business_recipient.name}}</td>
              <td class="amount">{{transaction.sending_currency}} {{transaction.sending_amount}}</td>
              <td class="amount">{{transaction.charge}}</td>
              <td class="amount">{{transaction.receiving_currency}} {{transaction.receiving_amount}}</td>
              <td>{{convertDate(transaction.created_at)}}</td>
              <td v-if="transaction.status === 'processing'"><span class="processing">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</span></td>
              <td v-else-if="transaction.status === 'canceled'"><span class="cancelled">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</span></td>
              <td v-else-if="transaction.status === 'failed'"><span class="failed">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</span></td>
              <td v-else-if="transaction.status === 'processed'"><span class="processed">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</span></td>
              <td v-else>N\A</td>
              <td class="action" @click="reRoute(transaction.id)">View <i class="far fa-eye"></i></td>
            </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="no-data" colspan="10">no transactions at the moment. <router-link :to="{name: 'Transfer'}">Start now</router-link></td>
          </tr>
          </tbody>
        </table>
        </div>
      </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import {useRouter} from "vue-router";

export default {
    name: 'History',

    setup(){
      const store = useStore();

      const router = useRouter();

      const token = computed( () => {
        return store.getters.getToken
      })

      const transactions = ref([]);

      const reRoute = (id) => {
        router.push({name: 'HistoryDetails', params: { transaction_id : id}})
      }

      const getTransactions = async () => {
        await store.dispatch('isLoading')
        await axios.get('/transactions', {
          headers: {
            'Authorization': `Bearer ${token.value}`
          }
        }).then( function (response) {
          store.dispatch('isLoading')
          transactions.value = response.data.data.data

        }).catch(function (error) {
          if (error.response){
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.errors.join(" "),
            })
            store.dispatch('isLoading')
          }
        })
      }

      getTransactions();


      const convertDate = (value) => {
        const localizedFormat = require('dayjs/plugin/localizedFormat')
        dayjs.extend(localizedFormat)
        return dayjs(value).format('llll');
      }

      return{ reRoute, transactions, convertDate }
    }


    
}
</script>

<style scoped>
  .transaction-history{
    margin: 1.25rem;
    overflow-x: auto;
  }



  table th{
    padding: 0.7rem;
    color: #26316c;
  }

  table td {
    text-transform: uppercase;
    letter-spacing: 0.047rem;
    padding: 0.7rem;
    font-size: 0.65rem;
    font-weight: 400;
  }

  table td.action{
    cursor: pointer;
  }

  table td span.processing{
    border-radius: 0.2rem;
    background-color: #FFBF00;
    padding: 0.3125rem;
    text-align: center;
    color: white;
    font-size: 0.5rem;
  }
  table td span.failed{
    border-radius: 0.2rem;
    background-color: #E32227;
    padding: 0.3125rem;
    text-align: center;
    color: white;
    font-size: 0.5rem;
  }
  table td span.processed{
    border-radius: 0.2rem;
    background-color: #00B38B;
    padding: 0.3125rem;
    text-align: center;
    color: white;
    font-size: 0.5rem;
  }
  table td span.cancelled{
    border-radius: 0.2rem;
    background-color: #E32227;
    padding: 0.3125rem;
    text-align: center;
    color: white;
    font-size: 0.5rem;
  }

  table td span.cor-type{
    border-radius: 0.2rem;
    background-color: #707070;
    padding: 0.3125rem;
    text-align: center;
    color: white;
    font-size: 0.5rem;
  }

  table td span.ind-type{
    border-radius: 0.2rem;
    background-color: #535ba7;
    padding: 0.3125rem;
    text-align: center;
    color: white;
    font-size: 0.5rem;
  }

  table .amount {
    color: red;
    font-weight: bold;
    text-align: left;
  }

  @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
    table td{
      padding: 0.3rem;
      font-size: 0.5rem;
    }
  }

  @media screen and (max-width: 31.25rem){
    .transaction-history{
      margin: 0.625rem;
    }

    table td{
      padding: 0.3rem;
      font-size: 0.5rem;
    }
  }
</style>