<template>
<div>
  <Loading v-if="loading"/>
  <div class="form-wrap">
        <form @submit.prevent="create_profile" class="register" autocomplete="off">
            <h2 class="profile-heading">Create Your Fast Pace Transfer Profile</h2>
            <div class="inputs">
                <div class="input" :class="{'input--error': v$.firstname.$error}">
                    <input  @blur="v$.firstname.$touch()" type="text" placeholder="First Name" v-model="state.firstname"/>
                    <i class="icon far fa-user"></i> 
                </div>
                <div class="input" :class="{'input--error': v$.lastname.$error}">
                    <input  @blur="v$.lastname.$touch()" type="text" placeholder="Last Name" v-model="state.lastname" />
                    <i class="icon far fa-user"></i>
                </div>
                <div class="input" :class="{'input--error': v$.place_of_birth.$error}">
                   <input type="text"  @blur="v$.place_of_birth.$touch()" placeholder="Place of Birth" v-model="state.place_of_birth"/>
                    <i class="icon fas fa-map-marker-alt"></i>
                </div>
                <div class="input" :class="{'input--error': v$.date_of_birth.$error}">
                    <input @blur="v$.date_of_birth.$touch()" placeholder="Date Of Birth" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" v-model="state.date_of_birth" />
                    <i class="icon far fa-calendar-alt"></i>
                </div>
                <div class="input" :class="{'input--error': v$.city.$error}">
                   <input @blur="v$.city.$touch()" type="text" placeholder="City" v-model="state.city"/>
                    <i class="icon fas fa-map-marker-alt"></i>
                </div>
                <div class="input" :class="{'input--error': v$.street_address.$error}">
                   <input @blur="v$.street_address.$touch()" type="text" placeholder="Street Address" v-model="state.street_address"/>
                    <i class="icon fas fa-map-marker-alt"></i>
                </div>
                <div class="input" >
                  <select v-model="state.nationality">
                      <option disabled selected value="">Nationality</option>
                      <option :value="country.code" v-for="country in countries" :key="country.id">
                        {{country.name}}
                      </option>
                    </select>
                  <i class="icon fas fa-globe"></i>
                </div>
                <div class="input" :class="{'input--error': v$.post_code.$error}">
                   <input @blur="v$.post_code.$touch()" type="text" placeholder="Post Code" v-model="state.post_code"/>
                    <i class="icon fas fa-map-marker-alt"></i>
                </div>
                <div class="input" >
                  <select v-model="state.resident_country">
                      <option disabled selected value="">Resident Country</option>
                      <option :value="country.code" v-for="country in sendingCountries" :key="country.id">
                        {{country.name}}
                      </option>
                    </select>
                  <i class="icon fas fa-globe"></i>
                </div>
                <div class="input" >
                    <select v-model="state.gender">
                      <option disabled selected value="">Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="O">Prefer not to say</option>
                    </select>
                    <i class="icon fas fa-transgender-alt"></i>
                </div>
            </div>
            <button :disabled="v$.$invalid" class="profile-btn">Sign Up</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</div>
</template>

<script>
import { useStore } from "vuex"
import useVuelidate from '@vuelidate/core'
import Loading from '../components/common/Loading.vue'
import Swal from "sweetalert2"
import { required } from '@vuelidate/validators'
import { reactive, computed } from "vue";
import dayjs from "dayjs";
import axios from "axios"
import {useRouter} from "vue-router";
export default {
    name: "Register",
    components: { Loading },


    setup(){

    const store  = useStore();
    const router = useRouter();
    store.dispatch('setSendingCountries');

    
    const loading = computed( () => {
      return store.state.loading;
    })

    const countries = computed( () => {
        return store.getters.getCountries
    })

    const sendingCountries = computed( () => {
      return store.getters.getSendingCountries
    })

    const token = computed( () => {
        return store.getters.getToken
    })
    
       // Grabbing inputs

     const state = reactive({
        firstname: "",
        lastname: "",
        date_of_birth: "", 
        place_of_birth: "",
        street_address: "", 
        city: "",
        post_code: "",
         gender: "",
         nationality: "",
         resident_country: ""
    });

     // Validation rules
      const rules = {
        firstname : { required },
        lastname : { required },
        date_of_birth : { required },
        place_of_birth : { required },
        street_address : { required },
        city : { required },
        post_code : { required },
          gender: { required },
          nationality: { required },
          resident_country: { required }
      }

      const v$ = useVuelidate(rules, state);
      
      
      


      // Actual registration function
     const create_profile = async () => {

        // Run validate function
          v$.$validate;

        // If there are no errors
          if (!v$.$error) {
              await store.dispatch('isLoading');
            const data =

            {
                firstname: state.firstname,
                lastname: state.lastname, 
                date_of_birth: dayjs(state.date_of_birth).format('DD-MM-YYYY'),
                place_of_birth: state.place_of_birth,
                nationality: state.nationality,
                street_address: state.street_address, 
                city: state.city,
                gender: state.gender,
                resident_country: state.resident_country,
                post_code: state.post_code
            }


                        await axios.post('/customer', data, {
                            headers: {
                                'Authorization': `Bearer ${token.value}`
                            }
                        })
                        .then( response => {
                            if (response.status === 200 || response.status === 201 || response.status === 202){
                                const result = response.data.data;
                                store.dispatch('isLoading');
                                store.dispatch('setUserData', result);
                                router.push({ name: 'Dashboard'})
                            }

                        })
                        .catch(error => {
                          if (error.response) {
                              store.dispatch('isLoading');
                            Swal.fire({
                              icon: 'error',
                              title: 'Error',
                              text: error.response.data.errors.join(" "),
                            }
                            )
                          }
                     });
         

                
        }
     }
      
      



      return {v$, loading, countries, create_profile, state, sendingCountries};
    }
}
</script>

<style scoped>

    .register h2 {
        max-width: 28.125rem;
    }



    .form-wrap form .inputs .input select {
        width: 100%;
        height: 3.125rem;
        background-color: #f2f7f6;
        border: none;
        padding: 0.25rem 0.25rem 0.25rem 1.875rem;
    }

    .form-wrap form .inputs .input select:focus{
        outline: none;
    }

    @media screen and (max-width: 550px) {
        .form-wrap{
            height: initial;
        }
    }

 
</style>