<template>
    <div class="transaction-details-section" v-if="recipient_details">
        <div class="transaction-details">
            <div class="transaction-details-title">
                <span><router-link class="link" :to="{name:'Recepients'}"> <i class="fas fa-chevron-left"></i> &nbsp;Back</router-link></span>
                <div class="title">
                    <span>Recipient Details</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>First Name: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.firstname}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Last Name: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.lastname}}</span>
                </div>
            </div>
            <div class="details-section" v-if="recipient_details.email">
                <div class="label">
                    <span>Email Address: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.email}}</span>
                </div>

            </div>
            <div class="details-section" v-if="recipient_details.address">
                <div class="label">
                    <span>Address: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.address}}</span>
                </div>

            </div>
            <div class="details-section" v-if="recipient_details.phone_number">
                <div class="label">
                    <span>Phone Number: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.phone_number}}</span>
                </div>
            </div>
            <div class="details-section" v-if="recipient_details.payment_channel.payment_method.id === 3">
                <div class="label">
                    <span>Bank Name: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.bank_name}}</span>
                </div>

            </div>

            <div class="details-section" v-if="recipient_details.payment_channel.payment_method.id === 3">
                <div class="label">
                    <span>Bank Account Number: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.bank_account_number}}</span>
                </div>
            </div>

            <div class="details-section">
                <div class="label">
                    <span>Relationship To Sender: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.relationship.charAt(0).toUpperCase() + recipient_details.relationship.slice(1)}}</span>
                </div>
            </div>

            <div class="details-section">
                <div class="label">
                    <span>Country: </span>
                </div>
                <div class="details">
                    <span>{{recipient_details.recipient_country.name}}</span>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import {useRoute} from "vue-router";
    import {useStore} from "vuex";
    import {computed, ref} from "vue";
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "Recipient_Details",

        setup(){
            const route = useRoute();
            const store = useStore();

            const token = computed( () => {
                return store.getters.getToken
            })

            const id = route.params.recipient_id

            const recipient_details = ref(null);

            const getRecipient = async () => {
                await store.dispatch('isLoading')
                await axios.get(`/recipient/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    recipient_details.value = response.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
                })
            }

            getRecipient();



            return { recipient_details }
        }
    }
</script>

<style scoped>

    .transaction-details{
        width: 50%;
    }

    .transaction-details-section .transaction-details .details-section .details{
        padding-left: 0;
    }

    .link{
        text-decoration: none;
        color: #383d6f;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }

    @media screen and (min-width: 600px) and (max-width: 900px) {
        .transaction-details{
            width: 90%;
        }
    }

    @media screen and (max-width: 550px) {
        .transaction-details{
            width: 90%;
        }

        .link{
            font-size: 15px;
        }
    }

</style>