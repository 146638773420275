<template>
    <div class="recipient-category" >
        <div class="title" v-if="corporate_recipient">
            <img :src="corporate_recipient.logo" alt="corporate-logo">
            <h1>{{corporate_recipient.name}}</h1>
        </div>
        <div class="recipient-card-section">
            <div class="recipient-card" v-for="product in corporate_recipient.products" :key="product.id" @click="reRoute(product.id, product.name)">
                <p>{{product.name}}</p>
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import { computed } from 'vue'
    import {useRouter} from "vue-router";

    export default {
        name: "CorporateRecipient",

        setup(){
            const store = useStore();
            const router = useRouter();

            const corporate_recipient = computed( () => {
                return store.getters.getCorporateRecipient
            })

            const reRoute = (id, name) => {
                if (corporate_recipient.value.validation_fields){
                    store.dispatch('setProductName', name)
                    store.dispatch('setProductID', id)
                    router.push({name: 'CorporateProductDetails', params: { product_id : id}})
                }else  {
                    router.push({name: 'NewTransaction'})
                }

            }


            return {  reRoute, corporate_recipient }
        }
    }
</script>

<style scoped>
    .recipient-category{
        width: 80%;
        background: white;
        box-shadow: 0 0.125rem 0.375rem #00000029;
    }

    .recipient-category .title{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recipient-category .title img{
        height: 5.625rem;
        object-fit: cover;
    }

    .recipient-category .title h1{
        color: #423e3b;
    }

    .recipient-category .recipient-card-section{
        display: flex;
        flex-wrap: wrap;
        padding: 1.25rem;
    }

    .recipient-category .recipient-card-section .recipient-card{
        display: flex;
        justify-content: space-between;
        margin: 0.625rem;
        width: 47%;
        padding: 0.825rem;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        color: #383D6F;
        cursor: pointer;
    }

    @media screen and (min-width: 600px) and (max-width: 900px) {
        .recipient-category .recipient-card-section .recipient-card{
            width: 90%;
        }
    }


    @media screen and (max-width: 31.25rem){
        .recipient-category{
            width: 90%;
        }
        .recipient-category .recipient-card-section{
            flex-direction: column;
            padding: 1rem;
        }
        .recipient-category .recipient-card-section .recipient-card{
            width: 100%;
            margin: 0.625rem 0;
        }
    }
</style>