<template>
    <div class="reset-password">
        <Loading v-if="loading"/>
        <div class="form-wrap">
            <form @submit.prevent="forgotPassword" class="reset" autocomplete="off">
                <img src="../assets/landing_page/logo_2.png" height="100" width="100"/>
                <p class="login-register">
                    Back to
                    <router-link class="router-link" :to="{ name: 'Login' }">Login</router-link>
                </p>
                <h2>Reset Password</h2>
                <p class="subtitle">Enter your new password with the OTP you received</p>
                <div class="inputs">
                    <div class="input" :class="{'input--error': v$.otp.$error}">
                        <input @blur="v$.otp.$touch()" type="password" placeholder="OTP 6-digit code" v-model="state.otp" />
                        <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                    </div>
                    <div v-if="v$.otp.$error">
                        <p :class="{'error': v$.otp.required.$invalid}"  v-if="v$.otp.required.$invalid">Please enter your code</p>
                        <p :class="{'error': v$.otp.minLength.$invalid}"  v-if="v$.otp.minLength.$invalid">Minimum length is 6 characters</p>
                        <p :class="{'error': v$.otp.maxLength.$invalid}"  v-if="v$.otp.maxLength.$invalid">Maximum length is 6 characters</p>
                    </div>
                    <div class="input" :class="{'input--error': v$.password.$error}">
                        <input @blur="v$.password.$touch()" type="password" placeholder="Password" v-model="state.password" />
                        <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                    </div>
                    <div v-if="v$.password.$error">
                        <p :class="{'error': v$.password.required.$invalid}"  v-if="v$.password.required.$invalid">Please enter your password</p>
                        <p :class="{'error': v$.password.minLength.$invalid}"  v-if="v$.password.minLength.$invalid">Password should be at least 8 characters</p>
                        <p :class="{'error': v$.password.containsLowercase.$invalid || v$.password.containsUppercase.$invalid || v$.password.containsNumber.$invalid || v$.password.containsSpecial.$invalid}"  v-if="v$.password.containsLowercase.$invalid || v$.password.containsUppercase.$invalid || v$.password.containsNumber.$invalid || v$.password.containsSpecial.$invalid">Password must have at least one uppercase letter, lowercase letter, number and special character</p>

                    </div>
                    <div class="input" :class="{'input--error': v$.password_confirmation.$error}">
                        <input @blur="v$.password_confirmation.$touch()" type="password" placeholder="Confirm password" v-model="state.password_confirmation" />
                        <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                    </div>
                    <div v-if="v$.password_confirmation.$error">
                        <p :class="{'error': v$.password_confirmation.required.$invalid}"  v-if="v$.password_confirmation.required.$invalid">Please confirm your password</p>
                        <p :class="{'error': v$.password_confirmation.sameAs.$invalid}"  v-if="v$.password_confirmation.sameAs.$invalid">Must match password entered above</p>
                    </div>

                </div>
                <button :disabled="v$.$invalid">Reset</button>
                <div class="angle"></div>
            </form>
            <div class="background"></div>
        </div>
    </div>
</template>

<script>
    import { useStore } from "vuex"
    import useVuelidate from '@vuelidate/core'
    import {required, minLength, sameAs, maxLength} from '@vuelidate/validators'
    import { ref, reactive, computed } from 'vue';
    import axios from 'axios'
    import Loading from '../components/common/Loading.vue'
    import Swal from "sweetalert2";
    import {useRouter} from "vue-router";


    export default {
        name: "ChangePassword",
        components: { Loading },

        setup(){

            const store  = useStore();
            const router = useRouter();
            store.dispatch('setCountries');


            const loading = computed( () => {
                return store.state.loading;
            })

            const countries = computed( () => {
                return store.getters.getSendingCountries
            })

            const token = computed( () => {
                return store.getters.getToken
            })



            const country_code = ref(null);
            const state = reactive({
                password : "",
                otp: "",
                password_confirmation : "",
            });

            // Validation rules
            const rules = computed( () => ({
                password: {
                    required,
                    minLength: minLength(8),
                    containsUppercase: function(value) {
                        return /[A-Z]/.test(value)
                    },
                    containsLowercase: function(value) {
                        return /[a-z]/.test(value)
                    },
                    containsNumber: function(value) {
                        return /\d/.test(value)
                    },
                    containsSpecial: function(value) {
                        return /[#?!@$%^&*-]/.test(value)
                    }
                },
                otp: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(6)
                },
                password_confirmation: {
                    required,
                    sameAs: sameAs(state.password)
                }
            }))

            const v$ = useVuelidate(rules, state);

            const forgotPassword = () => {
                //Loading function
                store.dispatch('isLoading')

                // Run validate function
                v$.$validate;

                // Check for errors
                if (!v$.$error) {

                    //Create data for axios POST request
                    const data =
                        {
                            "password": state.password,
                            "otp": state.otp,
                            "password_confirmation": state.password_confirmation
                        }

                    //    Create axios POST Request

                    axios.post('/password-change', data, {
                        headers: {
                            'Authorization': `Bearer ${token.value}`
                        }
                    }).then(function (response) {
                        if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203){
                            store.dispatch('isLoading')
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: "Password reset successful! Rerouting to Login",
                            })
                            router.push( { name: 'Login'})
                        }
                    }).catch(function (error) {
                        if (error.response){
                            store.dispatch('isLoading')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.errors.join(" "),
                            })
                        }
                    })



                }

            }
            return {v$, loading, forgotPassword, state, country_code, countries };

        }
    }
</script>

<style scoped>
    .reset-password {
        position: relative;
    }

    .reset-password .form-wrap .reset h2 {
        margin-bottom: 0.5rem;
    }

    .subtitle{
        text-align: center;
        margin-bottom: 1.875rem;
        max-width: 25rem;
    }
</style>