<template>
  <header>
      <nav>
        <div class="logo">
          <router-link :to="{ name: 'Home'}">
          <img
            src="../../assets/landing_page/FastPaceTransfer_Logo_Resized.png"
            alt="company_logo"
          />
          </router-link>
        </div>
        <ul>
          <li id="login"><router-link class="link" :to="{ name: 'Login'}">Login</router-link> </li>
          <li id="sign_up"><router-link class="link" :to="{ name: 'Register'}">Sign Up</router-link> </li>
        </ul>
      </nav>
    </header>
    
</template>

<script>

export default {
    name: "Navigation",
    components:{},

    

    
}
</script>

<style scoped>

  header {
    height: 8.125rem;
    padding-top: 1.2125rem;
    padding-bottom: 1.3125rem;
    background: linear-gradient(90deg, #fff 50%, #383d6f 50%);
  }

  header nav {
    height: 5rem;
    display: flex;
    justify-content: space-between;
  }

  header nav ul {
    display: flex;
    align-items: center;
    margin-right: 2.5rem;
  }

  header nav ul li {
    list-style-type: none;
    font-family: "Roboto", sans-serif;
    font-size: 1.325rem;

    cursor: pointer;
  }

  .link{
    text-decoration: none;
    color: #fff;
  }

  #sign_up {
    background: #f21d2f;
    margin-left: 3rem;
    border: 0.0625rem solid #f21d2f;
    border-radius: 0.9375rem;
    padding: 1rem;
  }

  .logo {
    padding-left: 2.5rem;
    padding-top: 1.25rem;
  }

  .logo router-link img {
    width: 18.75rem;
  }

  @media screen and (max-width: 17.5rem) {
    header {
      height: 5rem;
      padding: 0;
      background: #fff;
    }

    header nav {
      height: 4rem;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0rem 0.125rem 0.1875rem #00000029;
      opacity: 1;
    }

    header nav ul {
      margin: 0;
    }



    .link{
      color: #383d6f;
      font-size: 0.6875rem;
    }

    #sign_up {
      color: #fff;
      margin-left: 1.5rem;
      margin-right: 0.5rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.3125rem 0.75rem 0.625rem 0.75rem;
      border-radius: 0.5375rem;
    }

    #sign_up .link {
      color: white;
    }

    .logo {
      display: flex;
      padding-left: 1.0625rem;
      padding-top: 0;
    }

    .logo img {
      width: 5.625rem;

    }

  }

  @media screen and (min-width: 17.5625rem) and (max-width: 20rem) {
    header {
      height: 4rem;
      padding: 0;
      background: #fff;
    }

    header nav {
      height: 3rem;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0rem 0.125rem 0.1875rem #00000029;
      opacity: 1;
    }

    header nav ul {
      margin: 0;
    }

    .link {
      color: #383d6f;
      font-size: 0.8125rem;
    }



    #sign_up {
      color: #fff;
      margin-left: 2rem;
      margin-right: 1rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.0625rem 0.5625rem 0.3125rem 0.5625rem;
      border-radius: 0.5375rem;
    }

    #sign_up .link {
      color: white;
    }

    .logo {
      display: flex;
      padding-left: 0.75rem;
      padding-top: 0;
    }

    .logo img {
      width: 6.25rem;
    }

  }

  @media screen and (min-width: 20.0625rem) and (max-width: 23.375rem) {
    header {
      height: 4rem;
      padding: 0;
      background: #fff;
    }

    header nav {
      height: 3rem;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0rem 0.125rem 0.1875rem #00000029;
      opacity: 1;
    }

    header nav ul {
      margin: 0;
    }

    .link {
      color: #383d6f;
      font-size: 0.8125rem;
    }

    #sign_up {
      color: #fff;
      margin-left: 2rem;
      margin-right: 1rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.3125rem 0.75rem 0.625rem 0.75rem;
      border-radius: 0.5375rem;
    }

    #sign_up .link {
      color: white;
    }

    .logo {
      display: flex;
      padding-left: 0.75rem;
      padding-top: 0;
    }

    .logo img {
      width: 6.25rem;
    }

  }

  @media screen and (min-width: 23.4375rem) and (max-width: 47.9375rem) {
    header {
      height: 5rem;
      padding: 0;
      background: #fff;
    }

    header nav {
      height: 4rem;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0rem 0.125rem 0.1875rem #00000029;
      opacity: 1;
    }

    header nav ul {
      margin: 0;
    }

    .link {
      color: #383d6f;
      font-size: 0.8125rem;
    }

    #sign_up {
      color: #fff;
      margin-left: 2rem;
      margin-right: 1rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.3125rem 0.75rem 0.625rem 0.75rem;
      border-radius: 0.5375rem;
    }

    #sign_up .link {
      color: white;
    }

    .logo {
      padding-left: 1.0625rem;
      display: flex;
      align-items: center;
      padding-top: 0;
    }

    .logo img {
      width: 7.5rem;
    }

  }

  @media screen and (min-width: 48rem) and (max-width: 63.9375rem) {
    header {
      height: 5rem;
      padding: 0;
      background: #fff;
    }

    header nav {
      height: 5rem;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0rem 0.125rem 0.1875rem #00000029;
      opacity: 1;
    }

    header nav ul {
      margin: 0;
    }

    .link {
      color: #383d6f;
      font-size: 1.0625rem;
    }

    #sign_up {
      color: #fff;
      margin-left: 2rem;
      margin-right: 1rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.625rem 0.75rem 0.625rem 0.75rem;
      border-radius: 0.5375rem;
    }

    #sign_up .link {
      color: white;
    }

    .logo img {
      width: 12.5rem;
    }

  }

  @media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
    header {
      height: 12vh;
      padding: 0;
    }

    header nav {
      height: 5rem;
      align-items: center;
      justify-content: space-between;
    }

    header nav ul {
      margin: 0;
    }

    header nav ul li {
      color: #fff;
      font-size: 1.0625rem;
    }

    #sign_up {
      color: #fff;
      margin-left: 2rem;
      margin-right: 1rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.625rem 0.75rem 0.625rem 0.75rem;
      border-radius: 0.5375rem;
    }

    .logo img {
      width: 12.5rem;
    }
  }



  @media screen and (min-width: 80rem) and (max-width: 89.9375rem) {
    header {
      height: 12vh;
      padding: 0;
    }

    header nav {
      height: 5rem;
      align-items: center;
      justify-content: space-between;
    }

    header nav ul {
      margin: 0;
    }

    header nav ul li {
      color: #fff;
      font-size: 1.0625rem;
    }

    #sign_up {
      color: #fff;
      margin-left: 2rem;
      margin-right: 1rem;
      background: #f21d2f;
      border: 0.125rem solid #f21d2f;
      padding: 0.625rem 0.75rem 0.625rem 0.75rem;
      border-radius: 0.5375rem;
    }

    .logo {
      padding-top: 1.875rem;
    }

    .logo img {
      width: 15.625rem;
    }

  }

  @media screen and (min-width: 90rem) {
    header {
      height: 12vh;
      padding: 0;
    }
  }

</style>