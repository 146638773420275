<template>
  <div class="reset-password">
    <Loading v-if="loading"/>
    <div class="form-wrap">
      <form @submit.prevent="forgotPassword" class="reset" autocomplete="off">
        <img src="../assets/landing_page/logo_2.png" height="100" width="100"/>
        <p class="login-register">
          Back to
          <router-link class="router-link" :to="{ name: 'Login' }">Login</router-link>
        </p>
        <h2>Reset Password</h2>
        <p class="subtitle">Forgot your password? Enter your phone number and email address to reset it</p>
        <div class="inputs">
          <div class="input" :class="{'input--error': v$.phone.$error}">
            <select  ref="country_code">
                      <option :value="country.code" v-for="country in countries" :key="country.id">
                        {{ country.flag_emoji}} {{ country.dial_code}}
                      </option>
            </select>
            <input @blur="v$.phone.$touch()" type="tel" placeholder="Phone Number" v-model="state.phone" />
          </div>
          <div v-if="v$.phone.$error">
                <p class="errorMsg" :class="{'error': v$.phone.required.$invalid}"  v-if="v$.phone.required.$invalid">Please enter your phone number</p>
                </div>
          <div class="input" :class="{'input--error': v$.email.$error}">
                <input @blur="v$.email.$touch()" type="email" placeholder="Email"  v-model="state.email"/>
                <img class="icon" src="../assets/Icons/envelope-regular.svg" alt="email-icon">
          </div>
          <div v-if="v$.email.$error">
            <p class="errorMsg" :class="{'error': v$.email.email.$invalid}"  v-if="v$.email.email.$invalid">Please enter a valid email address</p>
            <p class="errorMsg" :class="{'error': v$.email.required.$invalid}"  v-if="v$.email.required.$invalid">Please enter an email address</p>
          </div>

          <div class="input">
            <select class="channel" v-model="state.channel">
              <option disabled selected value="">Channel</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
          </div>
        </div>
        <button :disabled="v$.$invalid">Reset</button>
        <div class="angle"></div>
      </form>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { ref, reactive, computed } from 'vue';
import axios from 'axios'
import Loading from '../components/common/Loading.vue'
import {useRouter} from "vue-router";
import Swal from "sweetalert2";


export default {
    name: "ForgotPassword",
    components: { Loading },

    setup(){

      const store  = useStore();
      const router = useRouter();
      store.dispatch('setSendingCountries');

      
      const loading = computed( () => {
        return store.state.loading;
      })

      const countries = computed( () => {
        return store.getters.getSendingCountries
      })

      const token = computed( () => {
        return store.getters.getToken
      })


      const country_code = ref(null);  
      const state = reactive({
        phone : "",
        email : "",
        channel: ""
      });

      // Validation rules
      const rules = {
        phone : { required },
        email: { required, email },
        channel: { required }
      }

          const v$ = useVuelidate(rules, state);

        const forgotPassword = () => {
            //Loading function
          store.dispatch('isLoading')
            // Run validate function
              v$.$validate;

            // Check for errors
              if (!v$.$error) {

                //Create data for axios POST request
                  const data = 
                  {
                      "country_code": country_code.value.value, 
                      "phone": state.phone,
                      "email": state.email,
                      "channel": state.channel
                  }

              //    Create axios POST Request

                axios.post('/request-otp', data, {
                  headers: {
                    'Authorization': `Bearer ${token.value}`
                  }
                }).then(function (response) {
                  if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203){
                    store.dispatch('isLoading')
                    Swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: "OTP Sent successfully",
                    })
                    router.push( { name: 'ChangePassword'})
                  }
                }).catch(function (error) {
                  if (error.response){
                    store.dispatch('isLoading')
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: error.response.data.errors.join(" "),
                    })
                  }
                })



              }

        }
          return {v$, loading, forgotPassword, state, country_code, countries };  

    } 
}
</script>

<style scoped>
  .reset-password {
    position: relative;
  }

  .reset-password .form-wrap .reset h2 {
    margin-bottom: 0.5rem;
  }

  .subtitle{
    text-align: center;
    margin-bottom: 1.875rem;
    max-width: 25rem;
  }

  .reset-password .form-wrap .reset .errorMsg {
    text-align: initial;
    font-size: 0.75rem;
    color: #F21D2F;
    margin-bottom: 0.5rem;
  }

  .form-wrap form .inputs .input select {
    height: 3.125rem;
    background-color: #f2f7f6;
    border: none;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  }
  .form-wrap form .inputs .input .channel{
    width: 100%;
  }

  .form-wrap form .inputs .input select:focus{
    outline: none;
  }

</style>