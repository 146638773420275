<template>
  <nav class="nav">
          <div class="sidebar-button" @click="toggleSidebar">
            <i class="fas fa-bars"></i>
          </div>
          <div class="details-section">
              <div class="notification-section" >
                  <div class="notification-icon" @click.stop="toggleNotifications">
                      <i class="far fa-bell"></i>
                      <div v-if="notifications">
                          <div class="badge" v-if="notifications.length">
                              <p>{{notifications.length}}</p>
                          </div>
                      </div>

                  </div>
                  <div ref="notificationDropdown" class="notification-dropdown" v-if="notificationActive">
                      <div class="notification-title">
                        <h4>Notifications</h4>
                      </div>
                      <div v-if="notifications.length">
                        <div class="notification-content-section" v-for="notification in notifications" :key="notification.id" @click="reRoute(notification.type, notification.transaction_id, notification.id)">
                          <div class="notification-type">
                              <i v-if="notification.type === 'recipient' "  class="fas fa-user"></i>
                              <i v-if="notification.type === 'transaction' " class="far fa-paper-plane"></i>
                          </div>
                          <div class="notification-content">
                              <div class="div notification-message">
                                  <p>{{notification.message}}</p>
                              </div>
                              <div class="notification-time">
                                  <p><i class="far fa-clock"></i> {{timeago(notification.created_at)}}</p>
                              </div>
                          </div>
                      </div>
                      </div>
                      <div v-else class="notification-content-section">
                          <p style="color: #383d6f; letter-spacing: 2px;">No notifications to show</p>
                      </div>
                  </div>
              </div>
              <div class="profile-details-section">
                  <div class="profile-details" @click.stop="toggleDropdown">
                      <div v-if="userData.customer">
                          <img v-if="userData.customer.gender === 'M'" src="../../assets/dashboard/Male.webp" height="600" width="600" alt="male"/>
                          <img v-if="userData.customer.gender === 'F'" src="../../assets/dashboard/Female.webp" height="600" width="600" alt="female"/>
                      </div>
                      <div v-else>
                          <img v-if="userData.gender === 'M'" src="../../assets/dashboard/Male.webp" height="600" width="600" alt="male"/>
                          <img v-if="userData.gender === 'F'" src="../../assets/dashboard/Female.webp" height="600" width="600" alt="female"/>
                      </div>
                      <span class="admin_name" v-if="userData.customer">{{userData.customer.firstname}} {{userData.customer.lastname}}</span>
                      <span class="admin_name" v-else>{{userData.firstname}} {{userData.lastname}}</span>
                      <i class="fas fa-chevron-down"></i>
                  </div>
                  <div ref="profileDropdown" class="dropdown-section" v-if="active">
                      <div class="dropdown">
                          <ul>
                              <li>
                                  <router-link class="link" :to="{name: 'Settings'}">
                                      <i class="fas fa-users-cog"></i>&nbsp; Account&nbsp;Settings
                                  </router-link>

                              </li>
                              <li @click="logout">
                                  <i class="fas fa-sign-out-alt"></i>&nbsp; Logout
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>


          </div>

  </nav>
</template>

<script>
import { useStore } from 'vuex'
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import axios from 'axios'
import Swal from "sweetalert2";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useClickOutside } from '@/composables/useClickOutside.js'
export default {
    name: 'Navbar',

    setup(){
        const store = useStore();
        const router = useRouter();
        const toggleSidebar = () => {
            store.commit('toggleSidebar');
        }

        const profileDropdown = ref(null)

        useClickOutside( profileDropdown, () => {
                active.value = false
        })

        const active = ref(false)

        const notificationActive = ref(false);

        const notifications = ref(null);

        const notificationDropdown = ref(null);

        useClickOutside( notificationDropdown, () => {
            notificationActive.value = false
        })

        const toggleNotifications = () => {
                notificationActive.value = !notificationActive.value
        }

        const timeago = (date) => {
            dayjs.extend(relativeTime)
            return dayjs(date).fromNow()
        }

        const token = computed( () => {
            return store.getters.getToken;
        })

        const userData = computed( () => {
            return store.getters.getUserData
        })

        const getNotifications = async() => {
            await axios.get(`/notifications`, {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                },
                params: {
                    per_page: 3
                }
            }).then(function (response) {
                notifications.value = response.data.data.data.filter(notification => notification.read_at == null)
            }).catch(function (error) {
                if (error.response){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })
        }

        getNotifications();


        const reRoute = async(type, transaction_id, id) => {
            if (type === "transaction"){
                await axios.post(`/notification/read/${id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    },
                }).then(function (response) {
                    if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203){
                        router.push({name: 'HistoryDetails', params: { transaction_id : transaction_id}})
                    }
                }).catch(function (error) {
                    if (error.response){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })

            }else if (type === "recipient"){

                await axios.post(`/notification/read/${id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    },
                }).then(function (response) {
                    if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203){
                        router.push({name: 'Recepients'})
                    }
                }).catch(function (error) {
                    if (error.response){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })
            }
        }

        const toggleDropdown = () => {
            active.value = !active.value
        }

        const logout = async () => {
            await store.dispatch('isLoading')
            await axios.get('/logout', {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            }).then(function (response) {
                if (response.status === 200){
                    store.dispatch('isLoading')
                    localStorage.removeItem('vuex');
                    router.push({name: 'Home'})
                }
            }).catch(function (error) {
                if (error.response){
                    store.dispatch('isLoading')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })
        }

        return { logout,  active, toggleSidebar, toggleDropdown, userData, toggleNotifications, notificationActive, notifications, timeago, reRoute, profileDropdown }
    }


}


</script>

<style scoped>
    .nav {
        display: flex;
        justify-content: space-between;
        height: 5rem;
        background: #fff;
        align-items: center;
        position: fixed;
        width: calc(100% - 15rem);
        left: 15rem;
        z-index: 100;
        padding: 0 1.25rem;
        box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease;
    }

    .nav .sidebar-button{
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 500;
        cursor: pointer;
    }
    .nav .sidebar-button i{
        font-size: 1.5625rem;
        margin-right: 0.625rem;
    }

    .nav .details-section{
        display: flex;
    }

    .nav .details-section .notification-section{
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .nav .details-section .notification-section .notification-icon{
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 0.625rem;
        padding: 0.625rem;
        cursor: pointer;
    }

    .nav .details-section .notification-section  .notification-icon i{
        font-size: 1.575rem;
    }

    .nav .details-section .notification-section .notification-icon .badge{
        background: #383d6f;
        height: 1rem;
        width: 1rem;
        padding: 0.3125rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0.2875rem;
        left: 1.1125rem;
    }

    .nav .details-section .notification-section .notification-icon .badge p{
        color: white;
        font-size: 10px;
    }

    .nav .details-section .notification-section .notification-dropdown{
        position: absolute;
        background: white;
        width: 600%;
        top: 66px;
        right: 6px;
        border-radius: 7px;
        box-shadow: -15px -15px 15px rgba(255,255,255,0.5),
        15px 15px 15px rgba(0, 0, 0, 0.05);
    }

    .nav .details-section .notification-section .notification-dropdown .notification-title{
        border-bottom: 1px solid #707070;
        padding: 10px;
        color: #383d6f;
        letter-spacing: 2px;
    }

    .nav .details-section .notification-section .notification-dropdown .notification-content-section{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        border-bottom: 1px dashed #aaaaaa;
    }

    .nav .details-section .notification-section .notification-dropdown .notification-content-section:last-of-type{
        border-bottom: none;
    }

    .nav .details-section .notification-section .notification-dropdown .notification-content-section .notification-type{
        border-radius: 50%;
        background: #535ba7;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav .details-section .notification-section .notification-dropdown .notification-content-section .notification-type i{
        color: white;
    }

    .nav .details-section .notification-section .notification-dropdown .notification-content-section .notification-content{
        font-size: 13px;
        width: 80%;
    }


    .nav .details-section .notification-section .notification-dropdown .notification-content-section .notification-content .notification-time{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
    }

    .nav .profile-details-section{
        display: flex;
        flex-direction: column;
        position: relative;

    }
    .nav .profile-details-section .dropdown-section{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .nav .profile-details-section .dropdown-section .dropdown{
        position: absolute;
        display: flex;
        top: calc(100% + 16px);
        width: 120%;
        background: white;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        padding: 1.25rem;

    }

    .nav .profile-details-section .dropdown-section .dropdown ul{
        width: 100%;
    }

    .nav .profile-details-section .dropdown-section .dropdown ul li{
        list-style: none;
        padding: 0.625rem 0.375rem;
        cursor: pointer;
    }

    .nav .profile-details-section .dropdown-section .dropdown ul li:hover{
        background: #bbbbbb;
        color: white;
    }

    .nav .profile-details-section .dropdown-section .dropdown ul li:hover .link{
        color: white;
    }

    .link{
        text-decoration: none;
        color: #303030;
    }



    .nav .profile-details{
        display: flex;
        align-items: center;
        background: #F5F6FA;
        border: 0.125rem solid #EFEEF1;
        border-radius: 0.375rem;
        height: 3.125rem;
        min-width: 11.875rem;
        cursor: pointer;
        padding: 0 0.9375rem;
    }
    .nav .profile-details img{
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 0.375rem;
        object-fit: cover;
    }
    .nav .profile-details .admin_name{
        font-size: 0.9375rem;
        font-weight: 500;
        color: #333;
        margin: 0 0.625rem;
        white-space: nowrap;
    }
    .nav .profile-details i{
        cursor: pointer;
        font-size: 0.9375rem;
        color: #333;
    }
    .nav .profile-details ul{
        display: flex;
        flex-direction: column;
    }
    @media (max-width: 550px) {

        .nav .profile-details-section .dropdown-section .dropdown {
            width: 20rem;
        }

        .nav .details-section .notification-section .notification-dropdown{
            width: 500%;
            right: 0;
            left: -113px;
            border-radius: initial;
        }



    }

</style>