<template>
        <div class="card">
            <div class="new-recipient" >
                <h1>New Recipient</h1>
                <RecepientCountry :receivingCountries="receivingCountries"/>
            </div>
        </div>
</template>

<script>

import RecepientCountry from '@/components/Dashboard/RecepientCountry.vue'
import {useStore} from "vuex";
import {computed} from "vue";
export default {
    name: 'Recepients',
    components: { RecepientCountry},

    setup(){
        const store = useStore();
        store.dispatch('setReceivingCountries')
        store.dispatch('setTransactionType', 'individual');
        store.dispatch('setCheckRecipient', true );

        const loading = computed( () => {
            return store.state.loading;
        })

        const receivingCountries = computed( () => {
            return store.getters.getReceivingCountries
        })

        return { receivingCountries, loading }
    }
}
</script>

<style scoped>
    .card{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .new-recipient {
        background: white;
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2.5rem;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        border-radius: 0.9375rem;
    }

    .new-recipient h1{
        color: #423E3B;
        margin-bottom: 1.875rem;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
        .new-recipient{
            width: 70%;
        }
    }

    @media (max-width: 34.375rem) {
        .new-recipient{
            width: 100%;
            box-shadow: none;
            border-radius: 0;
        }
    }

</style>