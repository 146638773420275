<template>
    <div class="activities" v-if="transactions">
        <div class="activity-section" v-if="transactions.length">
            <div class="activity" @click="reRoute(transaction.id)" v-for="transaction in transactions" :key="transaction.id">
                <div class="activity-icon" v-if="transaction.payment_method_id === 2">
                    <img
                            :src=transaction.payment_channel.logo
                            alt="activity icon"
                    />
                </div>
                <div class="activity-icon" v-else-if="transaction.payment_method_id === 3">
                    <img
                            :src=transaction.payment_channel.logo
                            alt="activity icon"
                    />
                </div>
                <div class="activity-icon" v-else-if="transaction.business_recipient">
                    <img
                            :src=transaction.business_recipient.logo
                            alt="activity icon"
                    />
                </div>
                <div class="activity-description" v-if="transaction.individual_recipient">
                    <p class="description">Transferred to {{transaction.individual_recipient.firstname}} {{transaction.individual_recipient.lastname}}</p>
                    <p class="date">{{convertDate(transaction.created_at)}}</p>
                </div>
                <div class="activity-description" v-if="transaction.business_recipient">
                    <p class="description">Transferred to {{transaction.business_recipient.name}}</p>
                    <p class="date">{{convertDate(transaction.created_at)}}</p>
                </div>
                <div class="activity-status">
                    <p class="activity-cost">{{transaction.sending_currency}} {{transaction.sending_amount}}</p>
                    <p class="failed-status" v-if="transaction.status === 'failed'">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</p>
                    <p class="success-status" v-if="transaction.status === 'processed'">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</p>
                    <p class="failed-status" v-if="transaction.status === 'canceled'">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</p>
                    <p class="pending-status" v-if="transaction.status === 'processing'">{{transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}}</p>
                </div>
            </div>
        </div>
        <div v-else>
            <p class="no-transaction">No transactions at the moment.</p>
        </div>
    </div>
    <div v-else class="loading">
        <div class="lds-dual-ring"></div>
    </div>

</template>
<script>
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import { ref, computed } from 'vue'
    import { useStore } from  'vuex'
    import dayjs from "dayjs";
    import {useRouter} from "vue-router";
    export default {
        name: 'TransactionActivities',

        setup(){
            const router = useRouter();
            const store = useStore();

            const transactions = ref(null);

            const token = computed( () => {
                return store.getters.getToken;
            })

            const convertDate = (value) => {
                const localizedFormat = require('dayjs/plugin/localizedFormat')
                dayjs.extend(localizedFormat)
                return dayjs(value).format('llll');
            }

            axios.get('/transactions?limit=4', {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            }).then(function (response) {
                transactions.value = response.data.data.data;
            }).catch(error => {
                if (error.response){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })

            const reRoute = (id) => {
                router.push({name: 'HistoryDetails', params: { transaction_id : id}})
            }

            return { convertDate, transactions, reRoute }
        }
    }
</script>
<style>

    .activity {
        margin-top: 1.25rem;
        display: flex;
        justify-content: space-between;
        color: black;
        align-items: center;
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 1.25rem;
        box-shadow: 0 -15px 15px rgba(255,255,255,0.5),
        15px 15px 15px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        background: white;
    }

    .activities .activity-section .activity .activity-icon{
        height: 60px;
        width: 60px;
    }

    .activities .activity-section .activity .activity-icon img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .activity-description .description {
        margin-bottom: 0.3125rem;
    }

    .activity-status .activity-cost {
        margin-bottom: 0.3125rem;
    }

    .failed-status {
        color: #ff0017;
        font-weight: bold;
    }

    .success-status {
        color: #3cb371;
        font-weight: bold;
    }

    .pending-status {
        color: #FFBF00;
        font-weight: bold;
    }

    .loading{
        display: flex;
        width: 100%;
        height: 8rem;
        justify-content: center;
        align-items: center;
    }

    .no-transaction{
        text-align: center;
        padding: 1.25rem;
        font-size: 1.25rem;
        font-weight: 900;
        color: #383d6f;
    }



    @media (max-width: 34.375rem) {

        .activity {
            padding: 10px;
        }

        .activity-description {
            margin-left: 0.625rem;
        }

    }

    @media screen and (min-width: 660px) and (max-width: 900px){
        .activity{
            width: 80%;
        }
        .activity-section{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }


</style>
