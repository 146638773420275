import store from '../store/index.js'
import { computed } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard_Home from '../views/Dashboard/DashboardHome.vue'
import Transfer from '../views/Dashboard/Transfer/Transfer.vue'
import Transaction_History from '../views/Dashboard/History/History.vue'
import Settings from '../views/Dashboard/Settings/Settings.vue'
import TransferHome from "../views/Dashboard/Transfer/TransferHome";
import Profile from "../views/Dashboard/Settings/Profile";
import Documents from "../views/Dashboard/Settings/Documents/Documents";
import TransactionLimit from "../views/Dashboard/Settings/TransactionLimit";
import NotFoundComponent from "../views/NotFoundComponent";
import History_Home from "../views/Dashboard/History/History_Home";
import PasswordChange from "../views/Dashboard/Settings/PasswordChange";
import Support from "../views/Dashboard/Settings/Support";
import Recepients from "../views/Dashboard/Recipients/Recepients";
import Recipient_Home from "../views/Dashboard/Recipients/Recipient_Home";
import History_Details from "../views/Dashboard/History/History_Details";
import Document_Details from "../views/Dashboard/Settings/Documents/Document_Details";
import Document_Home from "../views/Dashboard/Settings/Documents/Document_Home";
import CorporateRecipients from "../views/Dashboard/Transfer/Corporate/CorporateRecipients";
import CorporateRecipientCategory from "../views/Dashboard/Transfer/Corporate/CorporateRecipientCategory";
import CorporateRecipient from "../views/Dashboard/Transfer/Corporate/CorporateRecipient";
import CorporateProductDetails from "../views/Dashboard/Transfer/Corporate/CorporateProductDetails";
import AddNewRecipient from "../views/Dashboard/Transfer/Individual/AddNewRecipient";
import PaymentMethod from "../views/Dashboard/Transfer/Individual/PaymentMethod";
import PaymentChannels from "../views/Dashboard/Transfer/Individual/PaymentChannels";
import AddRecipientDetails from "../views/Dashboard/Transfer/Individual/AddRecipientDetails";
import NewTransaction from "../views/Dashboard/Transfer/NewTransaction";
import Transaction from "../views/Dashboard/Transfer/Transaction";
import ForgotPassword from "../views/ForgotPassword";
import ChangePassword from "../views/ChangePassword";
import Verification from "../views/Verification";
import IDVerification from "../views/IDVerification";
import Login from "../views/Login";
import Register from "../views/Register";
import CreateProfile from "../views/CreateProfile";
import Recipient_Details from "../views/Dashboard/Recipients/Recipient_Details";
import CardPayment from "../views/Dashboard/Transfer/CardPayments/CardPayment";
import AddNewCard from "../views/Dashboard/Transfer/CardPayments/AddNewCard";

// Navigation Guard
const token = computed( () => {
  return store.getters.getToken
})


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title: "Home"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title: "Login"
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      title: "Register"
    }
  },

  {
    path: '/create-profile',
    name: 'CreateProfile',
    component: CreateProfile,
    meta:{
      title: "Create Profile",
      requiresAuth: true
    }
  },
  {
    path: '/id-verification',
    name: 'IDVerification',
    component: IDVerification,
    meta:{
      title: "ID Verification",
      requiresAuth: true
    }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard_Home,
    meta:{
      title: "Dashboard",
      requiresAuth: true
    }
  },

  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
    redirect: {
      name: 'transfer-home'
    },
    children: [
      {
        path: '',
        name: 'transfer-home',
        component: TransferHome,
        meta:{
          title: "New Transaction",
          requiresAuth: true
        }
      },
      {
        path: 'corporate-recipients/:id',
        name: 'CorporateRecipients',
        component: CorporateRecipients,
        props: true,
        redirect: {
          name: 'CorporateRecipientCategory'
        },
        children: [
          {
            path: '',
            name: 'CorporateRecipientCategory',
            component: CorporateRecipientCategory,
            meta: {
              requiresAuth: true,
              title: 'Corporate Recipient Category'
            }
          },
          {
            path: 'corporate-recipient',
            name: 'CorporateRecipient',
            component: CorporateRecipient,
            meta: {
              title: 'Corporate Recipient',
              requiresAuth: true
            }
          },
          {
            path: 'product-details/:product_id',
            name: 'CorporateProductDetails',
            component: CorporateProductDetails,
            props: true,
            meta: {
              title: 'Product Details',
              requiresAuth: true
            }
          }
        ],
        meta:{
          title: "Corporate Recipients",
          requiresAuth: true
        }
      },
      {
        path: 'add-new-recipients',
        name: 'AddNewRecipients',
        component: AddNewRecipient,
        meta:{
          title: "Add New Recipient",
          requiresAuth: true
        }
      },
      {
        path: 'payment-method/:code',
        name: 'PaymentMethod',
        component: PaymentMethod,
        props: true,
        meta:{
          title: "Select Payment Method",
          requiresAuth: true
        }
      },
      {
        path: 'payment-channels/:id',
        name: 'PaymentChannels',
        component: PaymentChannels,
        props: true,
        meta:{
          title: "Select Payment Channel",
          requiresAuth: true
        }
      },

      {
        path: 'add-recipients-details/:id',
        name: 'AddRecipientDetails',
        component: AddRecipientDetails,
        props: true,
        meta:{
          title: "Add Recipient Details",
          requiresAuth: true
        }
      },
      {
        path: 'make-payment',
        name: 'NewTransaction',
        component: NewTransaction,
        meta:{
          title: "Make Payment",
          requiresAuth: true
        }
      },
      {
        path: 'transaction/:id',
        name: 'Transaction',
        component: Transaction,
        props: true,
        meta:{
          title: "Transaction",
          requiresAuth: true
        },
      },

      {
        path: 'card-payment',
        name: 'CardPayment',
        component: CardPayment,
        props: true,
        meta:{
          title: "Card Payment",
          requiresAuth: true
        },
      },

      {
        path: 'add-new-card',
        name: 'AddNewCard',
        component: AddNewCard,
        props: true,
        meta:{
          title: "Add New Card",
          requiresAuth: true
        },
      }
    ],

  },


  {
    path: '/history',
    name: 'History_Home',
    component: History_Home,
    meta:{
      title: "History"
    },
    redirect: {
      name: 'history'
    },
    children: [
      {
        path: '',
        name: 'History',
        component: Transaction_History,
      },
      {
        path: 'transaction-details/:transaction_id',
        component: History_Details,
        name: 'HistoryDetails',
        meta:{
          title: "Transaction Details"
        }
      }
    ]
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    redirect: {
      name: 'Profile'
    },
    meta:{
      title: "Settings"
    },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
          title: 'Profile'
        }
      },
      {
        path: 'documents',
        name: 'Documents',
        component: Documents,
        redirect: {
          name: 'Document_Home'
        },
        meta: {
          title: 'Documents'
        },
        children: [
          {
            path: '',
            name: 'Document_Home',
            component: Document_Home,
          },
          {
            path: 'documents/:document_id',
            name: 'DocumentDetails',
            component: Document_Details,
          }
        ]
      },
      {
        path: 'transaction-limits',
        name: 'TransactionLimits',
        component: TransactionLimit,
        meta: {
          title: 'Transaction Limits'
        }
      },
      {
        path: 'password-change',
        name: 'PasswordChange',
        component: PasswordChange,
        meta: {
          title: 'Change Password'
        }
      },
      {
        path: 'support',
        name: 'Support',
        component: Support,
        meta: {
          title: 'Support'
        }
      }
    ],

  },

  {
    path: '/recipients',
    name: 'Recepients',
    component: Recepients,
    redirect: {
      name: 'Recipients_Home'
    },
    meta:{
      title: "Recipients"
    },
    children: [
      {
        path: '',
        name: 'Recipients_Home',
        component: Recipient_Home,
      },
      {
        path: 'recipient-details/:recipient_id',
        component: Recipient_Details,
        name: 'RecipientDetails',
        meta:{
          title: "Recipient Details"
        }
      }
    ]
  },


  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta:{
      title: "Forgot Password"
    }
  },
  {
    path: '/password-change',
    name: 'ChangePassword',
    component: ChangePassword,
    meta:{
      title: "Change Password"
    }
  },

  {
    path: '/verify',
    name: 'Verification',
    component: Verification,
    meta:{
      title: "Verification"
    }
  },

  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: 'NotFound',
    meta:{
      title: "404 Error"
    }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = `${toRoute.meta.title} | Fast Pace Transfer`;
  if (toRoute.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!token.value) {
      next({name: 'Login'})
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }



})

export default router
