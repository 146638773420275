<template>
  <div class="recipients">
    <Loading v-if="loading"/>
  <Sidebar/>

  <section class="content-section">
    <Navbar/>
    <div class="content">
      <router-view></router-view>
    </div>
  </section>
</div>
</template>

<script>
import Sidebar from '@/components/Dashboard/Sidebar.vue'
import Navbar from '@/components/Dashboard/Navbar.vue'
import {  computed } from "vue"
import {useStore} from "vuex";
import Loading from "../../../components/common/Loading";

export default {
    name: 'Recipients',
    components: {Loading, Sidebar, Navbar},

    setup(){
      const store = useStore();

      const loading = computed( () => {
        return store.state.loading
      })




      return { loading }
    }
}
</script>

