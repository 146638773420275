<template>
    <div class="dashboard">
        <Loading v-if="loading"/>
        <Sidebar/>

        <section class="content-section">
            <Navbar/>
            <div class="content">
                <router-view></router-view>
            </div>
        </section>
    </div>
</template>

<script>
    import Sidebar from '@/components/Dashboard/Sidebar.vue'
    import Navbar from '@/components/Dashboard/Navbar.vue'
    import Loading from "../../../components/common/Loading";
    import { computed } from 'vue'
    import {useStore} from "vuex";


    export default {
        name: 'History_Home',
        components: {Loading, Sidebar, Navbar},
        setup(){
            const store = useStore();

            const loading = computed( () => {
                return store.state.loading
            })

            return{ loading }
        }



    }
</script>