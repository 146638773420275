<template>
<div>
  <Loading v-if="loading"/>

 <div class="form-wrap">

        <form @submit.prevent="login" class="login" autocomplete="off">
            <img src="../assets/landing_page/logo_2.png" height="100" width="100" alt="company-logo"/>
            <p class="login-register">
                Don't have an account?
                <router-link class="router-link" :to="{ name: 'Register' }">Register</router-link>
            </p>
            <h2>Login to FP Transfer</h2>
            
            <div class="inputs" >
                <div class="input" :class="{'input--error': v$.email.$error}">
                    <input @blur="v$.email.$touch()" type="email" placeholder="Email" v-model="state.email"/>
                    <img class="icon" src="../assets/Icons/envelope-regular.svg" alt="">
                </div>
                <div v-if="v$.email.$error">
                  <p :class="{'error': v$.email.email.$invalid}"  v-if="v$.email.email.$invalid">Please enter a valid email address</p>
                  <p :class="{'error': v$.email.required.$invalid}"  v-if="v$.email.required.$invalid">Please enter an email address</p>
                </div>
                <div class="input" :class="{ 'input--error': v$.password.$error}">
                    <input @blur="v$.password.$touch()" type="password" placeholder="Password" v-model="state.password"/>
                    <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                </div>
                <div v-if="v$.password.$error">
                <p :class="{'error': v$.password.required.$invalid}"  v-if="v$.password.required.$invalid">Please enter your password</p>
                </div>
            </div>
            <router-link class="forgot-password" :to="{ name: 'ForgotPassword' }"
            >Forgot your password?</router-link
            >
            <button :disabled="v$.$invalid">Sign In</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useStore } from "vuex"
import Loading from '../components/common/Loading.vue'
import Swal from "sweetalert2"
import axios from "axios";
import { useRouter } from 'vue-router'
import { computed, reactive} from 'vue'
export default {
    name: "Login",
    components: { Loading },

    setup(){
    // Router and Store Initialization
    const router = useRouter()
    const store  = useStore();

    // Loading value
    const loading = computed( () => {
      return store.state.loading;
    })

      //
      const state = reactive({
        email: '',
        password: '',  
      })
      
      // Validation rules
      const rules = computed( () => ({
        email: { required, email },
        password: { required }
      }
      )) 
      
      const v$ = useVuelidate(rules, state);

     const login = async () => {

      //  Data to be sent to API
       const data = {
            "email": state.email,
            "password": state.password
        }
      // Run validate function
        await v$.$validate;

      // If there are no errors
        if (!v$.$error) {
          await store.dispatch('isLoading');
          await axios.post('/login', data)
                     .then((response) => {

                             const token = response.data.data.token;
                             const result = response.data.data.user;
                             store.dispatch('setUserData', result);
                             store.dispatch('setToken', token);
                             store.dispatch('isLoading');
                             router.push({ name: 'Dashboard'})

                     })
                     .catch(error => {
                      if (error.response) {
                          if (error.response.status === 403){

                              switch (error.response.data.message) {
                                    case "verified": {
                                        const token = error.response.data.token
                                        store.dispatch('setToken', token);
                                        store.dispatch('isLoading');
                                        router.push({name: 'Verification'})
                                        break;
                                    }

                                    case "document": {
                                        const token = error.response.data.token
                                        store.dispatch('setToken', token);
                                        store.dispatch('isLoading');
                                        router.push({name: 'IDVerification'});
                                        break;
                                    }

                                  case "customer": {
                                      const token = error.response.data.token
                                      store.dispatch('setToken', token);
                                      store.dispatch('isLoading');
                                      router.push({name: 'CreateProfile'});
                                      break;
                                  }


                              }

                          }else {
                              store.dispatch('isLoading');
                              Swal.fire({
                                      icon: 'error',
                                      title: 'Error',
                                      text: error.response.data.errors.join(" "),
                                  }
                              )

                          }

                      }
                     });
        }
      
      // Axios POST request
     
     }



      return { loading, v$, state, login};
    }
}
</script>

<style>
    button{
        transition: 500ms ease all;
        cursor: pointer;
        margin-top: 1.5rem;
        padding: 0.75rem 1.5rem;
        background-color: rgb(56, 61, 111);
        color: #fff;
        border-radius: 1.25rem;
        border: none;
        text-transform: uppercase;
    }

    button:focus{
        outline: none;
    }

    button:disabled{
        background-color: rgba(56, 61, 111, 0.7);
    }

    button:hover{
        background-color: rgba(56, 61, 111, 0.7);
    }

    .form-wrap {
        overflow: hidden;
        display: flex;
        height: 100vh;
        justify-content: center;
        align-self: center;
        margin: 0 auto;
        width: 90%;
    }
    @media (min-width: 56.25rem) {
        .form-wrap {
            width: 100%;
        }
    }
    .form-wrap .login-register {
        margin-bottom: 2rem;
    }
    .form-wrap .login-register .router-link {
        color: #b50000;
        text-decoration: none;
    }
    .form-wrap form {
        padding: 0 0.625rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
    @media (min-width: 56.25rem) {
        .form-wrap form {
            padding: 0 3.125rem;
        }
    }
    .form-wrap form h2 {
        text-align: center;
        font-size: 2rem;
        color: #303030;
        margin-bottom: 2.5rem;
    }
    @media (min-width: 56.25rem) {
        .form-wrap form h2 {
            font-size: 2.5rem;
        }
    }
    .form-wrap form .inputs {
        width: 100%;
        max-width: 21.875rem;
    }
    .form-wrap form .inputs .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
    }
    .form-wrap form .inputs .input input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 0.25rem 0.25rem 0.25rem 1.875rem;
        height: 3.125rem;
    }
    .form-wrap form .inputs .input input:focus {
        outline: none;
    }
    .form-wrap form .inputs .input .icon {
        width: 0.75rem;
        position: absolute;
        left: 0.375rem;
    }
    .form-wrap form .forgot-password {
        text-decoration: none;
        color: #383D6F;
        cursor: pointer;
        font-size: 0.875rem;
        margin: 1rem 0 2rem;
        border-bottom: 0.0625rem solid transparent;
        transition: 0.5s ease all;
    }
    .form-wrap form .forgot-password:hover {
        border-color: rgba(56, 61, 111, 0.7);
    }
    .form-wrap form .angle {
        display: none;
        position: absolute;
        background-color: #fff;
        transform: rotateZ(3deg);
        width: 3.75rem;
        right: -1.875rem;
        height: 101%;
    }
    @media (min-width: 56.25rem) {
        .form-wrap form .angle {
            display: initial;
        }
    }
    .form-wrap .background {
        display: none;
        flex: 2;
        background-size: cover;
        background-image: url("../assets/landing_page/banner.webp");
        /*animation: slideBG 8s linear infinite 0s;*/
        /*animation-timing-function: ease-in-out;*/
        width: 100%;
        height: 100%;
    }

    /*@keyframes slideBG {*/
    /*    0%{*/
    /*        background-image: url("../assets/landing_page/new_banner.jpg");*/
    /*    }*/

    /*    100%{*/
    /*        background-image: url("../assets/landing_page/london.jpg");*/
    /*    }*/
    /*}*/


    @media (min-width: 56.25rem) {
        .form-wrap .background {
            display: initial;
        }
    }
 
</style>