<template>
    <div class="recipient-category">
        <div class="title" v-if="specificCorporateData">
            <img :src="specificCorporateData.icon" alt="">
            <h1>{{specificCorporateData.title}}</h1>
        </div>
        <div class="recipient-card-section" v-if="specificCorporateData">
            <div class="recipient-card" v-for="corporate in specificCorporateData.recipients" :key="corporate.id" @click="getCorporateRecipient(corporate.id)">
                <img :src="corporate.logo" alt="" width="120" height="120">
                <p>{{corporate.name}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {useRoute, useRouter} from "vue-router";
    import { ref, computed} from "vue";
    import {useStore} from "vuex";
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "CorporateRecipientCategory",

        props: ['id'],
        setup(){
            const route = useRoute();
            const router = useRouter();
            const id = route.params.id
            const specificCorporateData = ref(null);

            const token = computed( () => {
                return store.getters.getToken
            })
            const store = useStore();
            store.dispatch('setTransactionType', 'business')
            store.dispatch('isLoading')
            axios.get(`/category/${id}`).then(function (response) {
                store.dispatch('isLoading')
                specificCorporateData.value = response.data.data;
            }).catch(error => {
                if (error.response){
                    store.dispatch('isLoading')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })
                }
            })

            const getCorporateRecipient = (id) => {
                store.dispatch('isLoading')

                axios.get(`/corporate-recipient/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    },
                }).then(function (response) {
                    store.dispatch('setCorporateRecipient', response.data.data)
                    store.dispatch('isLoading')
                    router.push({name: 'CorporateRecipient'})
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })

            }

            return { getCorporateRecipient,  specificCorporateData }
        }
    }
</script>

<style scoped>
    .recipient-category{
        width: 80%;
        background: white;
        box-shadow: 0 0.125rem 0.375rem #00000029;
    }

    .recipient-category .title{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recipient-category .title img{
        height: 5.625rem;
        object-fit: cover;
    }

    .recipient-category .title h1{
        color: #423e3b;
    }

    .recipient-category .recipient-card-section{
        display: flex;
        align-items: center;
        padding: 1.25rem;
    }

    .recipient-category .recipient-card-section .recipient-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.625rem;
        padding: 0.625rem;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        color: #383D6F;
        cursor: pointer;
    }

    .recipient-category-section .recipient-category .recipient-card-section .recipient-card p{
        margin-top: 0.625rem;
    }

    @media screen and (max-width: 31.25rem){
        .recipient-category{
            width: 90%;
        }
        .recipient-category .recipient-card-section{
            flex-direction: column;
            padding: 0.625rem;
        }
        .recipient-category .recipient-card-section .recipient-card{
            width: 80%;
            margin: 0.625rem 0;
        }
    }
</style>