<template>
<div class="dashboard">
  <Loading v-if="loading"/>
  <Sidebar/>

  <section class="content-section">
    <Navbar/>
    <div class="content">
        <router-view></router-view>
    </div>
  </section>
</div>
</template>

<script>
  import Sidebar from '@/components/Dashboard/Sidebar.vue'
  import Navbar from '@/components/Dashboard/Navbar.vue'
  import Loading from "../../../components/common/Loading";
  import { computed } from 'vue'
  import {useStore} from "vuex";


  export default {
    name: 'Transfer_Home',
    components: {Loading, Sidebar, Navbar},
    setup(){
      const store = useStore();

      const loading = computed( () => {
        return store.state.loading
      })

      return{ loading }
    }


    
}
</script>

<style scoped>

  .left-content-section.transfer h1{
  color: #423E3B;
}

  .transfer.transfer-money-box .box span {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  font-size: 30px;
}


</style>