<template>
  <div class="form-wrap">
        <form @submit.prevent="register" class="register" autocomplete="off">
            <img src="../assets/landing_page/logo_2.png" height="100" width="100"/>
            <p class="login-register">
            Back to
            <router-link class="router-link" :to="{ name: 'Home' }">Home</router-link>
            </p>  
            <h2>Verify Your Account</h2>

            <p class="subtitle">Enter the six digit code sent to your phone to verify your Fast Pace Transfer account.</p>

            <div class="inputs"> 
                <div class="input" :class="{'input--error': v$.password.$error}">
                    <input @blur="v$.password.$touch()" type="password" placeholder="6-digit code" v-model="state.password" />
                    <img class="icon" src="../assets/Icons/lock-alt-solid.svg" alt="">
                </div>
                <div v-if="v$.password.$error">
                <p :class="{'error': v$.password.required.$invalid}"  v-if="v$.password.required.$invalid">Please enter your code</p>
                <p :class="{'error': v$.password.minLength.$invalid}"  v-if="v$.password.minLength.$invalid">Minimum length is 6 characters</p>
                <p :class="{'error': v$.password.maxLength.$invalid}"  v-if="v$.password.maxLength.$invalid">Maximum length is 6 characters</p>
                </div>
            </div>
            <div class="extra">
              <p class="timer"  v-if="!timer_end">Expires in {{string_timer}}</p>
              <p v-if="timer_end">Didn't get the code? <span class="resend" @click="resendcode">Resend code</span></p>
            </div>
            <button :disabled="v$.$invalid || timer_end" @click="verify">Continue</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import Swal from "sweetalert2"
import axios from "axios"

// import axios from "axios"
export default {
    name: "Register",


    setup(){

    // Timer
    let timer = 60;
    let timer_end = ref(false);
    let string_timer = ref('')

    const countdown = setInterval( () => {
      timer--;
      displayTime(timer)

      if (timer <= 0 || timer < 1) {
        clearInterval(countdown)
        timer_end.value = true;
      }
    }, 1000)
    function displayTime(second) {
      const min = Math.floor(second / 60);
      const sec = Math.floor(second % 60);
      string_timer.value = `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
    }

    // Store
    const store = useStore();
    const token = computed( () => {
        return store.getters.getToken
    })

    // Router
    const router = useRouter(); 


    // Grabbing inputs
      
     const state = reactive({
       password : "",
     });

     // Validation rules
      const rules = computed( () => ({
          password: {
              required,
              minLength: minLength(6),
              maxLength: maxLength(6)
          }
      }));

      const v$ = useVuelidate(rules, state);
      
      
      


      // Actual verification function
      const verify = () => {

        // Run validate function

        v$.$validate;

        // If there are no errors
          if (!v$.$error) {
            store.dispatch('isLoading');


            // Make a request for a user with a given ID
            axios.get('/verification/verify', {
              params: {
                code: state.password
              },
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            })
              .then(function (response) {
                // handle success
                if (response.status === 200 || response.status === 201 || response.status === 202) {
                    store.dispatch('isLoading');
                      Swal.fire(
                        {
                          icon: 'success',
                          title: 'Success',
                          text: 'Code verified successfully',
                          showConfirmButton: false,
                        }
                      )
                      router.push({ name: 'IDVerification'})
                }
              })
              .catch(function (error) {
                // handle error
                if (error.response) {
                    store.dispatch('isLoading');
                          Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                          }
                      )
              }
              })
          }
      } 
      
      const resendcode = async () => {
          await store.dispatch('isLoading');
                await axios.get('/verification/resend', {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                    })
                    .then(function (response) {
                      if (response.status === 200 || response.status === 201 || response.status === 202) {
                          store.dispatch('isLoading');
                        Swal.fire(
                          { 
                            icon: 'success',
                            title: 'Success',
                            text: 'Code resent successfully',
                            showConfirmButton: false
                          }
                        )
                        router.go();
                      }
                    })

                    .catch(function (error) {
                      if (error.response) {
                          store.dispatch('isLoading');
                          Swal.fire({ 
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                          }
                          )
                      }
                    })

      }
      



      return {verify, resendcode, v$, state, string_timer, timer_end};
    }
}
</script>

<style scoped>
    .subtitle{
        text-align: center;
        margin-bottom: 1.875rem;
        max-width: 25rem;
    }
    .register h2 {
        max-width: 21.875rem;
    }

    .extra{
        display: flex;
        width: 90%;
        justify-content: space-around;
        margin-top: 0.625rem;
    }

    .extra .resend{
        color: #383D6F;
        cursor: pointer;
    }

</style>