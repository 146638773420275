<template>
  <div class="recipient-details">

      <form @submit.prevent="addRecipient">
            <div class="inputs">
                <div class="input" :class="{'input--error': v$.firstname.$error}">
                    <input @blur="v$.firstname.$touch()" type="text" placeholder="First Name" v-model="state.firstname">
                    <i class="icon far fa-user"></i>
                </div>
                <div v-if="v$.firstname.$error">
                    <p :class="{'error': v$.firstname.required.$invalid}"  v-if="v$.firstname.required.$invalid">Please enter recipient's first name</p>
                </div>
                <div class="input" :class="{'input--error': v$.lastname.$error}">
                    <input @blur="v$.lastname.$touch()" type="text" placeholder="Last Name" v-model="state.lastname">
                    <i class="icon far fa-user"></i>
                </div>
                <div v-if="v$.lastname.$error">
                    <p :class="{'error': v$.lastname.required.$invalid}"  v-if="v$.lastname.required.$invalid">Please enter recipient's last name</p>
                </div>

                <div class="input" v-if="payment_method.code.includes('mobile') || payment_method.code.includes('cash')">
                    <input class="dial_code" type="text" :value="recipient_country.dial_code"  disabled>
                    <input class="phone" type="text" placeholder="Phone Number" v-model="state.phone_number" required>
                    <i class="icon fas fa-phone"></i>
                </div>

                <div class="input" v-if="payment_method.code.includes('bank')">
                    <input type="text" placeholder="Bank Account Number" v-model="state.bank_account_number" required>
                    <i class="icon fas fa-university"></i>
                </div>

                <div class="input" v-if="relationships" :class="{'input--error': v$.relationship.$error}">
                    <select v-model="state.relationship">
                        <option disabled selected value="">Relationship</option>
                        <option :value="relationship" v-for="(relationship, index) in relationships" :key="index">
                            {{ relationship }}
                        </option>
                    </select>
                    <i class="icon far fa-user"></i>
                </div>

                 <div class="input" v-if="sending_reasons" :class="{'input--error': v$.sending_reason.$error}">
                    <select v-model="state.sending_reason">
                        <option disabled selected value="">Sending Reason</option>
                        <option :value="sendingReason" v-for="(sendingReason, index) in sending_reasons" :key="index">
                            {{ sendingReason }}
                        </option>
                    </select>
                    <i class="icon far fa-user"></i>
                </div>
                <div class="input-checkbox">
                    <label for="checkbox">Save Recipient</label>
                    <input type="checkbox" id="checkbox" v-model="state.save_recipient">
                </div>
            </div>
            <button :disabled="v$.$invalid" type="submit">Continue</button>
      </form>
  </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import Swal from 'sweetalert2'
    import axios from "axios";
    import { useStore } from "vuex"
    import { ref, computed, reactive, onMounted } from "vue"
    import {useRouter} from "vue-router";

export default {
    name: 'RecipientDetails',
    props: ['receivingCountries', 'id'],
    setup(props){
        const router = useRouter();
        const id = computed( () => {
            return props.id
        } )

        const store = useStore();
        const token = computed( () => {
            return store.getters.getToken
        })
        const payment_method = computed( () => {
            return store.getters.getPaymentMethods
        })
        const relationships = ref(null);
        const sending_reasons = ref(null);

        const recipient_country = computed( () => {
            return store.getters.getRecipientCountry
        })

        const channel = computed( () => {
            return store.getters.getPaymentChannels
        })

        // Function for fetching sending reasons
        function getSendingReasons(){
            return axios.get('/sending-reasons', {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            })
        }

        // Function for fetching relationships
        function getRelationships(){
            return axios.get('/relationships', {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            })
        }



        const state = reactive({
            firstname: "",
            lastname: "",
            phone_number: "",
            bank_account_number: "",
            relationship: "",
            save_recipient: false,
            sending_reason: "",

        })
        const rules = computed( () => ({
            firstname : { required },
            lastname: { required },
            relationship: { required },
            sending_reason: { required },

        }));

        const displayCustomerDetails = function (obj){
            return Object.entries(obj).map(([key, value]) => `<strong>${(key).charAt(0).toUpperCase() + (key).slice(1)}:</strong> ${value} </br>`.replace(/[_]/g, " ")).join("");
        }


        const v$ = useVuelidate(rules, state);

        const addRecipient = async() => {
            v$.validate;

            if (!v$.$error){
                await store.dispatch('isLoading');
                    if (payment_method.value.id === 2){

                        const enquiry_data = {
                            "enquiry-type": "individual",
                            "type": "mobile-money",
                            "channel": channel.value.code,
                            "account": state.phone_number
                        }


                        const data =
                            {
                                "firstname": state.firstname,
                                "lastname": state.lastname,
                                "country": recipient_country.value.code,
                                "phone_number": state.phone_number,
                                "relationship": state.relationship,
                                "sending_reason": state.sending_reason,
                                "save_recipient": state.save_recipient,
                                "payment_channel_id": id.value
                            }

                            axios.post(`/recipient/account-enquiry/${recipient_country.value.code}`, enquiry_data , {
                                headers: {
                                    'Authorization': `Bearer ${token.value}`
                                }
                        }).then(function (response) {
                            if (response.status === 201 || response.status === 200){
                                store.dispatch('isLoading')

                                Swal.fire({
                                    title: 'Beneficiary Details',
                                    html: `${displayCustomerDetails(response.data.data)}`,
                                    icon: 'success',
                                    showCancelButton: true,
                                    confirmButtonColor: '#383d6f',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Proceed'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        store.dispatch('isLoading')
                                        axios.post('/recipient', data , {
                                            headers: {
                                                'Authorization': `Bearer ${token.value}`
                                            }
                                        }).then(function (response) {
                                            if (response.status === 201){
                                                store.dispatch('isLoading')
                                                store.dispatch('setRecipientData', response.data.data)
                                                router.push({name: 'NewTransaction'})
                                            }
                                        }).catch(function (error) {
                                            if (error.response){
                                                store.dispatch('isLoading')
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: error.response.data.errors.join(" "),
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }).catch(function (error) {
                            if (error.response){
                                store.dispatch('isLoading')
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.errors.join(" "),
                                })
                            }
                        })
                    }else{
                        const enquiry_data = {
                            "enquiry-type": "individual",
                            "type": "bank",
                            "channel": channel.value.code,
                            "account": state.bank_account_number
                        }


                        const data =
                            {
                                "firstname": state.firstname,
                                "lastname": state.lastname,
                                "country": recipient_country.value.code,
                                "bank_account_number": state.bank_account_number,
                                "bank_code": channel.value.code,
                                "bank_name": channel.value.name,
                                "relationship": state.relationship,
                                "sending_reason": state.sending_reason,
                                "save_recipient": state.save_recipient,
                                "payment_method_id": payment_method.value.id,
                                "payment_channel_id": id.value
                            }

                        axios.post(`/recipient/account-enquiry/${recipient_country.value.code}`, enquiry_data , {
                            headers: {
                                'Authorization': `Bearer ${token.value}`
                            }
                        }).then(function (response) {
                            if (response.status === 201 || response.status === 200){
                                store.dispatch('isLoading')
                                Swal.fire({
                                    title: 'Beneficiary Details',
                                    html: `${displayCustomerDetails(response.data.data)}`,
                                    icon: 'success',
                                    showCancelButton: true,
                                    confirmButtonColor: '#383d6f',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Proceed'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        store.dispatch('isLoading')
                                        axios.post('/recipient', data , {
                                            headers: {
                                                'Authorization': `Bearer ${token.value}`
                                            }
                                        }).then(function (response) {
                                            if (response.status === 201 || response.status === 200){
                                                store.dispatch('isLoading')
                                                store.dispatch('setRecipientData', response.data.data)
                                                router.push({name: 'NewTransaction'})
                                            }
                                        }).catch(function (error) {
                                            if (error.response){
                                                store.dispatch('isLoading')
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: error.response.data.errors.join(" "),
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }).catch(function (error) {
                            if (error.response){
                                store.dispatch('isLoading')
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.errors.join(" "),
                                })
                            }
                        })
                    }


            }
        }

        onMounted(async () => {
            await store.dispatch('isLoading');
            Promise.all([getRelationships(), getSendingReasons()])
                .then(function (results) {
                    store.dispatch('isLoading')
                    relationships.value = results[0].data.data
                    sending_reasons.value = results[1].data.data

                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
            })
        })

        return { addRecipient, payment_method, v$, state, relationships, recipient_country, sending_reasons }

    }
}
</script>

<style scoped>
    .recipient-details{
        width: 100%;
        margin-top: 1.875rem;
        display: flex;
        justify-content: center;
    }

    .recipient-details form{
        width: 100%;
        max-width: 21.875rem;
    }

    .recipient-details form .inputs{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .recipient-details form .inputs .input{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.75rem;
    }
    .recipient-details form .inputs .input-checkbox{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0.75rem;
    }
    .recipient-details form .inputs .input-checkbox label{
        margin-right: 1.25rem;
    }

    .recipient-details form .inputs .input .icon {
        width: 0.75rem;
        position: absolute;
        left: 0.5rem;
    }

    .recipient-details form .inputs .input input, .recipient-details form .inputs .input select{
        width: 100%;
        padding: 0.25rem 0.25rem 0.25rem 1.875rem;
        height: 3.125rem;
        border: 0.0625rem solid #707070;
        border-radius: 0.4375rem;
    }

    .recipient-details form .inputs .input .dial_code{
        width: 30%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .recipient-details form .inputs .input .phone{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .recipient-details form .inputs .input.input--error input, .recipient-details form .inputs .input.input--error select{
        border: 0.0625rem solid #F21D2F;
    }

    .recipient-details form .inputs .input-checkbox input[type='checkbox']{
        height: 1.875rem;
        padding-left: 0.625rem;
    }
    .recipient-details form .inputs .input input:focus, .recipient-details form .inputs .input select:focus{
        outline: none;
    }
    .recipient-details form button {
        width: 100%;
        padding: 0.9375rem;
        border-style: none;
        background: #383d6f;
        color: white;
        border-radius: 0.625rem;
        cursor: pointer;
    }

    .recipient-details form button:disabled{
        background: #9199ea;
        cursor: not-allowed;
    }

</style>
