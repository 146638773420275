<template>
        <div class="pay-card-section" v-if="corporateData">
              <div v-if="corporateData.length" class="card" >
                    <div v-for="corporate in corporateData" :key="corporate.id" class="pay-card" @click="router.push({name: 'CorporateRecipients', params: {id: corporate.id}} )">
                          <img :src="corporate.icon" alt="" />
                          <p>{{corporate.title}}</p>
                    </div>
              </div>
              <div v-else class="coming-soon">
                    <p >Coming Soon!</p>
              </div>
        </div>
            <div v-else class="loading">
                  <div class="lds-dual-ring"></div>
            </div>

</template>

<script>
      import { useStore } from 'vuex'
      import { ref, computed } from 'vue'
      import axios from "axios";
      import Swal from 'sweetalert2'
      import {useRouter} from "vue-router";
      export default {
        name: 'Corporate Pay',

        setup(){
              const store = useStore();
              const router = useRouter();
              const corporateData = ref(null);

              const token = computed( () => {
                    return store.getters.getToken;
              })

              axios.get('/categories', {
                    headers: {
                          'Authorization': `Bearer ${token.value}`
                    }
              }).then(function (response) {
                    corporateData.value = response.data.data;
              }).catch(error => {
                    if (error.response){
                          Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.errors.join(" "),
                          })
                    }
              })


              return { corporateData, router }
        }
      }
</script>

<style scoped>
      .pay-card-section{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
      }
      .pay-card {
            width: 29%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0.9375rem 1.1875rem 0.9375rem 0;
            background: #ffffff;
            padding: 0.5625rem;
            box-shadow: 0 0.125rem 0.375rem #00000029;
            border-radius: 0.5rem;
            cursor: pointer;
      }

      .card{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
      }

      .pay-card img {
            width: 40%;
      }

      .pay-card p {
            font-size: 1rem;
            color: #383d6f;
      }

      .coming-soon{
          font-size: 20px;
          letter-spacing: 2px;
          margin-top: 20px;
          color: #000000;
          width: 75%;
          display: flex;
          justify-content: flex-start;
          font-weight: bold;

      }


      .loading{
            display: flex;
            width: 100%;
            height: 8rem;
            justify-content: center;
            align-items: center;
      }

      @media (max-width: 34.375rem) {
            .pay-card{
                  width: 60%;
                  padding: 0.7rem;
            }

            .pay-card p {
                  font-size: 0.9rem;
                  color: #383d6f;
            }

            .pay-card img{
                  width: 50%;
                  margin-bottom: 0.3125rem;
            }

            .card{
                  flex-direction: column;
                  align-items: center;
            }

          .coming-soon{
              font-size: 15px;
              width: 90%;

          }



      }
</style>

<style>
      .lds-dual-ring {
            display: inline-block;
            width: 5rem;
            height: 5rem;
      }
      .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 4.375rem;
            height: 4.375rem;
            margin: 0.5rem;
            border-radius: 50%;
            border: 0.1875rem solid #fff;
            border-color: #F21D2F transparent #383D6F transparent;
            animation: lds-dual-ring 1s linear infinite;
      }
      @keyframes lds-dual-ring {
            0% {
                  transform: rotate(0deg);
            }
            100% {
                  transform: rotate(360deg);
            }
      }

</style>