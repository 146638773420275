<template>

    <div class="new_transaction">
        <div class="payment-details">
            <div class="payment-details-title">
                <h3>Transfer Portal</h3>
            </div>
            <div class="payment-details-recipient" v-if="transactionType === 'individual'">
                <p>RECIPIENT</p>
                <p class="payment-details-recipient-name">{{recipient.firstname}} {{recipient.lastname}}</p>
            </div>
            <div class="payment-details-recipient" v-if="transactionType === 'business'">
                <p>RECIPIENT</p>
                <p class="payment-details-recipient-name">{{corporateRecipient.name}}</p>
            </div>

            <div class="payment-details-recipient" v-if="transactionType === 'individual'">
                <p>PAYMENT CHANNEL</p>
                <p class="payment-details-recipient-name">{{recipient.payment_channel.name}}</p>
            </div>
            <div class="payment-details-recipient" v-if="transactionType === 'business'">
                <p>PAYMENT CHANNEL</p>
                <p class="payment-details-recipient-name">BANK</p>
            </div>


            <div class="payment-details-form">
                <form @submit.prevent="sendMoney">
                    <div class="inputs">
                        <label for="sending"> YOU SEND </label>
                        <div class="input" :class="{'input--error': v$.sending_amount.$error}">
                            <input @blur="v$.sending_amount.$touch()" type="text" id="sending" v-model="state.sending_amount" >
                            <div class="currency-symbol">
                                <span>{{customers.customer ?  customers.customer.country.symbol : customers.country.symbol}}</span>
                            </div>
                            <div class="currency-flag">
                                <span>{{customers.customer ? customers.customer.country.flag_emoji : customers.country.flag_emoji}}</span>
                                <span>{{customers.customer ? customers.customer.country.currency : customers.country.currency}}</span>
                            </div>
                        </div>

                        <label for="receiving"> RECIPIENT GETS </label>
                        <div class="input" :class="{'input--error': v$.receiving_amount.$error}" v-if="transactionType === 'individual' ">
                            <input @blur="v$.receiving_amount.$touch()" type="text" id="receiving" v-model="state.receiving_amount" disabled>
                            <div class="currency-symbol">
                                <span>{{recipient.recipient_country.symbol}}</span>
                            </div>
                            <div class="currency-flag">
                                <span>{{recipient.recipient_country.flag_emoji}}</span>
                                <span>{{recipient.recipient_country.currency}}</span>
                            </div>
                        </div>
                        <div class="input" :class="{'input--error': v$.receiving_amount.$error}" v-if="transactionType === 'business'">
                            <input @blur="v$.receiving_amount.$touch()" type="text" id="receiving-business" v-model="state.receiving_amount">
                            <div class="currency-flag">

                                <span>{{corporateRecipient.settlement_currency}}</span>
                            </div>
                        </div>

                        <div class="input select" :class="{'input--error': v$.sourceOfFund.$error}">
                            <select @blur="v$.sourceOfFund.$touch()" v-model="state.sourceOfFund">
                                <option disabled selected value="">Source Of Funds</option>
                                <option v-for="(source, index) in sourcesOfFunds" :key="index">
                                    {{source}}
                                </option>
                            </select>
                            <i class="icon fas fa-money-check"></i>
                        </div>
                    </div>
                    <div class="currency-exchange-rate" v-if="rate">
                        <p v-if="transactionType === 'individual'">Current Exchange rate 1 {{customers.customer.country.currency}} = {{rate.rate}} {{recipient.recipient_country.currency}}</p>
                        <p v-if="transactionType === 'business'">Current Exchange rate 1 {{customers.customer.country.currency}} = {{rate.rate}} {{corporateRecipient.settlement_currency}}</p>
                    </div>
                    <div v-else class="currency-exchange-rate">
                        <p>Loading...</p>
                    </div>

                    <div class="total-fees">
                        <p>FEE </p>
                        <p v-if="fees">{{customers.customer ? customers.customer.country.currency : customers.country.currency}} {{fees.fee}}</p>
                        <p v-else>Loading...</p>
                    </div>
                    <div class="total-amount">
                        <p>TOTAL CHARGED</p>
                        <p v-if="fees">{{customers.customer ? customers.customer.country.currency : customers.country.currency}} {{totalCharge}}</p>
                        <p v-else>Loading...</p>
                    </div>
                    <div class="submit-button">
                        <button :disabled="v$.$invalid" type="submit">Continue</button>
                    </div>
                </form>

            </div>

        </div>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core'
    import { required, numeric } from '@vuelidate/validators'
    import Swal from 'sweetalert2'
    import {useStore} from "vuex";
    import { computed, ref, reactive, watch } from 'vue'
    import axios from 'axios'
    export default {
        name: 'NewTransaction',
        setup(){
            const store = useStore();
            const token = computed( () => {
                return store.getters.getToken
            })
            const recipient = computed( () => {
                return store.getters.getRecipient
            })

            const corporateRecipient = computed( () => {
                return store.getters.getCorporateRecipient
            })

            const customers = computed( () => {
                return store.getters.getUserData
            })

            const product_id = computed( () => {
                return store.getters.getProductID
            })


            const accountsDetails = computed( () => {
                return store.getters.getAccountsDetails
            })

            const transactionType = computed( () => {
                return store.getters.getTransactionType
            })

            const checkRecipient = computed( () => {
                return store.getters.getCheckRecipient
            })

            const sendingReasons = ref(null);
            const sourcesOfFunds = ref(null);
            const rate = ref(null)
            const fees = ref(null)
            const totalCharge = ref(null)

            const state = reactive({
                sending_amount: 0,
                receiving_amount: 0,
                sourceOfFund: '',
                sendingReason: '',
            })

            const rules = computed( () => ({
                sending_amount : {
                    required,
                    numeric,
                },
                receiving_amount: { required, numeric },
                sourceOfFund: { required },

            }));

            const v$ = useVuelidate(rules, state);

            store.dispatch('isLoading')

            function getSourceOfFunds(){
                return axios.get('/source-of-funds', {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                })
            }

            function getRates(){
                if (Object.keys(recipient.value).length !== 0){
                    return axios.get(`/rate/${customers.value.customer.country.currency}/${recipient.value.recipient_country.currency}`, {
                        headers: {
                            'Authorization': `Bearer ${token.value}`
                        },
                    })
                }else{
                    return axios.get(`/rate/${customers.value.customer.country.currency}/${corporateRecipient.value.settlement_currency}`, {
                        headers: {
                            'Authorization': `Bearer ${token.value}`
                        },
                    })
                }

            }


            Promise.all([getSourceOfFunds(), getRates()])
                .then(function (results) {
                    store.dispatch('isLoading')
                    sourcesOfFunds.value = results[0].data.data
                    rate.value = results[1].data.data


                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
            })


            watch( () => state.sending_amount, (currentValue) => {

                state.receiving_amount = parseFloat( (currentValue * rate.value.rate).toFixed(4))
            })

            watch(() => state.sending_amount, async (currentValue) => {
                if (state.sending_amount > 0){
                            if (Object.keys(recipient.value).length !== 0){
                                await axios.get(`/fee/${recipient.value.payment_channel.payment_method.id}/${currentValue}/${customers.value.customer.country.currency}/${recipient.value.recipient_country.currency}`,{
                                    headers: {
                                        'Authorization': `Bearer ${token.value}`
                                    },
                                }).then(function (response) {
                                    fees.value = response.data.data;
                                    totalCharge.value = fees.value.fee + Number(state.sending_amount)

                                }).catch(function (error) {
                                    if (error.response){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error',
                                            text: error.response.data.errors.join(" "),
                                        })
                                    }
                                })
                            }else {
                                await axios.get(`/fee/${corporateRecipient.value.id}/${currentValue}/${customers.value.customer.country.currency}/${corporateRecipient.value.settlement_currency}`,{
                                    headers: {
                                        'Authorization': `Bearer ${token.value}`
                                    },
                                }).then(function (response) {
                                    fees.value = response.data.data;
                                    totalCharge.value = fees.value.fee + Number(state.sending_amount)

                                }).catch(function (error) {
                                    if (error.response){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Error',
                                            text: error.response.data.errors.join(" "),
                                        })
                                    }
                                })
                            }
                }
                    else
                        {
                            fees.value = null
                            totalCharge.value = null
                    }




            })




            const sendMoney = () => {
                //  Data to be sent to API
                // Run validate function
                v$.$validate;

                if (!v$.$error){
                    store.dispatch('isLoading')
                    if (transactionType.value === 'individual'){
                        if (!checkRecipient.value){
                            const data = {
                                "sending_amount": state.sending_amount,
                                "sending_currency": customers.value.customer ? customers.value.customer.country.currency : customers.value.country.currency,
                                "charge": fees.value.fee,
                                "rate": rate.value.rate,
                                "receiving_amount": state.receiving_amount,
                                "receiving_currency": recipient.value.recipient_country.currency,
                                "source_of_funds": state.sourceOfFund,
                                "accept_conditions": true,
                                "recipient": recipient.value.id,
                                "type": transactionType.value
                            }


                            axios.post('/transaction', data, {
                                headers: {
                                    'Authorization': `Bearer ${token.value}`
                                },
                            }).then(function (response) {
                                store.dispatch('setTransactionDetails', response.data.data.transaction)
                                store.dispatch('isLoading')
                                window.location.replace(`${response.data.data.redirect_url}`)
                            }).catch(function (error) {
                                if (error.response){
                                    store.dispatch('isLoading')
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: error.response.data.errors.join(" "),
                                    })

                                }
                            })
                        }else{
                            const data = {
                                "sending_amount": state.sending_amount,
                                "sending_currency": customers.value.customer ? customers.value.customer.country.currency : customers.value.country.currency,
                                "charge": fees.value.fee,
                                "rate": rate.value.rate,
                                "receiving_amount": state.receiving_amount,
                                "receiving_currency": recipient.value.recipient_country.currency,
                                "sending_reason": state.sendingReason,
                                "source_of_funds": state.sourceOfFund,
                                "accept_conditions": true,
                                "recipient": recipient.value.id,
                                "type": transactionType.value
                            }




                            axios.post('/transaction', data, {
                                headers: {
                                    'Authorization': `Bearer ${token.value}`
                                },
                            }).then(function (response) {
                                store.dispatch('setTransactionDetails', response.data.data.transaction)
                                store.dispatch('isLoading')
                                window.location.replace(`${response.data.data.redirect_url}`)
                            }).catch(function (error) {
                                if (error.response){
                                    store.dispatch('isLoading')
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: error.response.data.errors.join(" "),
                                    })

                                }
                            })
                        }
                    }
                        else {
                            const data = {
                                "sending_amount": state.sending_amount,
                                "sending_currency": customers.value.customer ? customers.value.customer.country.currency : customers.value.country.currency,
                                "charge": fees.value.fee,
                                "rate": rate.value.rate,
                                "receiving_amount": state.receiving_amount,
                                "receiving_currency": corporateRecipient.value.settlement_currency,
                                "sending_reason": corporateRecipient.value.sending_reason,
                                "source_of_funds": state.sourceOfFund,
                                "accept_conditions": true,
                                "recipient": corporateRecipient.value.id,
                                "corporate_product_id": product_id.value,
                                "type": transactionType.value,
                                "account_details": accountsDetails.value
                            }


                            axios.post('/transaction', data, {
                                headers: {
                                    'Authorization': `Bearer ${token.value}`
                                },
                            }).then(function (response) {
                                store.dispatch('setTransactionDetails', response.data.data.transaction)
                                store.dispatch('isLoading')
                                window.location.replace(`${response.data.data.redirect_url}`)
                            }).catch(function (error) {
                                if (error.response){
                                    store.dispatch('isLoading')
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: error.response.data.errors.join(" "),
                                    })

                                }
                            })



                    }




                }
            }

            return { v$, corporateRecipient, sendMoney, fees, rate, state, sendingReasons, sourcesOfFunds, recipient, customers, totalCharge, transactionType }
        }

    }
</script>

<style scoped>
    .new_transaction{
        display: flex;
        justify-content: center;
    }

    .payment-details{
        width: 50%;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        background: #ffffff;
        border-radius: 0.3125rem;
    }

    .payment-details .payment-details-title {
        padding: 1rem;
        font-weight: 900;
        border-bottom: 0.0625rem solid #E6E6FA;
        background: #383d6f;
        color: white;
        text-align: center;
    }

    .payment-details .payment-details-recipient{
        padding: 0.5rem 1rem;
    }

    .payment-details .payment-details-recipient p:nth-of-type(1){
        color: #8C92AC;
        letter-spacing: 0.0625rem;
    }

    .payment-details .payment-details-recipient .payment-details-recipient-name{
        padding: 0.5rem 0;
        font-weight: 900;
        color: #383d6f;
        letter-spacing: 0.0625rem;
    }


    .payment-details .payment-details-form .currency-exchange-rate, .payment-details .payment-details-form .total-fees, .payment-details .payment-details-form .total-amount{
        border-bottom: 0.0625rem solid #E6E6FA;
    }

    .payment-details .payment-details-form .inputs{
        padding: 1rem;
    }

    .payment-details .payment-details-form .inputs .input{
        display: flex;
        position: relative;
        padding: 0.7rem 0;
    }

    .payment-details .payment-details-form .inputs label{
        color: #8C92AC;
    }
    .payment-details .payment-details-form .inputs .input input, .payment-details .payment-details-form .inputs .input select{
        height: 2.1875rem;
        width: 100%;
        border-color: #E6E6FA;
        border-style: solid;
        border-radius: 0.3125rem;
        padding: 0.25rem 0.25rem 0.25rem 2.5rem;
        font-weight: 200;
        font-size: 1rem;
    }

    .payment-details .payment-details-form .inputs .input .corporate-reason{
        padding-left: 3.5rem;
    }

    .payment-details .payment-details-form .inputs .input select{
        width: 100%;
    }



    .payment-details .payment-details-form .inputs .input.input--error input, .payment-details .payment-details-form .inputs .input.input--error select{
        border: 0.0625rem solid #F21D2F !important;
    }

    .payment-details .payment-details-form .inputs .input i.icon{
        position: absolute;
        color: #383d6f;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 1.875rem;
        border-right: 0.0625rem solid #E6E6FA;
    }

    .payment-details .payment-details-form .inputs .input i.icon-tags{
        position: absolute;
        color: #383d6f;
        height: 1.875rem;
        display: flex;
        left: 51%;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 1.875rem;
        border-right: 0.0625rem solid #E6E6FA;
    }

    .error{
        font-size: 13px;
    }

    .payment-details .payment-details-form .inputs .input select{
        font-weight: 200;
    }
    .payment-details .payment-details-form .inputs .input input:focus, .payment-details .payment-details-form .inputs .input select:focus{
        outline: none;
    }
    .payment-details .payment-details-form .currency-symbol{
        position: absolute;
        color: #383d6f;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 1.875rem;
        border-right: 0.0625rem solid #E6E6FA;
    }

    .payment-details .payment-details-form .currency-flag{
        position: absolute;
        left: 83%;
        color: #383d6f;
        height: 1.875rem;
        display: flex;
        justify-content: space-around;
        border-left: 0.0625rem solid #E6E6FA;
        align-items: center;
        width: 3.75rem;
    }

    .payment-details-form .currency-exchange-rate{
        text-align: center;
        padding-bottom: 0.3rem;
        color: black;
        font-weight: bold;
    }

    .payment-details-form .currency-exchange-rate p{
        font-size: 1rem;
    }

    .payment-details-form .total-fees, .payment-details-form .total-amount{
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        font-weight: 900;
        color: #383d6f;
        letter-spacing: 0.0625rem;
    }
    .payment-details-form .total-fees p:nth-of-type(2), .payment-details-form .total-amount p:nth-of-type(2){
        color: #F21D2F;
    }

    .payment-details-form .submit-button{
        display: flex;
        justify-content: center;
    }

    .payment-details-form .submit-button button{
        margin: 1rem 0;
        padding: 0.7rem;
        width: 70%;
        background: #383d6f;
        border-style: none;
        border-radius: 0.3125rem;
        color: #ffffff;
        cursor: pointer;
    }

    .payment-details-form .submit-button button:disabled{
        background: #9199ea;
        cursor: not-allowed;
    }



    @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
        .payment-details{
            width: 80%;
        }
    }

    @media (max-width: 34.375rem) {

        .payment-details{
            width: 90%;
        }

        .payment-details .payment-details-form .currency-flag{
            left: 74%;
        }

        .payment-details .payment-details-form .inputs .input.select{
            flex-direction: column;
        }

        .payment-details .payment-details-form .inputs .input.select select{
            margin-bottom: 1.25rem;
            width: 100%;
        }


        .payment-details .payment-details-form .inputs .input i.icon-tags{
            left: 0;
            top: 4.375rem;
        }

        .payment-details-form .currency-exchange-rate p{
            font-size: 0.8125rem;
        }
    }

</style>