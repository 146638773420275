<template>
    <div class="list-of-documents-section" v-if="documents">
        <div class="list-of-documents" v-if="documents.length">
            <div class="document_card"  v-for="document in documents" :key="document.id">
                <i v-if="document.document_type.name.includes('PASSPORT')"  class="icon fas fa-passport"></i>
                <i v-else class="icon far fa-id-badge"></i>
                <p>{{document.document_type.name.replace('_', ' ')}}</p>
                <i @click="reRoute(document.id)" class="fas fa-external-link-alt"></i>
            </div>
        </div>
        <div v-else class="no_documents">
            <p>No documents to display</p>
        </div>
    </div>

</template>

<script>
    import {useStore} from "vuex";
    import {useRouter} from "vue-router";
    import {computed, ref} from "vue";
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "Document_Home",

        setup(){
            const store = useStore();
            const router = useRouter();

            const token = computed( () => {
                return store.getters.getToken
            })

            const reRoute = (id) => {
                router.push({name: 'DocumentDetails', params: { document_id : id}})
            }


            const documents = ref(null);

            const getDocuments = async () => {
                await store.dispatch('isLoading')
                await axios.get(`/documents`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    },
                }).then(function (response) {
                    store.dispatch('isLoading')
                    documents.value = response.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })
            }

            getDocuments()
            return  { documents, reRoute }
        }
    }
</script>

<style scoped>

    .no_documents{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 3.75rem;
    }

    .no_documents p{
        font-size: 1.875rem;
        color: red;
    }

    .list-of-documents-section{
        width: 100%;
    }

    .list-of-documents{
        margin-right: 0.625rem;
        width: 100%;
        padding: 1.25rem;
        display: flex;
        flex-wrap: wrap;
    }

    .list-of-documents .document_card{
        display: flex;
        margin-bottom: 1.25rem;
        width: 25%;
        max-width: 100%;
        max-height: 4rem;
        margin-right: 2.5rem;
        justify-content: space-between;
        box-shadow: -15px -15px 15px rgba(255,255,255,0.5),
        15px 15px 15px rgba(0, 0, 0, 0.05);
        border-radius: 0.625rem;
        padding: 1.25rem;
        background: #ffffff;
    }

    .list-of-documents .document_card .icon{
        font-size: 1.875rem;
        color: #383d6f;
    }

    .list-of-documents .document_card .fa-external-link-alt{
        cursor: pointer;
        color: #F21D2F;
    }

    .list-of-documents .document_card p{
        font-size: 1.0625rem;
        color: #383d6f;
        font-weight: bold;
    }

    @media screen and (min-width: 600px) and (max-width: 900px) {
        .list-of-documents{
            width: 100%;
        }

        .list-of-documents .document_card{
            width: 40%;
        }
    }

    @media screen and (max-width: 550px){
        .list-of-documents .document_card{
            width: 100%;
            margin-right: 0;
        }

        .list-of-documents{
            width: 100%;
        }
    }

</style>