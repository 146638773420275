<template>
    <div class="recipient-category" >
        <div class="title" v-if="corporate_recipient">
            <img :src="corporate_recipient.logo" alt="">
            <h1>{{corporate_recipient.name}}</h1>
            <h2>{{product_name}}</h2>
        </div>
        <div class="list_of_people-section" v-if="accountsDetails.length">
            <div class="list_of_people" >
                <p v-for="(account_detail, index) in accountsDetails" :key="index">
                    {{account_detail[Object.keys(account_detail)[0]]}} <i @click="removeAccount(account_detail)" class="fas fa-times"></i>
                </p>
            </div>
        </div>

        <div class="product-details-form" v-if="corporate_recipient.validation_fields">
            <form @submit.prevent="validateProductDetails">
                <div class="inputs">
                    <div class="input" v-for="(validation_field, index) in corporate_recipient.validation_fields.fields" :key="index">
                        <input v-model="validation_fields[index]" type="text" :placeholder="(validation_field.replaceAll('_', ' '))" required>
                        <i v-if="validation_field.includes('phone')" class="fas fa-phone"></i>
                        <i v-else class="fas fa-user"></i>
                    </div>
                </div>
                <div class="action-buttons">
                    <button type="button" class="add-btn" @click="addAccount" v-if="corporate_recipient.has_multi_accounts"><i class="fas fa-plus"></i> Add</button>
                    <button type="submit">Continue</button>
                </div>

            </form>
        </div>
        <div class="product-details-form" v-else>
            No input fields
        </div>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import {computed, reactive } from 'vue'
    import { useRouter } from 'vue-router'
    import axios from "axios";
    import Swal from "sweetalert2";

    export default {
        name: "CorporateRecipient",

        setup(){
            const store =useStore();
            const router = useRouter()
            const token = computed( () => {
                return store.getters.getToken
            })

            const accountsDetails = computed( () => {
                return store.getters.getAccountsDetails
            })

            accountsDetails.value.splice(0)


            const product_name = computed( () => {
                return store.getters.getProductName
            })

            const corporate_recipient = computed( () => {
                return store.getters.getCorporateRecipient
            })

            let responses = {}

            let validation_fields = reactive([])

            validation_fields.splice(0)

            let data = {}


            const validateProductDetails = () => {
                if (corporate_recipient.value.validation_fields){
                    let corporateValidations = reactive(corporate_recipient.value.validation_fields.fields)
                    store.dispatch('isLoading')
                    corporateValidations.forEach( (k, v) => [
                        data[k] = validation_fields[v]
                    ])
                    if (corporate_recipient.value.has_validation){
                        axios.get(`/validation/${corporate_recipient.value.processor_code}`, {
                            headers: {
                                'Authorization': `Bearer ${token.value}`
                            },
                            params: data
                        }).then(function (response) {
                            if (response.status === 200){
                                store.dispatch('isLoading')
                                const result = response.data.data
                                let corporateResponse = reactive(corporate_recipient.value.validation_fields.response)
                                corporateResponse.forEach( (k) => [
                                    responses[k] = result[k]
                                ])

                                const displayCustomerDetails = function (obj){
                                    return Object.entries(obj).map(([key, value]) => `<strong>${key}:</strong> ${value} </br>`.replace(/[_]/g, " ")).join("");
                                }

                                Swal.fire({
                                    title: 'Customer Details',
                                    html: `${displayCustomerDetails(responses)}`,
                                    icon: 'success',
                                    showCancelButton: true,
                                    confirmButtonColor: '#383d6f',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Proceed'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        store.dispatch('setAccountsDetails', data)
                                        router.push({name: 'NewTransaction'})
                                    }
                                })

                            }
                        }).catch(function (error) {
                            if (error.response){
                                store.dispatch('isLoading')
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.errors.join(" "),
                                })

                            }
                        })
                    }else{
                        store.dispatch('isLoading')
                        if (!accountsDetails.value.length){
                            store.dispatch('setAccountsDetails', data)
                        }
                        router.push({name: 'NewTransaction'})
                    }
                }
            }

            const addAccount = () => {
                if (corporate_recipient.value.validation_fields){
                    let corporateValidations = reactive(corporate_recipient.value.validation_fields.fields)

                    if (Object.keys(data).length){
                        data = {}
                        corporateValidations.forEach( (k, v) => [
                            data[k] = validation_fields[v]
                        ])
                        store.dispatch('setAccountsDetails', data)
                    }else {
                        corporateValidations.forEach( (k, v) => [
                            data[k] = validation_fields[v]
                        ])
                        store.dispatch('setAccountsDetails', data)
                        validation_fields.splice(0)
                    }
                }





            }

            const removeAccount = (account) => {
                store.dispatch('removeAccountDetails', account)
            }








            return { product_name, addAccount, removeAccount,  accountsDetails, validateProductDetails, validation_fields, corporate_recipient }
        }
    }
</script>

<style scoped>
    .recipient-category{
        width: 80%;
        background: white;
        box-shadow: 0 0.125rem 0.375rem #00000029;
    }

    .recipient-category .title{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recipient-category .title img{
        height: 5.625rem;
        margin-top: 0.625rem;
        object-fit: cover;
    }

    .recipient-category .title h1{
        color: #423e3b;
    }

    .recipient-category .title h2{
        color: #383d6f;
        padding: 1.25rem;
    }

    .product-details-form{
        display: flex;
        justify-content: center;
        padding: 1.25rem;
        width: 100%;

    }

    .product-details-form form{
        width: 40%;
        padding: 0.625rem;
    }

    .product-details-form form .inputs .input{
        position: relative;
    }

    .product-details-form form .inputs .input input{
        width: 100%;
        height: 2.5rem;
        border-radius: 0.3125rem;
        border-style: solid;
        margin-top: 0.625rem;
        font-size: 1.15rem;
        padding: 0.25rem 0.25rem 0.25rem 1.875rem;
    }

    .product-details-form form .inputs .input input:focus{
        outline: none;
    }

    .product-details-form form .inputs .input i{
        position: absolute;
        left: 0.625rem;
        top: 1.4rem;
    }

    .product-details-form form .inputs .input i.fa-phone{
        color: #00FF00;
    }


    .product-details-form .action-buttons{
        display: flex;
        justify-content: space-between;
    }

    .product-details-form .action-buttons button{
        margin-top: 0.625rem;
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;
        background: #383d6f;
        color: white;
        border-style: none;
        border-radius: 0.3125rem;
        cursor: pointer;
    }

    .product-details-form .action-buttons .add-btn{
        background: #00B38B;
        margin-right: 1.25rem;
    }

    .list_of_people-section{
        display: flex;
        justify-content: center;
    }

    .list_of_people{
        display: flex;
        width: 60%;
        padding: 0.625rem;
        flex-wrap: wrap;

    }

    .list_of_people p {
        padding: 0.625rem 0.9375rem;
        margin: 0.3125rem 0.625rem;
        background: #383d6f;
        border-radius: 0.9375rem;
        color: white;
    }

    .list_of_people p i {
        margin-left: 0.625rem;
        cursor: pointer;
        color: #F21D2F;
    }



    @media screen and (max-width: 31.25rem){
        .recipient-category{
            width: 90%;
        }

        .product-details-form form{
            width: 100%;
        }

        .product-details-form .action-buttons{
            flex-direction: column;
        }

    }

    @media screen and (min-width: 600px) and (max-width: 900px) {
        .product-details-form form{
            width: 70%;
        }
    }
</style>