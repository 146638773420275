<template>
    <div class="password-change-section">
        <div class="password-change">
            <p>Password</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PasswordChange"
    }
</script>

<style scoped>
    .password-change-section{
        border: 1px solid red;
        display: flex;
        justify-content: center;
    }

    .password-change-section .password-change{
        width: 50%;
        background: white;
    }
</style>