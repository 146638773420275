<template>
  <footer>
      <p>&copy; Copyright {{ year }} Fast Pace Transfer. All rights reserved</p>
  </footer>
</template>

<script>
import { ref } from '@vue/reactivity';

export default {
    name: "Footer",

    setup(){

        let year = ref(null);
        year = new Date();
        year = year.getFullYear();
        
        return{ year };
    }
}
</script>

<style scoped>

    footer{
        background: #151e3f;
        height: 1.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer p{
        font-family: 'Lato', sans-serif;
        color: #fff;
        font-size: 0.75rem;
    }

    @media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
        footer {
            height: 3vh;
        }

        footer p{
            font-size: 0.875rem;
        }
    }

    @media screen and (min-width: 80rem) and (max-width: 89.9375rem) {
        footer {
            height: 3vh;
        }

        footer p{
            font-size: 0.875rem;
        }
    }

    @media screen and (min-width: 90rem) {
        footer {
            height: 3vh;
        }

        footer p{
            font-size: 0.875rem;
        }
    }

</style>