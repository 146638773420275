<template>
    <div class="card">
        <div class="new-recipient" >
            <h3>Select Transaction Method</h3>
            <PaymentChannel/>
        </div>
    </div>
</template>

<script>

    import PaymentChannel from "../../../../components/Dashboard/PaymentChannel";

    export default {
        name: 'PaymentMethod',
        components: { PaymentChannel },


    }
</script>

<style scoped>
    .card{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .new-recipient {
        background: white;
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2.5rem;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        border-radius: 0.9375rem;
    }

    .new-recipient h3{
        color: #423e3b;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    @media screen and (min-width: 37.5rem) and (max-width: 56.25rem){
        .new-recipient{
            width: 70%;
        }
    }




    @media (max-width: 34.375rem) {
        .new-recipient{
            width: 90%;
            box-shadow: none;
            border-radius: 0;
            padding: 0.925rem;
        }

        .new-recipient h3{
            font-size: 0.7rem;
        }
    }

</style>