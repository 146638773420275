<template>
    <div>
        <Loading v-if="loading"/>
        <div class="form-wrap">
            <form @submit.prevent="idSubmission" class="register" autocomplete="off">
                <img src="../assets/landing_page/logo_2.png" height="100" width="100"/>
                <p class="login-register">
                    Back to Home?
                    <router-link class="router-link" :to="{ name: 'Home' }">Home</router-link>
                </p>
                <h2>Verify Your Details</h2>
                <p class="subtitle">Enter identity documents details and upload ID in png, jpg or pdf format.</p>
                <div class="inputs">
                    <div class="input">
                        <select v-model="state.issuing_country" required>
                            <option disabled selected value="">Country</option>
                            <option :value="country.code" v-for="country in countries" :key="country.id" :data-status="country.active" >
                                {{country.flag_emoji}}
                            </option>
                        </select>
                        <select @change="showEvent" required v-model="state.document_type_id">
                            <option disabled selected value="">Document Type</option>
                            <option :value="document.id" v-for="document in documentTypes" :key="document.id" :data-status="document.has_back_image" >
                                {{document.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input" >
                        <input type="text" placeholder="Document No" required v-model="state.document_id">
                        <i class="icon fas fa-passport"></i>
                    </div>

                    <div class="input" >
                        <input placeholder="Date Issued" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" required v-model="state.issue_date"/>
                        <i class="icon fas fa-calendar-check"></i>
                    </div>

                    <div class="input">
                        <input placeholder="Date of Expiry" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" required v-model="state.expiry_date"/>
                        <i class="icon fas fa-calendar-check"></i>
                    </div>

                    <div class="back-image-included" v-if="check === 'true'">
                        <p>UPLOAD DOCUMENT</p>
                        <div class="doc-input">
                            <label for="front-image-two">Front&nbsp;Image:</label>
                            <input type="file" id="front-image-two" accept=".png, .jpg, .jpeg, .pdf" required @change="onFrontFileSelected">
                        </div>
                        <div class="doc-input">
                            <label for="back-image-two">Back&nbsp;Image:</label>
                            <input type="file" id="back-image-two" accept=".png, .jpg, .jpeg, .pdf" required @change="onBackFileSelected">
                        </div>
                    </div>
                    <div class="input-file" v-else>
                        <label for="front-image-one">Document:</label>
                        <input type="file" id="front-image-one" accept=".png, .jpg, .jpeg, .pdf" required @change="onFrontFileSelected">
                    </div>


                </div>
                <button>Submit</button>
                <div class="angle"></div>
            </form>
            <div class="background"></div>
        </div>

    </div>
</template>

<script>
    import dayjs from "dayjs";
    import { useStore } from "vuex"
    import { useRouter } from 'vue-router'
    import Loading from '../components/common/Loading.vue'
    import { ref, computed, reactive } from "vue";
    import axios from 'axios'
    import Swal from "sweetalert2";
    export default {
        name: "ID-Verification",
        components: { Loading },


        setup(){


            const store  = useStore();
            const router = useRouter();
            store.dispatch('setCountries');


            const loading = computed( () => {
                return store.state.loading;
            })

            const token = computed( () => {
                return store.getters.getToken
            })

            const state = reactive({
                issue_date: '',
                expiry_date: '',
                issuing_country: '',
                document_id: '',
                document_type_id: '',
                front_image: [],
                back_image: []
            })

            const check = ref(null)

            const countries = computed( () => {
                return store.getters.getCountries
            })

            const documentTypes = ref(null)

            const showEvent = (event) => {
                check.value = event.target.options[event.target.selectedIndex].getAttribute('data-status');
            }

            const getDocumentTypes = async () => {
                await store.dispatch('isLoading')
                await axios.get('/document-types', {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    documentTypes.value = response.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" ")
                        })
                    }
                })
            }

            getDocumentTypes();

            const onFrontFileSelected = (event) => {
                state.front_image = event.target.files[0]
            }

            const onBackFileSelected = (event) => {
                state.back_image = event.target.files[0];
            }

            const idSubmission = () => {
                store.dispatch('isLoading')
                const data  = new FormData();
                data.append('issue_date', dayjs(state.issue_date).format('DD-MM-YYYY'),);
                data.append('expiry_date', dayjs(state.expiry_date).format('DD-MM-YYYY'),);
                data.append('issuing_country', state.issuing_country);
                data.append('document_id', state.document_id);
                data.append('document_type_id', state.document_type_id);
                data.append('front_image', state.front_image);

                if (state.back_image){
                    data.append('back_image', state.back_image);
                }

                axios.post('/document', data, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(function (response) {

                    if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203){
                        store.dispatch('isLoading')
                        router.push({name: 'CreateProfile'})
                    }
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })
                    }
                })

            }



            return {idSubmission, onFrontFileSelected, onBackFileSelected, state,  loading, documentTypes, countries, check, showEvent };
        }
    }
</script>

<style scoped>

    .register h2 {
        max-width: 21.875rem;
    }

    .subtitle{
        text-align: center;
        margin-bottom: 1.875rem;
        max-width: 25rem;
    }

    .form-wrap form .inputs .input-file {
        display: flex;
        align-items: center;
        margin-top: 0.625rem;
        justify-content: space-between;
    }

    .form-wrap form .inputs .input-file p{
        font-size: 0.875rem;
        padding-left: 0.625rem;
        color: #707070;
        font-weight: bold;
        margin-bottom: 0.625rem;
    }

    .form-wrap form .inputs .input-file label{
        padding-right: 1.25rem;
        padding-left: 0.625rem;
        font-size: 0.875rem;
        color: #707070;
    }

    .form-wrap form .inputs .input.file .input-file input {
        width: 100%;
        height: 3.125rem;
    }

    .form-wrap form .inputs .back-image-included p{
        font-size: 0.875rem;
        padding-left: 0.625rem;
        color: #707070;
        font-weight: bold;
        margin-bottom: 0.625rem;
    }

    .form-wrap form .inputs .back-image-included .doc-input{
        display: flex;
        margin-bottom: 1.25rem;
    }

    .form-wrap form .inputs .back-image-included .doc-input label{
        padding-right: 1.25rem;
        padding-left: 0.625rem;
        font-size: 0.875rem;
        color: #707070;
    }


    .form-wrap form .inputs .input select:nth-of-type(1) {
        height: 3.125rem;
        background-color: #f2f7f6;
        border: none;
        padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    }

    .form-wrap form .inputs .input select:nth-of-type(2) {
        height: 3.125rem;
        width: 100%;
        background-color: #f2f7f6;
        border: none;
        padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    }

    .form-wrap form .inputs .input select:focus{
        outline: none;
    }

</style>