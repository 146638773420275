<template>
    <div class="profile-section">
        <div class="profile">
            <h3>Your Profile</h3>

            <div class="profile-form">
                <form @submit.prevent="updateUserDetails">
                    <div class="inputs">
                        <div class="input">
                            <label>First&nbsp;Name:</label>
                            <input type="text" v-model="state.firstname"  @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Last&nbsp;Name:</label>
                            <input type="text" v-model="state.lastname"   @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Date Of Birth:</label>
                            <input type="text"  v-model="state.date_of_birth" disabled>
                        </div>
                        <div class="input">
                            <label>Gender:</label>
                            <input type="text"  v-model="state.gender" @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Email:</label>
                            <input type="email"  v-model="state.email" disabled>
                        </div>
                        <div class="input">
                            <label>Phone Number:</label>
                            <input type="text" v-model="state.phone_number" disabled>
                        </div>
                        <div class="input">
                            <label>City:</label>
                            <input type="text"  v-model="state.city" @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Street&nbsp;Address:</label>
                            <input type="text"  v-model="state.street_address" @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Place Of Birth:</label>
                            <input type="text"  v-model="state.place_of_birth" @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Post Code:</label>
                            <input type="text" v-model="state.post_code" @change="onUpdate">
                        </div>
                        <div class="input">
                            <label>Resident Country</label>
                            <input type="text" v-model="state.resident_country" @change="onUpdate" disabled>
                        </div>

                    </div>

                    <div class="save-changes">
                        <button type="submit" :disabled="disabled">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import {computed, ref, reactive} from "vue";
    import axios from 'axios'
    import Swal from "sweetalert2";

    export default {
        name: "Profile",

        setup(){
            const store = useStore();

            const userData = computed( () => {
                return store.getters.getUserData
            })

            store.dispatch('setCountries');

            const countries = computed( () => {
                return store.getters.getCountries
            })


            const token = computed( () => {
                return store.getters.getToken
            })

            const disabled = ref(true)

            const state = reactive({
                firstname: userData.value.customer ?  userData.value.customer.firstname : userData.value.firstname,
                lastname: userData.value.customer ?  userData.value.customer.lastname : userData.value.lastname,
                gender: userData.value.customer ? userData.value.customer.gender: userData.value.gender,
                date_of_birth: userData.value.customer ?  userData.value.customer.date_of_birth : userData.value.date_of_birth,
                email: userData.value.customer ? userData.value.email : userData.value.user.email,
                phone_number: userData.value.customer ? userData.value.phone: userData.value.user.phone,
                city: userData.value.customer ?  userData.value.customer.city : userData.value.city,
                street_address: userData.value.customer ?  userData.value.customer.street_address : userData.value.street_address,
                place_of_birth: userData.value.customer ?  userData.value.customer.place_of_birth : userData.value.place_of_birth,
                post_code: userData.value.customer ?  userData.value.customer.post_code : userData.value.post_code,
                country: userData.value.customer ? userData.value.customer.country.code : userData.value.country.code,
                resident_country: userData.value.customer ? userData.value.customer.country.name : userData.value.country.name
            })

            const onUpdate = () => {
                disabled.value = false;
            }

            const updateUserDetails = async () => {

                const data = {
                    'firstname': state.firstname ,
                    'lastname': state.lastname,
                    'gender': state.gender,
                    'city': state.city,
                    'street_address': state.street_address,
                    'place_of_birth': state.place_of_birth,
                    'post_code': state.post_code,
                    'resident_country': state.country
                }

                await store.dispatch('isLoading')

                await axios.put(`/customer/${userData.value.customer ? userData.value.id : userData.value.user.id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203){
                        store.dispatch('isLoading')
                        store.dispatch('setUserData', response.data.data);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Success',
                                text: 'Profile Updated Successfully',
                            }
                        )
                    }
                }).catch( function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire(
                            {
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.errors.join(" "),
                            }
                        )
                    }
                })

            }





            return { userData, onUpdate, updateUserDetails, disabled, state, countries }
        }
    }
</script>

<style scoped>
    .profile-section{
        display: flex;
        justify-content: center;
        padding: 1.875rem 0;
    }

    .profile-section .profile{
        width: 60%;
        background: white;
        padding: 1.25rem 0;
    }

    .profile-section .profile h3{
        color: #707070;
        padding-bottom: 1.25rem;
        text-align: center;
    }

    .profile-section .profile .profile-form{
        display: flex;
        justify-content: center;
    }

    .profile-section .profile .profile-form form{
        width: 80%;
    }

    .profile-section .profile .profile-form .input{
        margin: 1.25rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }


    .profile-section .profile .profile-form .input label{
        font-size: 0.9375rem;
    }



    .profile-section .profile .profile-form .input input, .profile-section .profile .profile-form .input select{
        height: 2.1875rem;
        width: 80%;
        padding-left: 1.25rem;
        border-radius: 0.3125rem;
        border-width: 0.0625rem;
        border-style: solid;
        border-color: #707070;

    }


    .profile-section .profile .profile-form .input input:focus{
        outline: none;
    }



    .profile-section .profile .profile-form .save-changes{
        display: flex;
        justify-content: flex-end;
        padding-right: 1.25rem;
    }


    .profile-section .profile .profile-form .save-changes button{
        background: #383d6f;
        color: white;
        border-style: none;
        padding: 0.625rem;
        cursor: pointer;
        border-radius: 0.3125rem;
    }

    .profile-section .profile .profile-form .save-changes button:disabled{
        background: #696cb1;
        cursor: not-allowed;
    }

    @media screen and (min-width: 600px) and (max-width: 900px) {
        .profile-section .profile{
            width: 100%;
        }
    }

    @media (max-width: 550px) {
        .profile-section{
            padding: 0;
        }
        .profile-section .profile{
            width: 100%;
        }

        .profile-section .profile .profile-form .input{
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0.625rem;
        }

        .profile-section .profile .profile-form .input label{
            margin-bottom: 0.625rem;
        }

        .profile-section .profile .profile-form .input input, .profile-section .profile .profile-form .input select{
            margin-bottom: 0.9375rem;
            width: 100%;
        }



        .profile-section .profile .profile-form .save-changes{
            padding: 0;
        }




    }
</style>