<template>
    <div class="transaction-details-section" v-if="transaction_details">
        <div class="transaction-details">
            <div class="transaction-details-title">
                <span><router-link class="link" :to="{name:'History'}"> <i class="fas fa-chevron-left"></i> &nbsp;Back</router-link></span>
                <div class="title">
                    <span>Transaction Details</span>
                </div>

            </div>
            <div class="details-section">
                <div class="label">
                    <span>Type of Transaction: </span>
                </div>
               <div class="details">
                   <span>{{transaction_details.type.charAt(0).toUpperCase() + transaction_details.type.slice(1)}}</span>
               </div>

            </div>
            <div class="details-section">
                <div class="label">
                    <span>Transaction ID: </span>
                </div>
                <div class="details">
                    <span>{{transaction_details.transaction_code}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Status:</span>
                </div>
                <div class="details">
                    <span class="success" v-if="transaction_details.status === 'processed'"><i class="fas fa-check-circle"></i>&nbsp;&nbsp;{{transaction_details.status.charAt(0).toUpperCase() + transaction_details.status.slice(1)}}</span>
                    <span class="failed" v-if="transaction_details.status === 'failed'"><i class="fas fa-times-circle"></i>&nbsp;&nbsp;{{transaction_details.status.charAt(0).toUpperCase() + transaction_details.status.slice(1)}}</span>
                    <span class="canceled" v-if="transaction_details.status === 'canceled'"><i class="fas fa-times-circle"></i>&nbsp;&nbsp;{{transaction_details.status.charAt(0).toUpperCase() + transaction_details.status.slice(1)}}</span>
                    <span class="processing" v-if="transaction_details.status === 'processing'"><i class="fas fa-sync-alt"></i>&nbsp;&nbsp;{{transaction_details.status.charAt(0).toUpperCase() + transaction_details.status.slice(1)}}</span>
                </div>
            </div>
            <div class="details-section" v-if="transaction_details.payment_method">
                <div class="label">
                    <span>Payment Method: </span>
                </div>
                <div class="details">
                    <span>{{transaction_details.payment_method.name}}</span>
                </div>
            </div>
            <div class="details-section" v-if="transaction_details.payment_channel">
                <div class="label">
                    <span>Payment Channel:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.payment_channel.name}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Amount Sent:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.sending_currency}} {{transaction_details.sending_amount}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Amount Received:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.receiving_currency}} {{transaction_details.receiving_amount}}</span>
                </div>
            </div>
            <div class="details-section" >
                <div class="label">
                    <span>Charge:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.sending_currency}} {{transaction_details.charge}}</span>
                </div>

            </div>
            <div class="details-section" v-if="transaction_details.business_recipient">
                <div class="label">
                    <span>Recipient:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.business_recipient.name}}</span>
                </div>
            </div>
            <div class="details-section" v-if="transaction_details.individual_recipient">
                <div class="label">
                    <span>Recipient:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.individual_recipient.firstname}} {{transaction_details.individual_recipient.lastname}}</span>
                </div>
            </div>
            <div class="details-section" v-if="transaction_details.individual_recipient">
                <div class="label">
                    <span>Relationship To Sender:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.individual_recipient.relationship.charAt(0).toUpperCase() + transaction_details.individual_recipient.relationship.slice(1)}}</span>
                </div>
            </div>
            <div class="details-section" v-if="transaction_details.individual_recipient">
                <div class="label">
                    <span>Recipient's Phone Number:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.individual_recipient.phone_number}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Date:</span>
                </div>
                <div class="details">
                    <span>{{convertDate(transaction_details.created_at)}}</span>
                </div>

            </div>
            <div class="details-section">
                <div class="label">
                    <span>Sending Reasons:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.sending_reason.charAt(0).toUpperCase() + transaction_details.sending_reason.slice(1)}}</span>
                </div>

            </div>
            <div class="details-section">
                <div class="label">
                    <span>Source Of Funds:</span>
                </div>
                <div class="details">
                    <span>{{transaction_details.source_of_funds.charAt(0).toUpperCase() + transaction_details.source_of_funds.slice(1)}}</span>
                </div>
            </div>

            <div class="action-buttons">
                <button><a :href=" 'mailto:support@fastpacetransfer.com?subject=TransactionRef: ' + transaction_details.transaction_code">Report</a></button>
            </div>

        </div>
    </div>
</template>

<script>
    import {useRoute} from "vue-router";
    import {useStore} from "vuex";
    import {computed, ref} from "vue";
    import axios from 'axios'
    import Swal from "sweetalert2";
    import dayjs from "dayjs";

    export default {
        name: "History_Details",

        setup(){
            const route = useRoute();
            const store = useStore();

            const token = computed( () => {
                return store.getters.getToken
            })

            const id = route.params.transaction_id

            const transaction_details = ref(null);

            const getTransaction = async () => {
                await store.dispatch('isLoading')
                await axios.get(`/transaction/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    transaction_details.value = response.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
                })
            }

            getTransaction();

            const convertDate = (value) => {
                const localizedFormat = require('dayjs/plugin/localizedFormat')
                dayjs.extend(localizedFormat)
                return dayjs(value).format('llll');
            }

            return { transaction_details, convertDate }
        }
    }
</script>

<style>

    .transaction-details-section{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .transaction-details{
        width: 60%;
        background: white;
        box-shadow: -15px -15px 15px rgba(255,255,255,0.5),
        15px 15px 15px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
        padding: 20px;
    }

    .transaction-details .transaction-details-title{
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .transaction-details .transaction-details-title .title{
        font-weight: bold;
        font-size: 30px;
        color: #000000;
        display: flex;
        justify-content: center;
        width: 80%;
    }


    .transaction-details .details-section{
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 10px 20px;
        font-weight: bold;
        font-size: 20px;
        color: #707070;
    }

    .transaction-details .details-section .label{
        width: 40%;
    }

    .transaction-details .details-section .details{
        width: 60%;
        padding-left: 10px;
    }

    .transaction-details .details-section .details span{
        color: #535ba7;
    }

    .transaction-details-section .transaction-details .details-section .success{
        color: #2bcf2b;
    }

    .transaction-details-section .transaction-details .details-section .failed, .transaction-details-section .transaction-details .details-section .canceled{
        color: #F21D2F;
    }

    .transaction-details-section .transaction-details .details-section .processing{
        color: #FFBF00;
    }

    .transaction-details .action-buttons{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0;
    }

    .transaction-details .action-buttons button{
        padding: 10px;
        width: 70%;
        border-style: none;
        border-radius: 5px;
        font-size: 20px;
        cursor: pointer;
        background: #F21D2F;
    }

    .transaction-details .action-buttons button a{
        text-decoration: none;
        color: white;
    }




    @media screen and (min-width: 600px) and (max-width: 900px){
        .transaction-details{
            width: 90%;
        }
    }

    @media screen and (max-width: 550px) {
        .transaction-details{
            width: 90%;
        }

        .link{
            font-size: 15px;
        }

        .transaction-details .transaction-details-title .title{
            font-weight: bold;
            font-size: 18px;
        }

        .transaction-details{
            padding: 5px;
        }

        .transaction-details .details-section{
            padding: 8px 10px;
            font-size: 15px;
        }

        .transaction-details .action-buttons{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 10px 0;
        }

        .transaction-details .action-buttons button{
            width: 90%;
            font-size: 15px;
        }

        .transaction-details .action-buttons button:nth-of-type(1){
            background: #F21D2F;
            margin-bottom: 10px;
        }
    }
</style>

<style scoped>
    .link{
        text-decoration: none;
        color: #383d6f;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }

    @media screen and (max-width: 550px) {
        .link{
            font-size: 15px;
        }
    }
</style>