<template>
   <div class="pay-content-section">
            <div class="tab-titles">
              <h3 class="corporate" :class="{'active': corporate}" @click="toggleTab($event)">Corporate Payments</h3>
              <h3 class="bill" :class="{'active': pay_bill}" @click="toggleTab($event)">Pay Bill</h3>
            </div>
            <PayBill v-if="pay_bill"/>
            <CorporatePay v-if="corporate"/>
          </div>
</template>

<script>
import { ref } from 'vue'
import CorporatePay from '@/components/Dashboard/CorporatePay'
import PayBill from '@/components/Dashboard/PayBill'

export default {
  name: 'PayContent',
  components: { PayBill, CorporatePay },

  setup(){

    const corporate = ref(true);
    const pay_bill = ref(false);

    const toggleTab = (event) => {
      if (event.target.classList.value.includes('bill')) {
        pay_bill.value = true;
        corporate.value = false;
      }
      else if (event.target.classList.value.includes('corporate')) {
        corporate.value = true;
        pay_bill.value = false;
      } 
    }

    const corporateData = ref([]);



    return { corporateData, toggleTab, pay_bill, corporate}
  }
}
</script>

<style scoped>
    .pay-content-section {
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

    }

    .pay-content-section .tab-titles {
        display: flex;
    }

    .pay-content-section .tab-titles h3 {
        color: #d1d1d1;
        border-bottom: 0.1875rem solid #d1d1d1;
        text-align: center;
        padding: 0.625rem;
        width: 50%;
        cursor: pointer;

    }

    .pay-content-section .tab-titles .active {
        color: #383d6f;
        border-bottom: 0.1875rem solid #383d6f;
    }

    @media screen and (max-width: 550px){
        .pay-content-section .tab-titles h3{
            font-size: 15px;
        }
    }



</style>