<template>
    <div class="add-new-card-content">
        <div class="add-new-card-form-layout">
            <div class="add-new-card-form-navigation">
                <span @click="reRoute()"><i class="fa fa-chevron-left"></i>&nbsp;Back</span>
            </div>
            <div class="add-new-card-form-title">
                <h2>add new card</h2>
            </div>
            <div class="add-new-card-form">
                <form>
                    <div class="card-holder">
                        <label for="card-holder">Cardholder's name</label>
                        <input type="text" id="card-holder" v-model="state.cardHolder">
                        <i class="fas fa-user"></i>
                    </div>
                    <div class="card-number">
                        <label for="card-number">Card Number</label>
                        <input type="tel" id="card-number" v-model="state.cardNumber">
                        <i class="fas fa-credit-card"></i>
                        <div class="accepted-card-types" v-if="cardType === ''">
                            <div class="visa-card-type">
                                <img src="../../../../assets/dashboard/VISA-logo.png" alt="visa-logo"/>
                            </div>
                            <div class="mastercard-card-type">
                                <img src="../../../../assets/dashboard/mastercard.png" alt="mastercard-logo"/>
                            </div>
                        </div>
                        <div class="card-type-images" v-if="cardType === 'visa'">
                            <img src="../../../../assets/dashboard/VISA-logo.png" alt="visa-logo"/>
                        </div>
                        <div class="card-type-images" v-if="cardType === 'mastercard'">
                            <img src="../../../../assets/dashboard/mastercard.png" alt="mastercard-logo"/>
                        </div>
                    </div>
                    <div class="form-input-row">
                        <div class="card-expiry-date">
                            <label>Expiration date</label>
                            <div class="card-date">
                                <div class="card-expiry-month">
                                    <select id="card-expiry-month" v-model="state.cardExpiryMonth">
                                        <option value selected disabled>Month</option>
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>

                                    </select>
                                    <i class="fas fa-calendar"></i>
                                </div>
                                <div class="card-expiry-year">
                                    <select id="card-expiry-year" v-model="state.cardExpiryYear">
                                        <option value selected disabled>Year</option>
                                        <option
                                                v-for="(n, $index) in 15"
                                                :key="n"
                                                :value="$index + minCardYear"
                                        >{{$index + minCardYear}}</option>
                                    </select>
                                    <i class="fas fa-calendar"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-cvv">
                            <label for="card-cvv">CVV</label>
                            <input
                                    type="tel"
                                    id="card-cvv"
                                    v-model="state.cardCvv"
                            >
                            <i class="fas fa-credit-card"></i>
                        </div>
                    </div>
                    <button type="submit" class="card-submit-button">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import { computed, reactive } from "vue";
    import { useRouter } from "vue-router";
    export default {
        name: "AddNewCard",
        directives: {
            'number-only': {
                bind (el) {
                    function checkValue (event) {
                        event.target.value = event.target.value.replace(/\D/g, '')
                        if (event.key >= 48 && event.key <= 57) {
                            return true
                        }
                        event.preventDefault()
                    }
                    el.addEventListener('keypress', checkValue)
                }
            },
            'letter-only': {
                bind (el) {
                    function checkValue (event) {
                        if (event.key >= 48 && event.key <= 57) {
                            event.preventDefault()
                        }
                        return true
                    }
                    el.addEventListener('keypress', checkValue)
                }
            }
        },
        setup(){
            const store = useStore();
            const router = useRouter();

            const loading = computed( () => {
                return store.state.loading
            })

            const reRoute = () => {
                router.push({name: 'CardPayment'})
            }

            const minCardYear = new Date().getFullYear()

            const cardType = computed( () => {
                let number = state.cardNumber
                let re = new RegExp('^4')
                if (number.match(re) != null) return 'visa'

                re = new RegExp('^(34|37)')
                if (number.match(re) != null) return 'amex'

                re = new RegExp('^5[1-5]')
                if (number.match(re) != null) return 'mastercard'

                re = new RegExp('^6011')
                if (number.match(re) != null) return 'discover'

                re = new RegExp('^62')
                if (number.match(re) != null) return 'unionpay'

                re = new RegExp('^9792')
                if (number.match(re) != null) return 'troy'

                re = new RegExp('^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}')
                if (number.match(re) != null) return 'dinersclub'

                re = new RegExp('^35(2[89]|[3-8])')
                if (number.match(re) != null) return 'jcb'

                return '' // default type
            })


            const state = reactive({
                cardHolder: '',
                cardNumber: '',
                cardExpiryMonth: '',
                cardExpiryYear: '',
                cardCvv: ''
            })



            return{ loading, minCardYear, state, cardType, reRoute }
        }
    }
</script>

<style scoped>
    .add-new-card-content{
        display: flex;
        justify-content: center;
    }

    .add-new-card-content .add-new-card-form-layout{
        width: 60%;
        background: white;
        padding: 20px;
        border-radius: 7px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form-navigation span{
        font-size: 20px;
        cursor: pointer;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form-navigation span:hover{
        color: #F21D2F;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form-title h2{
        text-align: center;
        text-transform: uppercase;
        margin-top: 15px;
        color: #383d6f;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form{
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form{
        width: 80%;
        padding: 20px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-holder, .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-holder label, .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number label, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv label, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date label{
        font-size: 16px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: #535ba7;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-holder input, .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number input, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date select, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv input{
        height: 2.3875rem;
        width: 100%;
        border-color: #E6E6FA;
        border-style: solid;
        border-radius: 0.3125rem;
        padding: 0.25rem 0.25rem 0.25rem 2.5rem;
        font-weight: 200;
        font-size: 1.1rem;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-holder input:focus, .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number input:focus, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv input:focus{
        outline: none;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-holder i, .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number i, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv i{
        position: absolute;
        color: #535ba7;
        top: 40px;
        left: 15px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number .card-type-images{
        position: absolute;
        height: 1.6rem;
        width: 3.5rem;
        top: 35px;
        right: 4px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number .accepted-card-types{
        position: absolute;
        height: 1.8rem;
        width: 7rem;
        top: 35px;
        right: 4px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number .accepted-card-types .visa-card-type{
        position: absolute;
        height: 1.6rem;
        width: 3.5rem;
        top: 0;
        right: 55px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number .accepted-card-types .mastercard-card-type{
        position: absolute;
        height: 1.6rem;
        width: 3.5rem;
        top: 0;
        right: 4px;
    }


    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-number img{
        height: 100%;
        width: 100%;
        object-fit: contain;

    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row{
        display: flex;
        gap: 5%;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date{
        width: 55%;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date .card-date{
        display: flex;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date .card-date .card-expiry-month{
        margin-right: 20px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date .card-date .card-expiry-month, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date .card-date .card-expiry-year{
        position: relative;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date .card-date .card-expiry-month i, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date .card-date .card-expiry-year i{
        position: absolute;
        color: #535ba7;
        top: 22px;
        left: 15px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date select, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv input{
        margin-top: 10px;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-expiry-date select:focus, .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv input:focus{
        outline: none;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form .form-input-row .card-cvv{
        width: 40%;
        position: relative;
    }

    .add-new-card-content .add-new-card-form-layout .add-new-card-form form .card-submit-button{
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
        color: #fff;
        margin-top: 20px;
        cursor: pointer;
    }



</style>
