<template>
    <div class="no-page-found-section">
        <div class="no-page-found">
            <h1 class="heading">404</h1>
            <p class="sub-heading">OOPS! NOTHING WAS FOUND</p>
            <button>
                <router-link class="link" :to="{name: 'Dashboard'}">Back to Dashboard</router-link>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFoundComponent"
    }
</script>

<style scoped>
    .link{
        text-decoration: none;
        letter-spacing: 0.05rem;
    }
    .no-page-found-section{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #383d6f;
    }

    .no-page-found-section .no-page-found{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .no-page-found-section .no-page-found .heading{
        font-size: 17.5rem;
        color:#F21D2F;
    }

    .no-page-found-section .no-page-found .sub-heading{
        font-size: 1.75rem;
        color: white;
        letter-spacing: 0.5rem;
        margin-bottom: 0.625rem;
    }

    .no-page-found-section .no-page-found button {
        background: white;
        border-style: none;
        padding: 0.625rem;
        border-radius: 0.4375rem;
    }

    @media screen and (max-width: 34.375rem){
        .no-page-found-section .no-page-found .heading{
            font-size: 7.5rem;
        }

        .no-page-found-section .no-page-found .sub-heading{
            font-size: 1rem;
            letter-spacing: 0.2rem;
        }
    }



</style>