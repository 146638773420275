<template>

        <div class="card">
            <div class="new-recipient" >
                <h1>Enter Recipient Details</h1>
                <img :src="channel.logo" alt="channel logo" height="60" width="60">
                <br/>
                <h3>{{channel.name}}</h3>
                <RecepientDetails :id="id" :receivingCountries="receivingCountries"/>
            </div>
        </div>

</template>

<script>
import RecepientDetails from '@/components/Dashboard/RecepientDetails.vue'
import {useStore} from "vuex";
import {computed} from "vue";

export default {
    name: 'Recipient Details',
    components: { RecepientDetails},
    props: ['id'],
    setup(){
        const store = useStore();
        store.dispatch('setReceivingCountries');


        const receivingCountries = computed( () => {
            return store.getters.getReceivingCountries
        })

        const channel = computed( () => {
            return store.getters.getPaymentChannels
        })

        return { channel,  receivingCountries }
    }

}
</script>

<style scoped>
    .card{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .new-recipient {
        background: white;
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2.5rem;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        border-radius: 0.3375rem;
    }

    .new-recipient h1{
        color: #423E3B;
        margin-bottom: 1.875rem;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    .new-recipient h3{
        color: #383d6f;
    }
    @media (max-width: 34.375rem) {
        .new-recipient{
            width: 90%;
            padding: 0.9375rem;
        }

        .new-recipient h1{
            font-size: 0.9rem;
        }
    }

</style>