<template>
    <div class="transaction-details-section" v-if="document_details">
        <div class="transaction-details">
            <div class="transaction-details-title">
                <span><router-link class="link" :to="{name:'Documents'}"> <i class="fas fa-chevron-left"></i> &nbsp;Back</router-link></span>
                <div class="title">
                    <span>Document Details</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Document Type: </span>
                </div>
                <div class="details">
                    <span>{{(document_details.document_type.name).replaceAll('_', ' ')}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Document No: </span>
                </div>
                <div class="details">
                    <span>{{document_details.document_id}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Issue Date: </span>
                </div>
                <div class="details">
                    <span>{{convertDate(document_details.issue_date) }}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Issuing Country: </span>
                </div>
                <div class="details">
                    <span>{{document_details.country.name}}</span>
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Expiry Date: </span>
                </div>
                <div class="details">
                    <span>{{convertDate(document_details.expiry_date) }}</span>
                </div>
            </div>
            <div class="details-section" v-if="document_details.front_image">
                <div class="label">
                    <span>Front Image: </span>
                </div>
                <div class="details">
                    <img :src="document_details.front_image" alt="document_image">
                </div>
            </div>
            <div class="details-section" v-if="document_details.back_image">
                <div class="label">
                    <span>Back Image: </span>
                </div>
                <div class="details">
                    <img :src="document_details.back_image" alt="document_image">
                </div>
            </div>
            <div class="details-section">
                <div class="label">
                    <span>Verified: </span>
                </div>
                <div class="details">
                    <i class="fas fa-check-circle" v-if="document_details.verified"></i>
                    <i v-else class="fas fa-times-circle"></i>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import {useRoute} from "vue-router";
    import {useStore} from "vuex";
    import {computed, ref} from "vue";
    import axios from "axios";
    import Swal from "sweetalert2";
    import dayjs from "dayjs";

    export default {
        name: "Document_Details",

        setup(){
            const route = useRoute();
            const store = useStore();

            const token = computed( () => {
                return store.getters.getToken
            })

            const convertDate = (value) => {
                const localizedFormat = require('dayjs/plugin/localizedFormat')
                dayjs.extend(localizedFormat)
                return dayjs(value).format('lll');
            }



            const id = route.params.document_id

            const document_details = ref(null);

            const getDocument = async () => {
                await store.dispatch('isLoading')
                await axios.get(`/document/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token.value}`
                    }
                }).then(function (response) {
                    store.dispatch('isLoading')
                    document_details.value = response.data.data
                }).catch(function (error) {
                    if (error.response){
                        store.dispatch('isLoading')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error.response.data.errors.join(" "),
                        })

                    }
                })
            }

            getDocument();

            return { document_details, convertDate }

        }
    }
</script>

<style scoped>

    .transaction-details-section{
        margin-top: 20px;
    }

    .transaction-details-section .transaction-details .details-section .details img{
        height: 200px;
        width: 100%;
    }

    .transaction-details-section .transaction-details .details-section .details i{
        font-size: 30px;
    }

    .transaction-details-section .transaction-details .details-section .details i.fa-check-circle{
        color: #00FF00;
    }

    .transaction-details-section .transaction-details .details-section .details i.fa-times-circle{
        color: #F21D2F;
    }

    .link{
        text-decoration: none;
        color: #383d6f;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }

    @media screen and (max-width: 550px) {
        .link{
            font-size: 15px;
        }
    }
</style>