<template>
  <div class="recipient-country">

      <h3>Select Recipient Country</h3>
      <div class="country-cards-section">
        <div class="country-card" v-for="receivingCountry in receivingCountries" :key="receivingCountry.id" @click="reRoute(receivingCountry.code)">
            <p>{{receivingCountry.flag_emoji}}</p>
            <p class="country-name">{{receivingCountry.name}}</p>
        </div>
      </div>

  </div>
</template>

<script>
import {useRouter} from "vue-router";
import { useStore } from "vuex"
import { computed } from "vue"
import axios from "axios";
import Swal from "sweetalert2";

export default {
    name: 'RecepientCountry',


    setup(){
        const store = useStore();

        store.dispatch('setReceivingCountries')
        const receivingCountries = computed(() => {
            return store.getters.getReceivingCountries
        })

        const token = computed( () => {
            return store.getters.getToken;
        })
        const router = useRouter()

        const reRoute = async(code) => {
            await store.dispatch('isLoading')
            await axios.get(`/country/${code}`, {
                headers: {
                    'Authorization': `Bearer ${token.value}`
                }
            }).then(function (response) {
                store.dispatch('isLoading')
                store.dispatch('setRecipientCountry', response.data.data)
                router.push({name: 'PaymentMethod', params: {code: code}} )

            }).catch(function (error) {
                if (error.response){
                    store.dispatch('isLoading')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.errors.join(" "),
                    })

                }
            })




        }


        return { receivingCountries, reRoute }
    }



}
</script>

<style scoped>
    .recipient-country{
        width: 100%;
    }
    .recipient-country h3{
        color: #423E3B;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    .country-cards-section{
        flex-wrap: wrap;
        display: flex;
        justify-content: space-around;
    }

    .country-card{
        width: 30%;
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        padding: 0.625rem;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0.125rem 0.375rem #00000029;
        border-radius: 0.9375rem;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
    }

    .country-card p{
        margin-top: 1.25rem;
    }



    @media (max-width: 34.375rem) {
        .recipient-country h3{
            font-size: 0.9375rem;
        }

        .country-card{
            width: 60%;
        }
    }
</style>